import { common } from "./common.service"

export const userService = {
    forgottenPassword,
    resetPassword,
    login,
    loginExternalToken,
    logout,
    register,
    remove,
    getInfo
}

function resetPassword(newpassword, uuid, token) {
    const url = `User/reset-password/${uuid}/${token}`;
    const data = {
        new_password: newpassword
    }
    return common.fetchAPI({ url, method: "POST", data });
}

function forgottenPassword(email) {
    const url = 'User/password-reset-request';
    const data = {
        email
    }
    return common.fetchAPI({ url, method: "POST", data });
}

function login(email, password) {
    const url = `User/login`;
    const data = {
            email,
            password
    }
    return common.fetchAPI({ url, method: "POST", data });
}

function loginExternalToken(type, token) {
    const url = `UserSession`;
    const data = {
        externalToken: {
            type: type === "google" ? 0 : (type === "facebook" ? 1 : 2),
            token
        }
    }
    
    const formData = {
        "Content-Type": "application/problem+json",
        Accept: "text/plain",
    }
    return common.fetchAPI({ url, method: "POST", data, formData });
}

function logout() {
    const sessionData = JSON.parse(localStorage.getItem("data"));
    const url = `UserSession/${sessionData.id}`;
    const formData = {
        "Content-Type": "application/problem+json",
        Accept: "text/plain",
        dpixauth: localStorage.getItem("token")
    }
    localStorage.removeItem("data");
    localStorage.removeItem("token");
    localStorage.removeItem("isAdmin");
    localStorage.removeItem("email");
    return common.fetchAPI({ url, method: "DELETE", formData });
}

function register(email, password, sendNotification) {
    const url = `User/register`;
    const data = {
        email,
        password,
        sendNotification
    }
    return common.fetchAPI({ url, method: "POST", data });
}

function remove(password = null) {
    if (password) {
        const url = `User/delete`;
        const data = {
            password: password
        }
        return common.fetchAPI({ url, method: "DELETE", data, token: true });
    }
}

function getInfo() {
    /* TODO implement the code when api is ready 
    const sessionData = JSON.parse(localStorage.getItem("data"));
    const url = `User/${sessionData.id}`;
    const formData = {
        dpixauth: localStorage.getItem("token")
    }
    return common.fetchAPI({ url, method: "GET", formData }); */
    return Promise.resolve({
        email: "",
        discountCodes: [{
            code: "AAAA - BBBB - CCCC - DDDD",
            description: "Sconto da applicare a tutto il carrello",
            title: "Sconto Invernale"
        }, {
            code: "AAAA - BBBB - CCCC - DDDD",
            description: "Sconto da applicare a tutto il carrello",
            title: "Sconto Invernale"
        }],
    });
}