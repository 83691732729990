import { orderService } from '../store/services';
import { traslateCodeToStatus } from './order';
/**
 * If user have at least 1 order done and passed 1 month since the last request, ask for a review
 */
export const askReview = () => {
    const lastReview = localStorage.getItem('lastReview');
    if (lastReview) {
        const lastReviewDate = new Date(lastReview);
        const now = new Date();
        if (now.getMonth() - lastReviewDate.getMonth() >= 1) {
            orderService.getList().then(
                (data) => {
                    if (data.length > 0) {
                        // if in data one element has status "Consegnato" ask for a review
                        for(let i = 0; i < data.length; i++) {
                            const order = data[i];
                            if (traslateCodeToStatus(order.status) === 'Consegnato') {
                                // ask for review
                                const message = {
                                    type: 'askReview',
                                    order: order.id,
                                }
                                if (window.ReactNativeWebView)
                                    window.ReactNativeWebView.postMessage(JSON.stringify(message));
                                else console.log('askReview', order.id);
                                localStorage.setItem('lastReview', now);
                                break;
                            }
                        }
                    }
                },
                (error) => {
                }
            );
        }
    }

}