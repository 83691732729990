export function AlertFixed({ message, showAlert, handleCloseAlert }) {

    return (
        <div className={`toast align-items-center text-white bg-danger border-0 position-fixed bottom-0 z-1000 mb-5 text-right p-2 start-50 translate-middle-x 
         ${showAlert ? 'd-block' : ''}`}

            role="alert" aria-live="assertive" aria-atomic="true">
            <div className="d-flex justify-content-between">
                <div className="toast-body text-center p-0">
                    {message}
                </div>
                <button type="button" className="btn-close btn-close-white fs-6" data-bs-dismiss="toast" aria-label="Close" onClick={handleCloseAlert}></button>
            </div>
        </div>
    );
}