import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useDispatch } from 'react-redux';
import { productDataActions } from '../../store/actions';
import { initStyle } from '../../libs/image';

export function base64ToBlob(base64Data, contentType) {
    contentType = contentType || '';
    var sliceSize = 1024;
    var byteCharacters = atob(base64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
}

export function UploadNativeButton({ maxQuantity, setImageUploading, textStyle = false, editorStyle = false, index = -1, changePhoto, imagesLength, navigate, disable, processingType, template }) {
    const dispatch = useDispatch();

    const handleButton = () => {
        const message = {
            type: 'gallery',
            maxImagesSelectable: maxQuantity,
            index: index,
        }
        window.ReactNativeWebView.postMessage(JSON.stringify(message));
        if (setImageUploading && imagesLength < 2) {
            setImageUploading(true);
        }
        const search = window.location.search;
        const newSearch = search.includes('double=true') ? search : search + '&double=true';
        const newUrl = window.location.pathname + newSearch;
        navigate(newUrl);
    }

    React.useEffect(() => {
        const addImage = async (file) => {
            // const urlImage = URL.createObjectURL(file.image);
            dispatch(productDataActions.add(file));
        }

        const updateImage = async (file, _index) => {
            const prop = {
                image: file.url,
                originalImage: file.url,
                name: file.name,
                width: file.width,
                height: file.height,
                id: file.id,
                imageWidth: file.imageWidth,
                imageHeight: file.imageHeight,
            }
            dispatch(productDataActions.update(prop, _index));

        }

        const messageHandler = (event) => {
            const data = JSON.parse(event.data);
            if (data.type === 'galleryClosed') {
                // confirm button pressed on gallery
                dispatch(productDataActions.validateIds(data.ids));
            }
            if (data.type === 'addImage') {
                let url = data.image.originalImage;
                if (data.base64 && !data.remote) {
                    const blob = base64ToBlob(data.base64, 'image/jpeg');
                    url = URL.createObjectURL(blob);
                }
                if (!textStyle && !editorStyle) {
                    // orange top button pressed
                    const style = initStyle({
                        imgWidth: data.image.imageWidth,
                        imgHeight: data.image.imageHeight,
                        processingType,
                        // I use add only for product with one template, in calendar I execute update when I add a new photo
                        template: template[0],
                    })
                    addImage({
                        ...data.image,
                        image: url,
                        originalImage: url,
                        initStyle: style,
                    });
                } else {
                    if (changePhoto) {
                        // change photo in editor pressed
                        changePhoto({
                            ...data.image,
                            url: url,
                        });
                    } else {
                        // 'press here to upload image' pressed (eg. tele)
                        if (data.index === index) {
                            updateImage({
                                ...data.image,
                                url: url,
                            }, data.index);
                        }
                    }
                }
            }
            if (data.type === 'addImages') {
                const images = data.images.map((image) => {
                    const style = initStyle({
                        imgWidth: image.imageWidth,
                        imgHeight: image.imageHeight,
                        processingType,
                        // I use add only for product with one template, in calendar I execute update when I add a new photo
                        template: template[0],
                    })
                    return {
                        ...image,
                        initStyle: style,
                    }
                })
                dispatch(productDataActions.addMany(images));
            }
            if (data.type === 'removeImage') {
                dispatch(productDataActions.removeId(data.id));
            }
            if (data.type === 'removeImages') {
                const { ids } = data;
                dispatch(productDataActions.removeIds(ids));
                if (setImageUploading) {
                    setTimeout(() => {
                        const url = window.location.href;
                        if (url.includes('double=true')) {
                            window.history.back();
                        }
                        setImageUploading(false);
                    }, 100);
                }
            }
            if (data.type === 'getImage') {
                const { index, base64 } = data;
                const blob = base64ToBlob(base64, 'image/jpeg');
                const url = URL.createObjectURL(blob);
                const prop = {
                    image: url,
                    originalImage: url,
                    native: false
                }
                dispatch(productDataActions.update(prop, index));
            }
            if (data.type === 'confirmImages') {
                if (setImageUploading) {
                    setTimeout(() => {
                        const url = window.location.href;
                        if (url.includes('double=true')) {
                            window.history.back();
                        }
                        setImageUploading(false);
                    }, 100);
                }
            }
        }
        window.addEventListener('message', messageHandler);
        document.addEventListener('message', messageHandler);
        return () => {
            window.removeEventListener('message', messageHandler);
            document.removeEventListener('message', messageHandler);
        }
    }, [changePhoto, dispatch, editorStyle, index, processingType, setImageUploading, template, textStyle]);

    if (editorStyle) {
        return <div className={`btn btn-link text-dark p-0 pe-auto`} onClick={handleButton}>
            <FontAwesomeIcon icon="fa-regular fa-images" size="2x" />
        </div>
    }

    if (textStyle) {
        return <button className={`btn btn-link w-100 text-dark mb-4 bg-white`} onClick={handleButton}>
            Tocca qui per caricare un'immagine
        </button>
    }

    return (
        <button className={`btn fw-bold btn-outline-primary border border-3 border-primary w-100 text-dark rounded-5 mb-4 no-hover ${disable ? 'disabled' : ''}`}
            onClick={handleButton}>
            <FontAwesomeIcon icon="fa-regular fa-square-plus" className="me-2" />
            Carica Immagini
        </button>
    );
}