import { common } from "./common.service"

export const discountService = {
    get,
    getAll
}


function get(pin, application) {
    let url = `discount/${pin}?application=${application}`;
    return common.fetchAPI({ url, method: "GET", token: true });
}

function getAll(application) {
    let url = `discount?application=${application}`;
    return common.fetchAPI({ url, method: "GET", token: true });
}