import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback } from 'react';
import { LightHorizontalContainer, ButtonLink, Button } from '../../ui';
import { checkRatio } from '../../libs';

const SENSIBILITY_TOUCH = 0;

export const CropperEditor = ({
    aspectRatio,
    background = "white",
    changePhoto,
    designObjects,
    initProp,
    initStyle = {},
    editorPage = false,
    processingType,
    src,
    saveProps,
    saveInitStyle,
    overloresList,
    enableUpdates = true,
    handleImageError,
    index,
    hideShadow = false,
    handleOnLoadImage,
    notWasInEditor = false,
    showResolutionAlert = false,
    showScissors = false,
}) => {
    const imageRef = React.useRef(null);
    const imagePresent = React.useRef(true);
    const blockDocumentTouch = React.useRef(false);
    const containerRef = React.useRef(null);
    const imgContainerRef = React.useRef(null);
    const editorLimits = React.useRef({
        x: 0,
        y: 0,
        zoomMin: 1,
        zoomMax: 10,
    });
    const [objectsDesigned, setObjectsDesigned] = React.useState([]);
    const [overloresDesigned, setOverloresDesigned] = React.useState([]);
    const listImgAdded = React.useRef([]);
    const mouseClicked = React.useRef(false);
    const errorsLoadCount = React.useRef(0);

    const [showUploadButton, setShowUploadButton] = React.useState(false);
    const [props, setProps] = React.useState(() => {
        if (initProp)
            return initProp;
        return {
            zoom: 1,
            rotate: 0,
            x: 0,
            y: 0
        }
    });
    const [touchPos, setTouchPos] = React.useState({
        x: 0,
        y: 0
    });
    const imageLoaded = React.useRef(false);

    const designPhoto = React.useMemo(() => {
        return designObjects?.find(item => item.image?.type === 0);
    }, [designObjects]);
    const imgContainerStyle = React.useMemo(() => ({
        width: `${designPhoto?.width}%`,
        height: `${designPhoto?.height}%`,
        left: `${designPhoto?.x}%`,
        top: `${designPhoto?.y}%`,
        transform: "translate(-50%, -50%)",
        zIndex: showUploadButton ? 30 : 20,
    }), [designPhoto, showUploadButton]);

    React.useEffect(() => {
        const preventDefault = function (e) {
            if (blockDocumentTouch.current) {
                e.preventDefault();
            }
        }
        // Disable browser zoom
        document.body.addEventListener('touchstart', preventDefault, { passive: false });
        document.body.addEventListener('touchmove', preventDefault, { passive: false });
        return () => {
            document.body.removeEventListener('touchstart', preventDefault);
            document.body.removeEventListener('touchmove', preventDefault);
        }
    }, []);

    const removedAllImg = React.useCallback(() => {
        listImgAdded.current.forEach((img) => {
            containerRef.current.removeChild(img);
        });
        listImgAdded.current = [];
    }, []);

    const drawDesignObjects = React.useCallback((designObjects) => {
        designObjects.forEach((obj, index) => {
            // Create img element
            const img = document.createElement("img");
            img.src = obj.image.src;
            img.style.width = `${obj.imageWidth}%`;
            img.style.height = `${obj.imageHeight}%`;
            img.style.position = "absolute";
            img.style.transform = `translate(-50%, -50%) rotate(${obj.imageRotation}deg)`;
            img.style.top = `${obj.imageY}%`;
            img.style.left = `${obj.imageX}%`;
            img.style.pointerEvents = "none";
            img.style.zIndex = 20 + designObjects.length - index;
            containerRef.current.appendChild(img);
            listImgAdded.current.push(img);
        });
    }, []);

    const drawOverlores = useCallback(() => {
        overloresList.forEach((obj, index) => {
            // Create img element
            const img = document.createElement("img");
            img.src = obj.src;
            img.style.width = `${obj.width}%`;
            img.style.height = `${obj.height}%`;
            img.style.position = "absolute";
            img.style.transform = `translate(-50%, -50%)`;
            img.style.left = `${obj.x}%`;
            img.style.top = `${obj.y}%`;
            img.style.pointerEvents = "none";
            img.style.zIndex = 100 + overloresList.length - index;
            containerRef.current.appendChild(img);
            listImgAdded.current.push(img);
        });
    }, [overloresList]);

    const recalculateAndFixLimits = useCallback(({ zoom, rotate }) => {
        if (!imageRef.current) return props;
        let _zoom = zoom || props.zoom;
        let _rotate = rotate === 0 ? 0 : rotate || props.rotate;
        let rotationDone = rotate || rotate === 0 ? true : false;
        if (_rotate % 180 === 0) {
            // Case where the image is not rotated or rotated by 180 degrees
            editorLimits.current.zoomMin = 1;
            editorLimits.current.x = (imageRef.current.width * _zoom - imgContainerRef.current.clientWidth) / (2 * imageRef.current.width * _zoom) * 100;
            editorLimits.current.y = (imageRef.current.height * _zoom - imgContainerRef.current.clientHeight) / (2 * imageRef.current.height * _zoom) * 100;
            if (rotationDone)
                _zoom = 1;
        } else {
            /**
             * Case where the image is rotated by 90 or 270 degrees
             * In this case our min zoom changes to adapt the image rotated to the container
             */

            let zoomW = -1, zoomH = -1;
            zoomW = imgContainerRef.current.clientWidth / imageRef.current.height;
            zoomH = imgContainerRef.current.clientHeight / imageRef.current.width;
            if (zoomW > 0 || zoomH > 0) {
                editorLimits.current.zoomMin = Math.max(zoomW, zoomH);
                if (rotationDone)
                    _zoom = Math.max(zoomW, zoomH);
            }
            editorLimits.current.x = (imageRef.current.height * _zoom - imgContainerRef.current.clientWidth) / (2 * imageRef.current.width * _zoom) * 100;
            editorLimits.current.y = (imageRef.current.width * _zoom - imgContainerRef.current.clientHeight) / (2 * imageRef.current.height * _zoom) * 100;
        }
        let newProps = { ...props, zoom: _zoom, rotate: _rotate };
        if (designPhoto?.adjustment === 1) {
            // if limits are negative, it means that the image is smaller than the container
            // so we set the limits to 0
            // Cases where the image is bigger than the container
            if (props.x < -editorLimits.current.x && editorLimits.current.x >= 0) {
                newProps.x = -editorLimits.current.x;
            }
            if (props.x > editorLimits.current.x && editorLimits.current.x >= 0) {
                newProps.x = editorLimits.current.x;
            }
            if (props.y < -editorLimits.current.y && editorLimits.current.y >= 0) {
                newProps.y = -editorLimits.current.y;
            }
            if (props.y > editorLimits.current.y && editorLimits.current.y >= 0) {
                newProps.y = editorLimits.current.y;
            }
            // Cases where the image is smaller than the container
            if (props.x < editorLimits.current.x && editorLimits.current.x < 0) {
                newProps.x = editorLimits.current.x;
            }
            if (props.x > -editorLimits.current.x && editorLimits.current.x < 0) {
                newProps.x = -editorLimits.current.x;
            }
            if (props.y < editorLimits.current.y && editorLimits.current.y < 0) {
                newProps.y = editorLimits.current.y;
            }
            if (props.y > -editorLimits.current.y && editorLimits.current.y < 0) {
                newProps.y = -editorLimits.current.y;
            }
        }

        if (rotationDone) {
            newProps.x = 0;
            newProps.y = 0;
        }
        return newProps;
    }, [designPhoto?.adjustment, props]);

    const safeAreaWidth = React.useMemo(() => {
        if (overloresList?.length > 0) {
            return 100 / overloresList[0].safeAreaWidth * 100;
        }
        return 75;
    }, [overloresList]);

    const _handleOnLoadImage = useCallback((onLoad = false) => {
        if (handleOnLoadImage && !src) {
            handleOnLoadImage();
            if (!imageLoaded.current && onLoad)
                imageLoaded.current = true;
        }
        if (objectsDesigned === JSON.stringify(designObjects) && overloresDesigned === JSON.stringify(overloresList) && !onLoad)
            return;
        removedAllImg();

        let phoneAspectRatio = window.innerHeight / window.innerWidth;

        const variableWidth = aspectRatio < phoneAspectRatio ? safeAreaWidth : safeAreaWidth - 25 * (aspectRatio - phoneAspectRatio);
        let _style = {
            ...initStyle,
            aspectRatio: aspectRatio,
            containerWidth: `${variableWidth}%`,
            x: `50%`,
            y: `50%`,
        }
        const designObjectImage = designObjects.find(item => item.image?.type === 0);
        const imageSpaceAspectRatio = designObjectImage ? designObjectImage.width / designObjectImage.height : 1;
        if (imageRef.current) {
            let realAspectRatio = aspectRatio;
            if (processingType === 1) {
                if (imageRef.current.width >= imageRef.current.height || realAspectRatio === 1)
                    _style = {
                        ..._style,
                        aspectRatio: aspectRatio,
                        containerWidth: "100%",
                    }
                else {
                    _style = {
                        ..._style,
                        aspectRatio: 1 / aspectRatio,
                    }
                    realAspectRatio = 1 / aspectRatio;
                }
            }
            const imageAspectRatio = imageRef.current.width / imageRef.current.height;
            _style = {
                ..._style,
                ...checkRatio(imageAspectRatio, realAspectRatio * imageSpaceAspectRatio, designPhoto?.adjustment),
            }
            if (onLoad || (!onLoad && !imageLoaded.current && !initStyle.width)) // save only when an image is loaded
                saveInitStyle?.(_style);
        }
        drawDesignObjects(designObjects.filter(item => item.image?.type !== 0));
        setObjectsDesigned(JSON.stringify(designObjects));
        drawOverlores();
        setOverloresDesigned(JSON.stringify(overloresList));
    }, [aspectRatio, checkRatio, designObjects, designPhoto?.adjustment, drawDesignObjects, drawOverlores, handleOnLoadImage, initStyle, notWasInEditor, objectsDesigned, overloresDesigned, overloresList, processingType, removedAllImg, safeAreaWidth, saveInitStyle, src]);

    const handleZoom = (e) => {
        let zoom = 0;
        if (e.deltaY > 0) {
            zoom = props.zoom - 0.1;
            if (zoom < editorLimits.current.zoomMin && designPhoto?.adjustment === 1)
                zoom = editorLimits.current.zoomMin;
        }
        if (e.deltaY < 0) {
            zoom = props.zoom + 0.1;
            if (zoom > editorLimits.current.zoomMax && designPhoto?.adjustment === 1)
                zoom = editorLimits.current.zoomMax;
        }
        const newProps = {
            ...recalculateAndFixLimits({ zoom }),
            zoom
        }
        setProps(newProps);
        saveProps(newProps);
    };

    const handleRotate = () => {
        const newProps = recalculateAndFixLimits({ rotate: (props.rotate + 90) % 360 });
        setProps(newProps);
        saveProps(newProps);
    };

    const handleTouchStart = (e) => {
        blockDocumentTouch.current = true;
        if (e.touches.length === 1) {
            setTouchPos(prev => {
                return {
                    ...prev,
                    x: e.touches[0].clientX,
                    y: e.touches[0].clientY
                }
            })
        }
        if (e.touches.length === 2) {
            setTouchPos(prev => {
                return {
                    ...prev,
                    distance: Math.sqrt(Math.pow(e.touches[0].clientX - e.touches[1].clientX, 2) + Math.pow(e.touches[0].clientY - e.touches[1].clientY, 2))
                }
            })
        }
    }

    const handleMouseDown = (e) => {
        blockDocumentTouch.current = true;
        mouseClicked.current = true;
        setTouchPos(prev => {
            return {
                ...prev,
                x: e.clientX,
                y: e.clientY
            }
        })
    }

    const handleTouchMove = (e) => {
        blockDocumentTouch.current = true;
        if (e.touches.length === 2) {
            // Zoom image
            const touch1 = e.touches[0];
            const touch2 = e.touches[1];
            const distance = Math.sqrt(Math.pow(touch1.clientX - touch2.clientX, 2) + Math.pow(touch1.clientY - touch2.clientY, 2));
            const diff = (distance - touchPos.distance);
            if (Math.abs(diff) > 5) {
                setProps((prev) => {
                    let zoom = 1;
                    if (prev.zoom <= 2)
                        zoom = prev.zoom + diff * 0.01;
                    if (prev.zoom > 2 && prev.zoom <= 5)
                        zoom = prev.zoom + diff * 0.05;
                    if (prev.zoom > 5)
                        zoom = prev.zoom + diff * 0.1;
                    if (prev.zoom > 20)
                        zoom = prev.zoom + diff;
                    if (zoom < editorLimits.current.zoomMin) {
                        zoom = editorLimits.current.zoomMin;
                    }
                    if (zoom > editorLimits.current.zoomMax) {
                        zoom = editorLimits.current.zoomMax;
                    }
                    return {
                        ...prev,
                        zoom
                    };
                });
                setTouchPos(prev => {
                    return {
                        ...prev,
                        distance
                    }
                })
            }
        }
        if (e.touches.length === 1) {
            // Move image
            const touch = e.touches[0];
            let diffX = (touch.clientX - touchPos.x) * 1 / props.zoom;
            let diffY = (touch.clientY - touchPos.y) * 1 / props.zoom;
            if (Math.abs(diffX) > SENSIBILITY_TOUCH || Math.abs(diffY) > SENSIBILITY_TOUCH) {
                diffX = diffX / imageRef.current.clientWidth * 100;
                diffY = diffY / imageRef.current.clientHeight * 100;
                setProps((prev) => {
                    return {
                        ...prev,
                        x: prev.x + diffX,
                        y: prev.y + diffY
                    };
                });
                setTouchPos(prev => {
                    return {
                        ...prev,
                        x: touch.clientX,
                        y: touch.clientY
                    }
                })
            }
        }
    }

    const handleMouseMove = (e) => {
        if (!mouseClicked.current) return;
        blockDocumentTouch.current = true;
        let diffX = (e.clientX - touchPos.x) * 1 / props.zoom;
        let diffY = (e.clientY - touchPos.y) * 1 / props.zoom;
        if (Math.abs(diffX) > SENSIBILITY_TOUCH || Math.abs(diffY) > SENSIBILITY_TOUCH) {
            diffX = diffX / imageRef.current.clientWidth * 100;
            diffY = diffY / imageRef.current.clientHeight * 100;
            setProps((prev) => {
                return {
                    ...prev,
                    x: prev.x + diffX,
                    y: prev.y + diffY
                };
            });
            setTouchPos(prev => {
                return {
                    ...prev,
                    x: e.clientX,
                    y: e.clientY
                }
            })
        }
    }

    const handleTouchEnd = () => {
        blockDocumentTouch.current = false;
        mouseClicked.current = false;
        const newProps = recalculateAndFixLimits({});
        setProps(newProps);
        saveProps(newProps);
    }

    const handleReset = () => {
        const newProps = { ...recalculateAndFixLimits({ zoom: 1, rotate: 0 }), x: 0, y: 0 };
        setProps(newProps);
        saveProps(newProps);
    }

    const _changePhoto = (e) => {
        changePhoto(e);
        handleReset();
    }

    const style = {
        transform: "translate(-50%,-50%)",
        left: initStyle.x,
        top: initStyle.y,
        width: initStyle.width,
        height: initStyle.height
    };

    if (props.zoom !== 1) {
        style.transform = style.transform + "scale(" + props.zoom + ")";
    }

    if (props.x !== 0 || props.y !== 0) {
        style.transform = style.transform + "translate(" + props.x + "%," + props.y + "%)";
    }

    if (props.rotate !== 0) {
        style.transform = style.transform + "rotate(" + props.rotate + "deg)";
    }

    React.useEffect(() => {
        if (processingType !== 1)
            _handleOnLoadImage();
    }, [_handleOnLoadImage, processingType]);

    React.useEffect(() => {
        /**
         * If processing type is 3 or 4 and src is not present, show upload button
         * Example of products of this type are: Calendar, image with border, ecc...
         */
        if ((processingType === 3 || processingType === 4) && !src && designPhoto)
            setShowUploadButton(true);
        else
            setShowUploadButton(false);
    }, [designPhoto, processingType, src]);

    const _handleImageError = () => {
        let native = !src && handleOnLoadImage ? handleOnLoadImage() : false;
        if (errorsLoadCount.current < 3 && handleImageError && !native)
            handleImageError();
        errorsLoadCount.current++;
    }
    const nativePermissionGranted = localStorage.getItem('permissionGranted') === 'true';

    const disableZoomIn = props.zoom >= editorLimits.current.zoomMax && designPhoto?.adjustment === 1;
    const disableZoomOut = props.zoom <= editorLimits.current.zoomMin && designPhoto?.adjustment === 1;

    return (
        <div className='d-flex h-100 flex-column justify-content-between pb-3'>
            {editorPage && <div></div>}
            <div className={`position-relative ${editorPage ? "p-2" : ""}`}>

                <div className={`position-relative m-auto ${hideShadow ? "" : "shadow"}`}
                    ref={containerRef}
                    style={{ aspectRatio: initStyle.aspectRatio, background: background, width: initStyle.containerWidth }}>
                    {showScissors && <Button otherClass={"position-absolute top-0 end-0 m-n2 w-auto z-1000"}>
                        <FontAwesomeIcon icon="fa-regular fa-scissors" />
                    </Button>}
                    {showResolutionAlert && <Button otherClass={"position-absolute top-0 start-0 m-n2 w-auto z-1000 btn-danger"}>
                        <FontAwesomeIcon icon="fa-regular fa-file-magnifying-glass" />
                    </Button>}
                    <div className="position-absolute overflow-hidden" style={imgContainerStyle} ref={imgContainerRef}
                        onWheel={!showUploadButton && imagePresent && enableUpdates ? handleZoom : null}
                        onTouchStart={!showUploadButton && imagePresent && enableUpdates ? handleTouchStart : null}
                        onTouchMove={!showUploadButton && imagePresent && enableUpdates ? handleTouchMove : null}
                        onTouchEnd={!showUploadButton && imagePresent && enableUpdates ? handleTouchEnd : null}
                        // add support for mouse events like touch
                        onMouseDown={!showUploadButton && imagePresent && enableUpdates ? handleMouseDown : null}
                        onMouseMove={!showUploadButton && imagePresent && enableUpdates ? handleMouseMove : null}
                        onMouseUp={!showUploadButton && imagePresent && enableUpdates ? handleTouchEnd : null}
                    >


                        {!showUploadButton && imagePresent &&
                            <div className="position-relative w-100 h-100 overflow-hidden">
                                {errorsLoadCount.current < 3 ?
                                    <img ref={imageRef} src={src || ""} alt="loading" className='position-absolute img-draggable' style={{ ...style }} onLoad={() => _handleOnLoadImage(true)} loading="lazy" onError={_handleImageError} />
                                    : <span className='position-absolute top-50 start-50 translate-middle text-center'>Errore caricamento immagine</span>}
                            </div>}
                        {showUploadButton && (
                            <div className="position-absolute w-75 h-75 d-flex justify-content-center align-items-center" style={{ ...style }}>
                                <span className="text-center bg-white">Tocca qui per caricare un'immagine</span>
                                <input type="file" multiple={false} data-max_length="20" className="opacity-0 w-0 position-absolute top-0 start-0 w-100 h-100" onChange={_changePhoto} accept="image/jpeg, image/png, image/tiff" lang='it' />
                            </div>
                        )}
                    </div>

                </div>
            </div>
            {editorPage &&
                <div className={`position-relative z-1000 me-2 ms-2 ${editorPage ? '' :
                    (overloresList?.length > 0 ? "mt-4" : "mt-3")}`}>
                    <LightHorizontalContainer>
                        <ButtonLink onClick={() => handleZoom({ deltaY: -1 })} disabled={disableZoomIn}>
                            <FontAwesomeIcon icon="fa-regular fa-magnifying-glass-plus" size="2x" />
                        </ButtonLink>
                        <ButtonLink onClick={() => handleRotate()}>
                            <FontAwesomeIcon icon="fa-regular fa-rotate-right" size="2x" />
                        </ButtonLink>
                        <ButtonLink onClick={() => handleZoom({ deltaY: 1 })} disabled={disableZoomOut}>
                            <FontAwesomeIcon icon="fa-regular fa-magnifying-glass-minus" size="2x" />
                        </ButtonLink>
                        <ButtonLink otherClass="pe-none position-relative">
                            <FontAwesomeIcon icon="fa-regular fa-images" size="2x" />
                            <input type="file" multiple={false} data-max_length="20" className="opacity-0 w-0 position-absolute top-0 start-0 w-100 h-100 pe-auto" onChange={_changePhoto} accept="image/jpeg, image/png, image/tiff" lang='it' />
                        </ButtonLink>
                        <ButtonLink onClick={() => handleReset()} otherClass={'z-1000'}>
                            <FontAwesomeIcon icon="fa-regular fa-compress" size="2x" />
                        </ButtonLink>
                    </LightHorizontalContainer>
                </div>}
        </div>
    )
}