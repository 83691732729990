import React, { useEffect } from "react";
import { Page } from '../../components/page';
import { CardAlbum, Button, AlbumList as AlbumListUI } from "../../ui";
import { useNavigate } from 'react-router-dom';
import { albumService } from "../../store/services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function AlbumList() {
    const [albums, setAlbums] = React.useState(undefined)
    const navigate = useNavigate();
    const token = localStorage.getItem('token');

    const handleOpen = (id) => {
        navigate('/album?id=' + id);
    };

    useEffect(() => {
        if (!albums) {
            albumService.getList().then((res) => {
                setAlbums(res);
            });
        }
    }, [albums]);

    const goToHome = () => {
        navigate('/');
    }

    const goToLogin = () => {
        navigate('/login');
    }

    if (!token) {
        return <Page>
            <div className="text-center w-100 h-100 d-flex flex-column justify-content-center align-items-center p-4">
                <h2>Devi effettuare il login per accedere a questa pagina</h2>
                <Button otherClass="btn-primary p-1 mt-3" onClick={goToLogin}>
                    <span className="text-tertiary  fs-5">
                        Vai al login
                    </span>
                </Button>
            </div>
        </Page>
    }

    return (
        <Page loading={!albums}>
            <div className="p-3 h-100">
                <AlbumListUI albums={albums} handleOpen={handleOpen} />
                {/* <CardSimple
                    otherClass={"bg-primary shadow mt-4"}
                    children={<><div className="d-flex justify-content-between">
                        <p className="fw-bold">
                            Memoria premium usata:
                        </p>
                        <p>
                            0Gb / 10Gb
                        </p>
                    </div>
                        <ProgressBarMemory percentage={0} />
                    </>} >
                </CardSimple> */}
                {albums && albums.length === 0 && <div className='text-center w-100 h-100 d-flex flex-column justify-content-center align-items-center p-4'>
                    <FontAwesomeIcon icon="fa-regular fa-face-sad-cry" className='mb-1' size='3x' />
                    <div className='mb-1 fs-4 fw-bold'>Non hai ancora nessun album</div>
                    <div className='mb-3 fs-6'>Crea una smartphoto della tua ultima vacanza o regalala ad un amico per festeggiare un evento speciale trascorso insieme!</div>
                    <Button otherClass="btn-primary p-1" onClick={goToHome}>
                        <span className="text-tertiary  fs-5">
                            <FontAwesomeIcon icon="fa-regular fa-cart-plus" className='me-2' />
                            Aggiungi un prodotto
                        </span>
                    </Button>
                </div>}
            </div>
        </Page >
    );
}