import React, { useEffect, useMemo } from "react";
import { Page } from '../../components/page';
import { Button, ImgWithPlaceholder, PlaceholdersLoaderGif, VideoWithPlaceholder, ToastInfo } from "../../ui";
import { useNavigate } from "react-router-dom";
import { MediaCarousel, MediaSettings, UploadButtonAlbum } from "../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { albumService } from "../../store/services";
import { getAllUrlParams } from "../../libs";
import { Modals } from "./modals";

export function Album() {
    const { id, fromNFC, owner } = getAllUrlParams();
    const navigate = useNavigate();
    const [data, setData] = React.useState(undefined);
    const [media, setMedia] = React.useState(undefined);
    const [indexMediaClicked, setIndexMediaClicked] = React.useState(null);
    const [settingId, setSettingId] = React.useState(null);
    const [password, setPassword] = React.useState('');
    const [passwordError, setPasswordError] = React.useState(false);
    const [showToast, setShowToast] = React.useState(false);
    const [messageToast, setMessageToast] = React.useState('');
    const [showNotAccessError, setShowNotAccessError] = React.useState(false);
    const onlyReadAlbum = (owner && owner !== localStorage.getItem('email')) || !localStorage.getItem('email');

    useEffect(() => {
        if (!data) {
            albumService.get(id).then((res) => {
                if(res.status === 403) {
                    setShowNotAccessError(true);
                    return;
                }
                setData(res);
            });
        }
    }, [data, id]);

    useEffect(() => {
        if (!media && data) {
            albumService.getFiles(id).then((res) => {
                if (res.status)
                    return;
                setMedia(res);
                if (fromNFC) {
                    const indexCover = res.findIndex((item) => item.id === data.coverId);
                    setIndexMediaClicked(indexCover);
                }
            });
        }
    }, [data, fromNFC, id, media]);

    const handlePrivacy = () => {
        navigate(`/album-privacy?id=${id}&privacy=${data.privacy}&title=${data.name}`);
    }

    const handleGift = () => {
        navigate(`/album-gift?id=${id}&title=${data.name}`);
    }

    const handleNewMedia = (newMedia) => {
        setMedia([...media, ...newMedia]);
        // Get new size of album
        albumService.get(id).then((res) => {
            setData(res);
        });
    }

    const handleClickMedia = (index) => {
        setIndexMediaClicked(index);
    }

    const handleAlbumLink = () => {
        // const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        const url = window.location.href;
        const text = `Guarda il mio album!: ${url}&owner=${localStorage.getItem('email')}`;
        const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(text)}`;
        window.open(whatsappUrl, '_blank');
    };

    const handleDeleteFile = () => {
        const id = media[indexMediaClicked].id;
        albumService.deleteFile(id).then((res) => {
            if (res.status === 204) {
                const newMedia = media.filter((item) => item.id !== id);
                setMedia(newMedia);
                setSettingId(null);
                // update indexMediaClicked
                if (newMedia.length === 0) {
                    setIndexMediaClicked(null);
                } else {
                    setIndexMediaClicked(prev => prev - 1);
                }
            }
        });
    }

    const handleSetCover = () => {
        const idFile = media[indexMediaClicked].id;
        albumService.setAlbumCover(id, idFile).then((res) => {
            setMessageToast('Cover cambiata con successo');
            setShowToast(true);
            setTimeout(() => {
                setShowToast(false);
            }, 3000);
        });
    }

    const confirmChangeName = () => {
        const newName = { name: data.name };
        albumService.put(id, newName).then((res) => {
        });
    }

    const confirmRemoveAlbum = () => {
        albumService.deleteAlbum(id, password).then((res) => {
            if (res.status === 204) {
                navigate('/album-list');
            } else {
                setPasswordError(true);
            }
        });
    }

    const organizeMedia = useMemo(() => {
        if (!media) return { col1: [], col2: [], col3: [] };
        const col1 = [], col2 = [], col3 = [];
        media.forEach((item, index) => {
            if (index % 3 === 0)
                col1.push(item);
            else if (index % 3 === 1)
                col2.push(item);
            else
                col3.push(item);
        });
        return { col1, col2, col3 };
    }, [media]);

    if(showNotAccessError) {
        return <Page title="Errore">
            <div className="container mt-2">
                Non hai accesso a questo album, se il link ti è stato condiviso da un amico digli di modificarne la privacy
                </div>
        </Page>
    }

    return (
        <Page title={<div className='text-decoration-underline cursor-pointer'
            data-bs-toggle="modal" data-bs-target={!onlyReadAlbum ? "#changeName" : ""}>
            {data ? data.name : "-"}
            {!onlyReadAlbum && <FontAwesomeIcon icon="fa-regular fa-pen" className="ms-2" />}
        </div>}
            loading={data === undefined || media === undefined}
            hideContainer={indexMediaClicked !== null}
            customBottomSection={onlyReadAlbum  
                ? <></>
                : data &&
                <div className="border-top p-2">
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            <div>
                                <span className="fw-bold me-1">Elementi:</span>
                                {data.mediaCount}
                            </div>
                            <div>
                                <span className="fw-bold me-1">Associato ad una Smartphoto:</span>
                                {data.nfc ? 'Si' : 'No'}
                            </div>
                            <div>
                                <span className="fw-bold me-1">Memoria usata:</span>
                                {(data.memoryUsed / 1000000).toFixed(2)} Mb / {(data.memoryTotal / 1000000).toFixed(2)} Mb
                            </div>
                        </div>
                        <FontAwesomeIcon icon="fa-regular fa-share-nodes" className="text-dark bg-primary fs-3 border border-2 rounded-5 p-1 ps-2 pe-2" onClick={handleAlbumLink} />
                    </div>
                    <div className="d-flex justify-content-between mt-1 gap-1">
                        <Button otherClass={"p-1 btn-danger"} otherProps={{ 'data-bs-toggle': "modal", 'data-bs-target': "#removeAlbum" }}>
                            Elimina
                        </Button>
                        <Button otherClass={"p-1 btn-secondary"}
                            onClick={handlePrivacy}>
                            Privacy
                        </Button>
                        <Button otherClass={"p-1"} onClick={handleGift}>
                            Regala
                        </Button>
                    </div>
                </div>
            }>

            {
                !media ? <div>Loading...</div> :
                    <React.Fragment>
                        <div className="container mt-1">
                            <div className="row g-1 mb-1">
                                {['col1', 'col2', 'col3'].map((col, colIndex) => (
                                    <div key={colIndex} className="col-4">
                                        {organizeMedia[col].map((item, index) => {
                                            if (item.type.includes('image'))
                                                return <ImgWithPlaceholder src={item.url}
                                                    alt="image"
                                                    placeholder={PlaceholdersLoaderGif.loader_1200_1200}
                                                    className="w-100 mb-1 cursor-pointer"
                                                    onClick={() => handleClickMedia(index * 3 + colIndex)}
                                                    key={item.id}
                                                />
                                            if (item.type.includes('video'))
                                                return <VideoWithPlaceholder src={item.url}
                                                    className="w-100 mb-1 cursor-pointer"
                                                    onClick={() => handleClickMedia(index * 3 + colIndex)}
                                                    key={item.id}
                                                />
                                        })}
                                    </div>
                                ))}
                            </div>
                            {!onlyReadAlbum && <UploadButtonAlbum active={true}
                                memoryAvailable={data.memoryTotal - data.memoryUsed}
                                handleNewMedia={handleNewMedia}
                                albumId={id}
                            />}
                        </div>
                        {indexMediaClicked !== null &&
                            <MediaCarousel
                                media={media}
                                initialSlide={indexMediaClicked}
                                handleClose={() => setIndexMediaClicked(null)}
                                handleSettings={setSettingId}
                                onlyReadAlbum={onlyReadAlbum}
                            />}
                        <MediaSettings visible={settingId !== null}
                            handleClose={() => setSettingId(null)}
                            handleDelete={handleDeleteFile}
                            handleSetCover={handleSetCover} />
                    </React.Fragment>
            }

            {showToast && <ToastInfo children={messageToast} warningImageFormat={true} />}

            {
                data &&
                <Modals
                    name={data.name}
                    onChangeNameAlbum={(e) => setData({ ...data, name: e.target.value })}
                    confirmChangeName={confirmChangeName}
                    confirmRemoveAlbum={confirmRemoveAlbum}
                    password={password}
                    onChangePassword={(e) => setPassword(e.target.value)}
                    passwordError={passwordError}
                />
            }
        </Page>
    );
}