import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useDispatch } from 'react-redux';
import { productDataActions, alertActions } from '../../store/actions';
import { ProgressbarCircle, AlertFixed } from '../../ui';
import { initStyle } from '../../libs/image';

const ALERT_MESSAGES = {
    TOO_MANY_IMAGES: 'Troppe immagini selezionate, il limite è di ',
    ERROR_DURING_UPLOAD: 'Errore durante il caricamento delle immagini, potrebbe essere causato dal caricamento di immagini non salvate in locale. Salvale in locale e riprova.'
}

export function UploadButton({ maxQuantity, setImageUploading, resetWarn, active, processingType, template }) {
    const dispatch = useDispatch();
    const [showAlert, setShowAlert] = React.useState(false);
    const [internalImageUploading, setInternalImageUploading] = React.useState(false);
    const [errorDuringUpload, setErrorDuringUpload] = React.useState(false);
    const [imagesUploaded, setImagesUploaded] = React.useState(4);
    const [allImages, setAllImages] = React.useState(10);
    const [alertMessage, setAlertMessage] = React.useState('');
    const imagesWithNotValidFormat = React.useRef(0);

    React.useEffect(() => {
        if (errorDuringUpload) {
            setAlertMessage(ALERT_MESSAGES.ERROR_DURING_UPLOAD);
            setShowAlert(true);
        }
    }, [errorDuringUpload])

    const loadImages = async (file) => {
        if (file.type.match(/(jpeg|jpg|tiff|tif|png)$/)) {
            await new Promise((resolve, reject) => {
                const urlImage = URL.createObjectURL(file);
                // create image
                const image = new Image();
                image.src = urlImage;
                image.onload = () => {
                    const style = initStyle({
                        imgWidth: image.width,
                        imgHeight: image.height,
                        processingType,
                        // I use add only for product with one template, in calendar I execute update when I add a new photo
                        template: template[0],
                    })
                    dispatch(productDataActions.add({
                        originalImage: urlImage,
                        imageName: file.name,
                        imageWidth: image.width,
                        imageHeight: image.height,
                        editorProps: {
                            x: 0,
                            y: 0,
                            zoom: 1,
                            rotate: 0,
                        },
                        quantity: 1,
                        initStyle: style,
                    }));
                    resolve();
                }
                image.onerror = () => {
                    resolve();
                }
            });
        }
        else {
            imagesWithNotValidFormat.current++;
        }
    }

    const handleButton = async (e) => {
        const files = e.target.files;

        setImageUploading(true);
        setInternalImageUploading(true);
        setErrorDuringUpload(false);
        setImagesUploaded(0);
        setAllImages(files.length);
        const copyFiles = files.length <= maxQuantity ? [...files] : [...files].slice(0, maxQuantity);
        if (files.length > maxQuantity) {
            setAlertMessage(ALERT_MESSAGES.TOO_MANY_IMAGES + maxQuantity);
            setShowAlert(true);
        }
        imagesWithNotValidFormat.current = 0;
        setTimeout(async () => {
            for (let i = 0; i < copyFiles.length; i++) {
                await loadImages(copyFiles[i]);
                setImagesUploaded(prev => prev + 1);
            }
            setImageUploading(false);
            setInternalImageUploading(false);
            resetWarn();
            if (imagesWithNotValidFormat.current > 0) {
                dispatch(alertActions.addToastProduct(imagesWithNotValidFormat.current === 1));
                imagesWithNotValidFormat.current = 0;
            }
        }, 100);
        // clean input
        e.target.value = "";
    }

    const handleCloseAlert = () => {
        setShowAlert(false);
    }

    const percentage = Math.round((imagesUploaded / allImages) * 100);
    return (
        <React.Fragment>
            <label className="w-100">
                <p className={`btn fw-bold btn-outline-primary border border-3 border-primary w-100 text-dark rounded-5 no-hover ${active ? '' : 'disabled'}`}>
                    <FontAwesomeIcon icon="fa-regular fa-square-plus" className="me-2" />
                    Carica Immagini
                </p>
                <input type="file" multiple={true} data-max_length={maxQuantity} className="opacity-0" onChange={handleButton} accept="image/jpeg, image/png, image/tiff" lang='it' disabled={!active} />
            </label>
            <AlertFixed message={alertMessage} showAlert={showAlert} handleCloseAlert={handleCloseAlert} />
            {internalImageUploading && <div className="position-fixed top-0 start-0 w-100 h-100 bg-white d-flex justify-content-center align-items-center flex-column" style={{ zIndex: 9999 }}>
                <ProgressbarCircle percentage={percentage} delay={false} />
                <h2 className='mt-4'>{imagesUploaded} / {allImages}</h2>
                <h1 className='mt-4'>Caricamento immagini...</h1>
            </div>}
        </React.Fragment>
    );
}