import React from 'react';

export function Accordion({ id = 'accordion', items }) {
    const [active, setActive] = React.useState(-1);

    const accordionClicked = (index) => {
        if (active === index) {
            setActive(-1);
        } else {
            setActive(index);
            setTimeout(() => {
                const element = document.getElementById('page');
                element.scrollTo({top:element.scrollHeight, left: 0, behavior: 'smooth' });
            }, 200);
        }
    }

    return (
        <div className="accordion border-start border-end border-2 rounded-3 overflow-hidden" id={id}>
            {items.map((item, index) => {
                return (
                    <div className="accordion-item rounded-0 border-start-0 border-end-0" key={index} onClick={() => accordionClicked(index)}>
                        <h2 className="accordion-header" id={`heading${item.id}`}>
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${item.id}`} aria-expanded="true" aria-controls={`collapse${item.id}`}>
                                {item.question}
                            </button>
                        </h2>
                        <div id={`collapse${item.id}`} className="accordion-collapse collapse" aria-labelledby={`heading${item.id}`} data-bs-parent={`#${id}`}>
                            <div className="accordion-body" dangerouslySetInnerHTML={{ __html: item.answer }}>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    );
}