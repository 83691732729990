export const getCountryList = () => {
    /** return all country from database in this format:
     * {
            label: '<Name>',
            value: '<Code>',
        }
     */
    return _database.map((country) => {
        return {
            label: country.Name,
            value: country.Code,
        }
    })
}

export const provincesExist = (country) => {
    const countryObj = _database.find((c) => c.Code === country)
    return countryObj?.StateDivision
}

export const getProvinceList = (country) => {
    /** return all province from database in this format:
     * {
            label: '<Name>',
            value: '<Code>',
        }
     */
        
    const countryObj = _database.find((c) => c.Code === country)
    if(countryObj?.StateDivision && countryObj?.States) {
        return countryObj.States.map((state) => {
            return {
                label: state.Name,
                value: state.Code,
            }
        })
    }
    return null
}

/**
 * Return rows for billing address in the correct order
 * @param {*} address 
 * @returns 
 */
export const formatBillingAddress = (address) => {
    if(!address) return [];
    let a = [
        `C.F: ${address.fiscalCode}`,
        `${address.address}`,
        `${address.zipCode} ${address.city} (${address.state})`,
        `${address.countryLabel}`,
      ]
      if (address?.vatNumber) {
        a.splice(1, 0, `P.IVA: ${address.vatNumber}`);
    }
    return a
}

/**
 * Return rows for shipping address in the correct order
 * @param {*} address 
 * @returns 
 */
export const formatShippingAddress = (address) => {
    if(!address) return [];
    return [
        address.address,
        `${address.zipCode} ${address.city} (${address.state})`,
        address.countryLabel,
    ]
}

const _database = [
	{
		"Name": "Austria",
		"Code": "AT",
		"StateDivision": false,
		"ZipCodeDivision": true,
		"States": null
	},
	{
		"Name": "Belgio",
		"Code": "BE",
		"StateDivision": false,
		"ZipCodeDivision": true,
		"States": null
	},
	{
		"Name": "Bulgaria",
		"Code": "BG",
		"StateDivision": true,
		"ZipCodeDivision": true,
		"States": null
	},
	{
		"Name": "Croazia",
		"Code": "HR",
		"StateDivision": false,
		"ZipCodeDivision": true,
		"States": null
	},
	{
		"Name": "Danimarca",
		"Code": "DK",
		"StateDivision": false,
		"ZipCodeDivision": true,
		"States": null
	},
	{
		"Name": "Estonia",
		"Code": "EE",
		"StateDivision": false,
		"ZipCodeDivision": true,
		"States": null
	},
	{
		"Name": "Finlandia",
		"Code": "FI",
		"StateDivision": false,
		"ZipCodeDivision": true,
		"States": null
	},
	{
		"Name": "Francia",
		"Code": "FR",
		"StateDivision": false,
		"ZipCodeDivision": true,
		"States": null
	},
	{
		"Name": "Germania",
		"Code": "DE",
		"StateDivision": false,
		"ZipCodeDivision": true,
		"States": null
	},
	{
		"Name": "Grecia",
		"Code": "GR",
		"StateDivision": false,
		"ZipCodeDivision": true,
		"States": null
	},
	{
		"Name": "Italia",
		"Code": "IT",
		"StateDivision": true,
		"ZipCodeDivision": true,
		"States": [
			{
				"Code": "AG",
				"Name": "Agrigento"
			},
			{
				"Code": "AL",
				"Name": "Alessandria"
			},
			{
				"Code": "AN",
				"Name": "Ancona"
			},
			{
				"Code": "AO",
				"Name": "Aosta (Aoste)"
			},
			{
				"Code": "AP",
				"Name": "Ascoli Piceno"
			},
			{
				"Code": "AQ",
				"Name": "L'Aquila"
			},
			{
				"Code": "AR",
				"Name": "Arezzo"
			},
			{
				"Code": "AT",
				"Name": "Asti"
			},
			{
				"Code": "AV",
				"Name": "Avellino"
			},
			{
				"Code": "BA",
				"Name": "Bari"
			},
			{
				"Code": "BG",
				"Name": "Bergamo"
			},
			{
				"Code": "BI",
				"Name": "Biella"
			},
			{
				"Code": "BL",
				"Name": "Belluno"
			},
			{
				"Code": "BN",
				"Name": "Benevento"
			},
			{
				"Code": "BO",
				"Name": "Bologna"
			},
			{
				"Code": "BR",
				"Name": "Brindisi"
			},
			{
				"Code": "BS",
				"Name": "Brescia"
			},
			{
				"Code": "BT",
				"Name": "Barletta-Andria-Trani"
			},
			{
				"Code": "BZ",
				"Name": "Bolzano (Bozen)"
			},
			{
				"Code": "CA",
				"Name": "Cagliari"
			},
			{
				"Code": "CB",
				"Name": "Campobasso"
			},
			{
				"Code": "CE",
				"Name": "Caserta"
			},
			{
				"Code": "CH",
				"Name": "Chieti"
			},
			{
				"Code": "CL",
				"Name": "Caltanissetta"
			},
			{
				"Code": "CN",
				"Name": "Cuneo"
			},
			{
				"Code": "CO",
				"Name": "Como"
			},
			{
				"Code": "CR",
				"Name": "Cremona"
			},
			{
				"Code": "CS",
				"Name": "Cosenza"
			},
			{
				"Code": "CT",
				"Name": "Catania"
			},
			{
				"Code": "CZ",
				"Name": "Catanzaro"
			},
			{
				"Code": "EN",
				"Name": "Enna"
			},
			{
				"Code": "FC",
				"Name": "Forlì-Cesena"
			},
			{
				"Code": "FE",
				"Name": "Ferrara"
			},
			{
				"Code": "FG",
				"Name": "Foggia"
			},
			{
				"Code": "FI",
				"Name": "Firenze"
			},
			{
				"Code": "FM",
				"Name": "Fermo"
			},
			{
				"Code": "FR",
				"Name": "Frosinone"
			},
			{
				"Code": "GE",
				"Name": "Genova"
			},
			{
				"Code": "GO",
				"Name": "Gorizia"
			},
			{
				"Code": "GR",
				"Name": "Grosseto"
			},
			{
				"Code": "IM",
				"Name": "Imperia"
			},
			{
				"Code": "IS",
				"Name": "Isernia"
			},
			{
				"Code": "KR",
				"Name": "Crotone"
			},
			{
				"Code": "LC",
				"Name": "Lecco"
			},
			{
				"Code": "LE",
				"Name": "Lecce"
			},
			{
				"Code": "LI",
				"Name": "Livorno"
			},
			{
				"Code": "LO",
				"Name": "Lodi"
			},
			{
				"Code": "LT",
				"Name": "Latina"
			},
			{
				"Code": "LU",
				"Name": "Lucca"
			},
			{
				"Code": "MB",
				"Name": "Monza e Brianza"
			},
			{
				"Code": "MC",
				"Name": "Macerata"
			},
			{
				"Code": "ME",
				"Name": "Messina"
			},
			{
				"Code": "MI",
				"Name": "Milano"
			},
			{
				"Code": "MN",
				"Name": "Mantova"
			},
			{
				"Code": "MO",
				"Name": "Modena"
			},
			{
				"Code": "MS",
				"Name": "Massa e Carrara"
			},
			{
				"Code": "MT",
				"Name": "Matera"
			},
			{
				"Code": "NA",
				"Name": "Napoli"
			},
			{
				"Code": "NO",
				"Name": "Novara"
			},
			{
				"Code": "NU",
				"Name": "Nuoro"
			},
			{
				"Code": "OR",
				"Name": "Oristano"
			},
			{
				"Code": "PA",
				"Name": "Palermo"
			},
			{
				"Code": "PC",
				"Name": "Piacenza"
			},
			{
				"Code": "PD",
				"Name": "Padova"
			},
			{
				"Code": "PE",
				"Name": "Pescara"
			},
			{
				"Code": "PG",
				"Name": "Perugia"
			},
			{
				"Code": "PI",
				"Name": "Pisa"
			},
			{
				"Code": "PN",
				"Name": "Pordenone"
			},
			{
				"Code": "PO",
				"Name": "Prato"
			},
			{
				"Code": "PR",
				"Name": "Parma"
			},
			{
				"Code": "PT",
				"Name": "Pistoia"
			},
			{
				"Code": "PU",
				"Name": "Pesaro e Urbino"
			},
			{
				"Code": "PV",
				"Name": "Pavia"
			},
			{
				"Code": "PZ",
				"Name": "Potenza"
			},
			{
				"Code": "RA",
				"Name": "Ravenna"
			},
			{
				"Code": "RC",
				"Name": "Reggio Calabria"
			},
			{
				"Code": "RE",
				"Name": "Reggio Emilia"
			},
			{
				"Code": "RG",
				"Name": "Ragusa"
			},
			{
				"Code": "RI",
				"Name": "Rieti"
			},
			{
				"Code": "RM",
				"Name": "Roma"
			},
			{
				"Code": "RN",
				"Name": "Rimini"
			},
			{
				"Code": "RO",
				"Name": "Rovigo"
			},
			{
				"Code": "SA",
				"Name": "Salerno"
			},
			{
				"Code": "SI",
				"Name": "Siena"
			},
			{
				"Code": "SO",
				"Name": "Sondrio"
			},
			{
				"Code": "SP",
				"Name": "La Spezia"
			},
			{
				"Code": "SR",
				"Name": "Siracusa"
			},
			{
				"Code": "SS",
				"Name": "Sassari"
			},
			{
				"Code": "SU",
				"Name": "Sud Sardegna"
			},
			{
				"Code": "SV",
				"Name": "Savona"
			},
			{
				"Code": "TA",
				"Name": "Taranto"
			},
			{
				"Code": "TE",
				"Name": "Teramo"
			},
			{
				"Code": "TN",
				"Name": "Trento"
			},
			{
				"Code": "TO",
				"Name": "Torino"
			},
			{
				"Code": "TP",
				"Name": "Trapani"
			},
			{
				"Code": "TR",
				"Name": "Terni"
			},
			{
				"Code": "TS",
				"Name": "Trieste"
			},
			{
				"Code": "TV",
				"Name": "Treviso"
			},
			{
				"Code": "UD",
				"Name": "Udine"
			},
			{
				"Code": "VA",
				"Name": "Varese"
			},
			{
				"Code": "VB",
				"Name": "Verbano-Cusio-Ossola"
			},
			{
				"Code": "VC",
				"Name": "Vercelli"
			},
			{
				"Code": "VE",
				"Name": "Venezia"
			},
			{
				"Code": "VI",
				"Name": "Vicenza"
			},
			{
				"Code": "VR",
				"Name": "Verona"
			},
			{
				"Code": "VT",
				"Name": "Viterbo"
			},
			{
				"Code": "VV",
				"Name": "Vibo Valentia"
			}
		]
	},
	{
		"Name": "Lettonia",
		"Code": "LV",
		"StateDivision": false,
		"ZipCodeDivision": true,
		"States": null
	},
	{
		"Name": "Lituania",
		"Code": "LT",
		"StateDivision": false,
		"ZipCodeDivision": true,
		"States": null
	},
	{
		"Name": "Lussemburgo",
		"Code": "LU",
		"StateDivision": false,
		"ZipCodeDivision": true,
		"States": null
	},
	{
		"Name": "Monaco",
		"Code": "MC",
		"StateDivision": false,
		"ZipCodeDivision": true,
		"States": null
	},
	{
		"Name": "Paesi Bassi",
		"Code": "NL",
		"StateDivision": false,
		"ZipCodeDivision": true,
		"States": null
	},
	{
		"Name": "Polonia",
		"Code": "PL",
		"StateDivision": false,
		"ZipCodeDivision": true,
		"States": null
	},
	{
		"Name": "Portogallo",
		"Code": "PT",
		"StateDivision": false,
		"ZipCodeDivision": true,
		"States": null
	},
	{
		"Name": "Repubblica Ceca",
		"Code": "CZ",
		"StateDivision": false,
		"ZipCodeDivision": true,
		"States": null
	},
	{
		"Name": "Repubblica Slovacca",
		"Code": "SK",
		"StateDivision": false,
		"ZipCodeDivision": true,
		"States": null
	},
	{
		"Name": "Romania",
		"Code": "RO",
		"StateDivision": false,
		"ZipCodeDivision": true,
		"States": null
	},
	{
		"Name": "San Marino",
		"Code": "SM",
		"StateDivision": false,
		"ZipCodeDivision": true,
		"States": null
	},
	{
		"Name": "Slovenia",
		"Code": "SI",
		"StateDivision": false,
		"ZipCodeDivision": true,
		"States": null
	},
	{
		"Name": "Spagna",
		"Code": "ES",
		"StateDivision": true,
		"ZipCodeDivision": true,
		"States": [
			{
				"Code": "A",
				"Name": "Alicante"
			},
			{
				"Code": "AB",
				"Name": "Albacete"
			},
			{
				"Code": "AL",
				"Name": "Almería"
			},
			{
				"Code": "AV",
				"Name": "Ávila"
			},
			{
				"Code": "B",
				"Name": "Barcellona"
			},
			{
				"Code": "BA",
				"Name": "Badajoz"
			},
			{
				"Code": "BI",
				"Name": "Vizcaya"
			},
			{
				"Code": "BU",
				"Name": "Burgos"
			},
			{
				"Code": "C",
				"Name": "La Coruña"
			},
			{
				"Code": "CA",
				"Name": "Cádice"
			},
			{
				"Code": "CC",
				"Name": "Cáceres"
			},
			{
				"Code": "CE",
				"Name": "Ceuta"
			},
			{
				"Code": "CO",
				"Name": "Córdoba"
			},
			{
				"Code": "CR",
				"Name": "Ciudad Real"
			},
			{
				"Code": "CS",
				"Name": "Castellón"
			},
			{
				"Code": "CU",
				"Name": "Cuenca"
			},
			{
				"Code": "GC",
				"Name": "Las Palmas"
			},
			{
				"Code": "GI",
				"Name": "Girona"
			},
			{
				"Code": "GR",
				"Name": "Granada"
			},
			{
				"Code": "GU",
				"Name": "Guadalajara"
			},
			{
				"Code": "H",
				"Name": "Huelva"
			},
			{
				"Code": "HU",
				"Name": "Huesca"
			},
			{
				"Code": "J",
				"Name": "Jaén"
			},
			{
				"Code": "L",
				"Name": "Lleida"
			},
			{
				"Code": "LE",
				"Name": "León"
			},
			{
				"Code": "LO",
				"Name": "La Rioja"
			},
			{
				"Code": "LU",
				"Name": "Lugo"
			},
			{
				"Code": "M",
				"Name": "Madrid"
			},
			{
				"Code": "MA",
				"Name": "Málaga"
			},
			{
				"Code": "ML",
				"Name": "Melilla"
			},
			{
				"Code": "MU",
				"Name": "Murcia"
			},
			{
				"Code": "NA",
				"Name": "Navarra"
			},
			{
				"Code": "O",
				"Name": "Asturie"
			},
			{
				"Code": "OR",
				"Name": "Ourense"
			},
			{
				"Code": "P",
				"Name": "Palencia"
			},
			{
				"Code": "PM",
				"Name": "Baleari"
			},
			{
				"Code": "PO",
				"Name": "Pontevedra"
			},
			{
				"Code": "S",
				"Name": "Cantabria"
			},
			{
				"Code": "SA",
				"Name": "Salamanca"
			},
			{
				"Code": "SE",
				"Name": "Siviglia"
			},
			{
				"Code": "SG",
				"Name": "Segovia"
			},
			{
				"Code": "SO",
				"Name": "Soria"
			},
			{
				"Code": "SS",
				"Name": "Guipúzcoa"
			},
			{
				"Code": "T",
				"Name": "Tarragona"
			},
			{
				"Code": "TE",
				"Name": "Teruel"
			},
			{
				"Code": "TF",
				"Name": "Santa Cruz De Tenerife"
			},
			{
				"Code": "TO",
				"Name": "Toledo"
			},
			{
				"Code": "V",
				"Name": "Valencia"
			},
			{
				"Code": "VA",
				"Name": "Valladolid"
			},
			{
				"Code": "VI",
				"Name": "Álava"
			},
			{
				"Code": "Z",
				"Name": "Saragozza"
			},
			{
				"Code": "ZA",
				"Name": "Zamora"
			}
		]
	},
	{
		"Name": "Svezia",
		"Code": "SE",
		"StateDivision": false,
		"ZipCodeDivision": true,
		"States": null
	},
	{
		"Name": "Ungheria",
		"Code": "HU",
		"StateDivision": false,
		"ZipCodeDivision": true,
		"States": null
	}
]