import { getProvinceList } from '../../libs';

const countryList = [{
  label: 'Italia',
  value: 'IT',
},]//getCountryList();

export const privateFormData = [
    {
      label: 'A chi è intestata la fattura?',
      name: 'owner',
      type: 'radio',
      options: [
        {
          label: 'Privato',
          value: 'private'
        },
        {
          label: 'Partita IVA',
          value: 'vat'
        }
      ]
    },
    {
      label: 'Nazione',
      name: 'country',
      placeholder: ' ',
      type: 'select',
      options: countryList
    },
    {
      label: 'Intestatario * (es: Mario Rossi)',
      name: 'name',
      placeholder: ' ',
      maxLength: 35
    },
    {
      label: 'Codice Fiscale * (es: RSSMRN80A01H501R)',
      type: 'label',
    },
    {
      type: 'cf',
      subItem1: {
        name: 'vatCountry',
        options: countryList
      },
      subItem2: {
        name: 'fiscalCode',
        placeholder: ' '
      }
    },
    {
      label: 'Indirizzo * (es: Via Roma 1)',
      name: 'address',
      placeholder: ' ',
      maxLength: 35
    },
    {
      label: 'Città * (es: Roma)',
      name: 'city',
      placeholder: ' ',
      maxLength: 29
    },
    {
      label: 'Cap * (es: 00100)',
      name: 'zipCode',
      placeholder: ' ',
      maxLength: 5,
      type: 'number'
    },
    {
      label: 'Provincia',
      name: 'state',
      placeholder: ' ',
      type: 'select',
      options: getProvinceList('IT')
    }
  ]

export const vatFormData = [
    {
      label: 'A chi è intestata la fattura?',
      name: 'owner',
      type: 'radio',
      options: [
        {
          label: 'Privato',
          value: 'private'
        },
        {
          label: 'Partita IVA',
          value: 'vat'
        }
      ]
    },
    {
      label: 'Nazione',
      name: 'country',
      placeholder: ' ',
      type: 'select',
      options: countryList
    },
    {
      label: 'Intestatario * (es: Mario Rossi)',
      name: 'name',
      placeholder: ' ',
      maxLength: 35
    },
    {
      label: 'Codice Fiscale * (es: RSSMRN80A01H501R)',
      type: 'label',
    },
    {
      type: 'cf',
      subItem1: {
        name: 'vatCountry',
        options: countryList
      },
      subItem2: {
        name: 'fiscalCode',
        placeholder: ' '
      }
    },
    {
      label: 'Partita IVA * (es: 00000000000000)',
      type: 'label',
    },
    {
      type: 'vat',
      subItem1: {
        name: 'vatCountry',
        options: countryList
      },
      subItem2: {
        name: 'vatNumber',
        placeholder: ' '
      }
    },
    {
      label: 'Indirizzo * (es: Via Roma 1)',
      name: 'address',
      placeholder: ' ',
      maxLength: 35
    },
    {
      label: 'Città *( es: Roma)',
      name: 'city',
      placeholder: ' ',
      maxLength: 29
    },
    {
      label: 'Cap * (es: 00100)',
      name: 'zipCode',
      placeholder: ' ',
      maxLength: 5,
      type: 'number'
    },
    {
      label: 'Provincia',
      name: 'state',
      placeholder: ' ',
      type: 'select',
      options: getProvinceList('IT')
    },
    {
      label: 'Codice Univoco * (es: 0000000)',
      name: 'recipientCode',
      placeholder: ' ',
      maxLength: 7,
      alterativeField: 'pec',
    },
    {
      label: 'PEC Destinatario * (es: mario.rossi@pec.it)',
      name: 'pec',
      maxLength: 35,
      placeholder: ' ',
      alterativeField: 'recipientCode',
    }
  ]