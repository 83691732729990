import { TemplateImage } from '../../../ui';

export function ListTemplatesImage({ templates, indexTemplate, setIndexTemplate }) {

    return (
        <ul className="row p-3 pt-0">
            {templates.map((item, index) => (
                <li className={`list-group-item border-0 p-0 col-6 p-3 pb-2`} key={index} onClick={() => setIndexTemplate(index)}>
                    <TemplateImage active={indexTemplate === index} title={item.name} image={item.images[0].url} />
                </li>
            ))}
        </ul>
    );
}