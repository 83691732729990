export const objectsAreEqual = (obj1, obj2) => {
    if(obj1 === obj2) {
        return true;
    }
    if(obj1 == null || obj2 == null) {
        return false;
    }
    const obj1Keys = Object.keys(obj1);
    const obj2Keys = Object.keys(obj2);

    if (obj1Keys.length !== obj2Keys.length) {
        return false;
    }

    const ret = obj1Keys.every(key => obj1[key] === obj2[key]);
    return ret;
};
