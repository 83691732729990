import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BottomButton, FormAddress, Page } from '../../components';
import { formatShippingAddress, getAllUrlParams, getCountryList, getProvinceList, provincesExist } from '../../libs';
import { userActions } from '../../store/actions';
import { CardAddress, Modal, Button } from '../../ui';

const checkFields = (addressForm) => {
    const { recipient, address, city, zipCode, phoneNumber, state } = addressForm;
    return recipient && address && city && zipCode && phoneNumber && (state || !['IT', 'ES'].includes(addressForm.country));
}

const initAddressForm = {
    country: 'IT',
    countryLabel: 'Italia',
    recipient: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    phoneNumber: '',
    courierNotes: ''
}

export function ShippingAddresses() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { index, check } = getAllUrlParams();
    const { shippingAddresses } = useSelector((state) => state.userData);
    const [addressForm, setAddressForm] = React.useState(initAddressForm);
    const [showError, setShowError] = React.useState(false);
    const [deleteIndex, setDeleteIndex] = React.useState(null);

    const formSchema = React.useMemo(() => {
        const isProvincesExist = provincesExist(addressForm.country);
        const provinces = getProvinceList(addressForm.country);
        const schema = [
            {
                label: 'Nazione',
                name: 'country',
                placeholder: ' ',
                type: 'select',
                options: getCountryList()
            },
            {
                label: 'Destinatario * (es: Mario Rossi)',
                name: 'recipient',
                placeholder: ' ',
                maxLength: 35
            },
            {
                label: 'Indirizzo * (es: Via Roma 1)',
                name: 'address',
                placeholder: ' ',
                maxLength: 35
            },
            {
                label: 'Città * (es: Roma)',
                name: 'city',
                placeholder: ' ',
                maxLength: 29
            },
        ]
        if (isProvincesExist && provinces) {
            // state if requested and we have a list of provinces
            schema.push({
                label: 'Provincia',
                name: 'state',
                placeholder: ' ',
                type: 'select',
                options: provinces
            })
        }
        if (isProvincesExist && !provinces) {
            // state if requested but we don't have a list of provinces
            schema.push({
                label: 'Provincia',
                name: 'state',
                placeholder: ' ',
                maxLength: 3
            })
        }
        if (addressForm.country === 'IT') {
            // zip code dedicated to Italy
            schema.push({
                label: 'Cap * (es: 00100)',
                name: 'zipCode',
                placeholder: ' ',
                maxLength: 5,
                type: 'number'
            })
        } else {
            // zip code dedicated to other countries
            schema.push({
                label: 'Cap * (es: 00100)',
                name: 'zipCode',
                placeholder: ' ',
                maxLength: 9
            })
        }
        schema.push(
            {
                label: 'Numero di Telefono * (es: 3333333333)',
                name: 'phoneNumber',
                placeholder: ' ',
                type: 'number',
                maxLength: 15
            },
            {
                label: 'Note Corriere',
                name: 'courierNotes',
                placeholder: ' ',
                optional: true,
                maxLength: 60
            }
        )
        return schema;
    }, [addressForm.country]);

    React.useEffect(() => {
        dispatch(userActions.getShippingAddresses());
    }, [dispatch]);

    React.useEffect(() => {
        if (shippingAddresses && index !== undefined && index !== '-1')
            setAddressForm(shippingAddresses[index]);
    }, [shippingAddresses, index]);

    const handleManageAddress = (_index) => {
        if (index === undefined) {
            // List page
            if (_index || _index === 0) {
                navigate(`/shipping-addresses?index=${_index}`);
                setAddressForm(shippingAddresses[_index]);
            }
            else {
                navigate('/shipping-addresses?index=-1');
                setAddressForm(initAddressForm)
            }
        } else {
            // Form page
            if (checkFields(addressForm)) {
                setShowError(false);
                if (_index === '-1')
                    dispatch(userActions.addShippingAddress(addressForm));
                else
                    dispatch(userActions.updateShippingAddress(addressForm, _index));
                window.history.back()
            }
            else
                setShowError(true);
        }
    };

    const handleSetDefault = (index) => {
        dispatch(userActions.setDefaultShippingAddress(index));
    };

    const handleDelete = () => {
        if (deleteIndex !== null)
            dispatch(userActions.removeShippingAddress(deleteIndex));
    };

    const title = React.useMemo(() => {
        if (index === undefined) {
            return 'Indirizzi di Spedizione';
        }
        if (index === '-1') {
            return 'Nuovo indirizzo';
        }
        return 'Modifica indirizzo';
    }, [index]);

    if ((shippingAddresses === null || shippingAddresses.length === 0) && index === undefined) {
        return (
            <Page
                title={title}
                visibleBottomNavigation={false}
            >
                <div className='text-center w-100 h-100 d-flex flex-column justify-content-center align-items-center p-4'>
                    <div className='mb-1 fs-4 fw-bold'>Non hai ancora aggiunto alcun indirizzo</div>
                    <div className='mb-3 fs-6'>
                        Dove vuoi che venga inviato il tuo ordine?
                    </div>
                    <Button otherClass="p-1" onClick={() => handleManageAddress(index)}>
                        <span className="text-dark fs-5">
                            <FontAwesomeIcon icon="fa-regular fa-plus" className='me-2' />
                            Aggiungi un indirizzo
                        </span>
                    </Button>
                </div>
            </Page>
        )
    }

    return (
        <Page
            title={title}
            visibleBottomNavigation={index || check ? true : false}
            customBottomSection={
                index ? <BottomButton onClick={() => handleManageAddress(index)} content={
                    <React.Fragment>
                        <FontAwesomeIcon
                            icon="fa-regular fa-floppy-disk" className="me-2" />
                        Salva Indirizzo
                    </React.Fragment>
                } />
                    : (
                        check && <BottomButton onClick={() => window.history.back()}
                            content={
                                <React.Fragment>
                                    Conferma
                                </React.Fragment>
                            } />
                    )
            }>
            {index === undefined ? shippingAddresses?.map((address, index) => {
                const rows = formatShippingAddress(address);
                return <React.Fragment>
                    <CardAddress
                        key={index}
                        title={address.recipient}
                        rows={rows}
                        note={address.courierNotes}
                        favorite={address.isDefault}
                        icon={address.isDefault ? <FontAwesomeIcon icon="fa-regular fa-house-heart" size='2x' className='text-primary' /> : <FontAwesomeIcon icon="fa-regular fa-house" size='2x' />}
                        handleEdit={() => handleManageAddress(index)}
                        handleSetDefault={() => handleSetDefault(index)}
                        handleDelete={() => setDeleteIndex(index)}
                        check={check && address.isDefault}
                        globalSetDefault={check}
                    />
                </React.Fragment>
            })
                : addressForm && <FormAddress
                    addressForm={addressForm}
                    showError={showError}
                    setAddressForm={setAddressForm}
                    formSchema={formSchema}
                />
            }
            {index === undefined && <div className="w-100 ps-3 pe-3">
                <Button onClick={() => handleManageAddress()} otherClass={"btn-secondary"}>
                    <FontAwesomeIcon
                        icon="fa-regular fa-circle-plus" className="me-2" />
                    Nuovo Indirizzo
                </Button>
            </div>}
            <Modal
                title={"Eliminazione Indirizzo di Spedizione"}
                text={"Vuoi eliminare l'indirizzo di spedizione? Questa operazione non può essere annullata."}
                primaryText="Conferma"
                secondaryText="Annulla"
                handleConfirm={handleDelete}
                id="addressModal"
            />
        </Page>
    );
}