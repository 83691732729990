import { combineReducers } from 'redux';
import alertData from './alert.reducer';
import mainData from './main.reducer';
import templateData from './template.reducer';
import cartData from './cart.reducer';
import productData from './product.reducer';
import userData from './user.reducer';
import orderData from './order.reducer';

export default combineReducers(
  {
    alertData,
    mainData,
    templateData,
    cartData,
    productData,
    userData,
    orderData
  }
)