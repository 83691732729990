import { common } from "./common.service"

export const albumService = {
    get,
    getList,
    getFromNFC,
    putAlbumToNFC,
    put,
    getFiles,
    deleteAlbum,
    uploadFile,
    deleteFile,
    setAlbumCover,
    changePrivacy,
    changeUser,
}

/**
 * Get list of all files of an album
 * @param {*} id 
 * @param {*} fingerprint 
 * @returns 
 */
async function getFiles(id) {
    let url = `files/${id}`;
    const userIsLogged = !!localStorage.getItem('token');
    if (userIsLogged) {
    return common.fetchAPI({ url, method: "GET", token: true });
    } else {
        return common.fetchAPI({ url, method: "GET" });
    }
}

/**
 * Get all informations of an album
 * @param {*} id 
 * @returns 
 */
async function get(id) {
    let url = `album/${id}`;
    const userIsLogged = !!localStorage.getItem('token');
    if (userIsLogged) {
        return common.fetchAPI({ url, method: "GET", token: true });
    } else
        return common.fetchAPI({ url, method: "GET" });
}

/**
 * Pass an NFC id to the server to get the album or list of albums
 * to associate one of them to the NFC
 * @param {*} data 
 * @returns 
 */
async function getFromNFC(id) {
    let url = `nfc/${id}`;
    return common.fetchAPI({ url, method: "GET", token: true });
}

/**
 * Associate an album to an NFC
 * @param {*} id 
 * @param {*} data 
 * @returns 
 */
async function putAlbumToNFC(id, idAlbum) {
    let url = `nfc/${id}`;
    let data = { album_id: idAlbum };
    return common.fetchAPI({ url, method: "PUT", data, token: true });
}

/**
 * Update an album
 * @param {*} id
 * @param {*} data
 * @returns 
 */
async function put(id, data) {
    let url = `album/${id}`;
    return common.fetchAPI({ url, method: "PUT", data, token: true });
}

/**
 * Get list of all albums
 * @returns 
 */
async function getList() {
    let url = `album`;
    return common.fetchAPI({ url, method: "GET", token: true });
}

/**
 * Delete an album
 * @param {*} id 
 * @param {*} password 
 * @returns 
 */
async function deleteAlbum(id, password) {
    let url = `album/${id}`;
    return common.fetchAPI({ url, method: "DELETE", data: { password }, token: true });
}

/**
 * Upload files
 */
async function uploadFile(data) {
    let url = `file`;
    return common.fetchAPI({ url, method: "POST", data, useStringify: false, token: true });
}

async function deleteFile(id) {
    let url = `file/${id}`;
    return common.fetchAPI({ url, method: "DELETE", token: true });
}

async function setAlbumCover(idAlbum, idFile) {
    let url = `album/${idAlbum}/cover`;
    return common.fetchAPI({ url, method: "PUT", data: { file_id: idFile }, token: true });
}

async function changePrivacy(idAlbum, privacy) {
    let url = `album/${idAlbum}/privacy`;
    return common.fetchAPI({ url, method: "PUT", data: { privacy }, token: true });
}

async function changeUser(idAlbum, email, password) {
    let url = `album/${idAlbum}/change-user`;
    return common.fetchAPI({ url, method: "PUT", data: { newUserEmail: email, password }, token: true });
}
