export function Button({ children, otherClass, onClick, disabled, otherProps}) {
    return (
        <button type="button" className={`btn rounded-5 w-100 text-dark fs-6 btn-primary ${otherClass ? otherClass : ""} ${disabled ? 'disabled' : ''}`}
            disabled={disabled}
            onClick={onClick}
            {...otherProps}
            >
            {children}
        </button>
    );
}