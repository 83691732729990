import { traslateCodeToStatus } from '../../libs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function CardOrders({ orderNumber, status, date, price, payNow, setRemoveIndex, goToDetails }) {
    const statusLabel = traslateCodeToStatus(status);

    window.dateAngelo = date;

    return (
        <div className="card p-2 shadow rounded-3 mb-4">
            <div className="fw-bold">
                Ordine {orderNumber}
            </div>
            <div className='text-primary fw-bold'>
                {statusLabel}
            </div>
            <div>
                Data: {new Date(date).toLocaleDateString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' })}
            </div>
            <div>
                Totale:<span className='fw-bold'> {price.toFixed(2).replace(".", ",")} €</span>
            </div>
            {statusLabel === "In Attesa di Pagamento" &&
                <div>
                    <div className='d-flex gap-2 h-100 mt-1'>
                        <button className="btn btn-primary rounded-5 fs-6 fw-bold" onClick={payNow}>
                            Paga Ora
                        </button>
                        <button className="btn btn-secondary rounded-5 fs-6  fw-bold" data-bs-toggle="modal" data-bs-target="#orderModal" onClick={setRemoveIndex}>
                            Cancella
                        </button>
                    </div>
                </div>}
            {statusLabel === "Spedito" && goToDetails &&
                <div>
                    <div className='d-flex gap-2 h-100 mt-1'>
                        <button className="btn btn-primary rounded-5 fs-6 fw-bold" onClick={goToDetails}>
                            <span className='me-2'>
                            Rintraccia ordine
                            </span>
                            <FontAwesomeIcon icon="fa-regular fa-external-link-alt" />
                        </button>
                    </div>
                </div>}
        </div>
    );
}