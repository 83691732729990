import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Page } from '../../components';
import { CardSmall, LabelIconText, CarouselSmall, CardSimple } from '../../ui';
import { productDataActions } from '../../store/actions';
import logo from '../../ui/assets/logo.png';
import ExampleGif from './example.gif';

export function Home() {
    const { categoryDict, categoryIDs, subCategoryDict } = useSelector(state => state.mainData);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [search, setSearch] = React.useState('');
    const [idAdmitted, setIdAdmitted] = React.useState([]);
    const delaySearch = React.useRef(null);
    const askedToInstall = localStorage.getItem('askedToInstall') === 'true';

    const handleCardClick = (categoryId, subCategoryId) => {
        navigate(`/details?categoryId=${categoryId}&subCategoryId=${subCategoryId}`);
    }

    React.useEffect(() => {
        if (delaySearch.current)
            clearTimeout(delaySearch.current);
        delaySearch.current = setTimeout(() => {
            if (search.length > 0) {
                const newIdAdmitted = [];
                Object.keys(subCategoryDict).forEach((subCategoryId) => {
                    if (subCategoryDict[subCategoryId].name.toLowerCase().includes(search.toLowerCase())) {
                        newIdAdmitted.push(parseInt(subCategoryId));
                    }
                });
                if (newIdAdmitted.length === 0)
                    newIdAdmitted.push(-1);
                setIdAdmitted(newIdAdmitted);
            }
            else
                setIdAdmitted([]);
        }, 500);
    }, [search, subCategoryDict]);

    React.useEffect(() => {
        dispatch(productDataActions.unlockClean());
    }, [dispatch]);

    const checkIdsAdmitted = (subCategoryIds) => {
        if (idAdmitted.length > 0)
            return subCategoryIds.some((subCategoryId) => idAdmitted.includes(subCategoryId));
        else
            return true;
    }

    const checkSearchInTitle = (title) => {
        if (search.length > 0)
            return title.toLowerCase().includes(search.toLowerCase());
        else
            return true;
    }

    return (
        <Page customTopSection={
            <React.Fragment>
                <div className='p-3 d-flex align-items-center bg-tertiary' style={{ height: '70px' }}>
                    <img src={logo} alt="logo" className='h-100 me-2' />
                    <input type="text" placeholder="Cerca un prodotto" className='w-100 form-control rounded-5' value={search} onChange={(e) => setSearch(e.target.value)} />
                    {search.length > 0
                        ? <button className="btn btn-link text-primary p-0" onClick={() => setSearch('')}>
                            <FontAwesomeIcon icon="fa-regular fa-x" className='ms-2' size='2x' />
                        </button>
                        : <button className="btn btn-link text-primary p-0">
                            <FontAwesomeIcon icon="fa-regular fa-search" className='ms-2' size='2x' />
                        </button>}
                </div>
                <div className='horizontal-line' />
            </React.Fragment>
        }>
            <div className='p-3'>
                <CardSimple otherClass="text-danger border border-danger border-2 text-center"
                    children={<>
                        <div>
                            App beta. Non è possibile effettuare acquisti al momento.
                        </div>
                    </>} />
                <div className='w-100 d-flex justify-content-center'>
                    <div className='col-12 col-sm-6'>
                        <div className='w-100 text-center fw-bold fs-1'>
                            Molto più di una foto!
                        </div>
                        <div className='w-100 text-center fs-5 fw-bold mb-2'>
                            ..un portale verso i tuoi ricordi digitali
                        </div>
                        <div className='w-100 text-center fs-6'>
                            Ogni foto include un album cloud da 500MB personale, sicuro e per sempre, pronto ad accogliere immagini e video
                        </div>

                        <div className="w-100 ratio ratio-16x9 mt-2 mb-2">
                            <img src={ExampleGif} alt="example" className="img-fluid" />
                        </div>
                        <div className='w-100 text-center fs-6 mb-2'>
                            Potrai accedervi facilmente tramite questa app o semplicemente avvicinando il tuo smartphone alla foto che riceverai a casa
                        </div>
                        <div className='w-100 text-center'>
                            {/** Open link in new tab */}
                            <a href='/landing-page' className='text-tertiary'>
                                SCOPRI DI PIÙ
                            </a>
                        </div>
                    </div>
                </div>
                <div className='w-100 text-center fw-bold mt-3 fs-1'>
                    Prodotti
                </div>
                {categoryIDs?.map((categoryId, index) => {
                    const searchedProduct = idAdmitted.length === 0 ? categoryDict[categoryId].subCategoryIDs
                        : categoryDict[categoryId].subCategoryIDs?.filter((subCategoryId) => idAdmitted.includes(subCategoryId) || checkSearchInTitle(categoryDict[categoryId].name));
                    const notCarouselElement = searchedProduct.filter((subCategoryId) => (subCategoryDict[subCategoryId].layoutType !== 2));
                    const carouselElement = searchedProduct.filter((subCategoryId) => subCategoryDict[subCategoryId].layoutType === 2);
                    return checkIdsAdmitted(categoryDict[categoryId].subCategoryIDs) || checkSearchInTitle(categoryDict[categoryId].name) ?
                        (<div className='row mt-3' key={index}>
                            <LabelIconText
                                text={categoryDict[categoryId].name}
                                otherClass="" />
                            {notCarouselElement.length > 0 && notCarouselElement.map((subCategoryId, index) => {
                                return <div onClick={() => handleCardClick(categoryId, subCategoryId)} key={index}
                                    className={`${subCategoryDict[subCategoryId].layoutType === 1 ? "col-12 col-sm-6 col-md-4" : "col-6 col-md-4"}`}>
                                    <CardSmall
                                        key={index}
                                        title={subCategoryDict[subCategoryId].name}
                                        image={subCategoryDict[subCategoryId].image}
                                        otherClass="cursor-pointer mb-3 mt-3"
                                    />
                                </div>
                            }
                            )}
                            {carouselElement.length > 0 &&
                                <CarouselSmall
                                    elements={carouselElement.map((subCategoryId, index) => {
                                        return <div onClick={() => handleCardClick(categoryId, subCategoryId)} key={index}
                                            className={`mb-4 mt-n1`}>
                                            <CardSmall
                                                key={index}
                                                title={subCategoryDict[subCategoryId].name}
                                                image={subCategoryDict[subCategoryId].image}
                                                otherClass="cursor-pointer mt-3 mb-3"
                                            />
                                        </div>
                                    })}
                                />}
                        </div>)
                        : null
                }
                )}
            </div>
            <div className='w-100 text-center fw-bold'>
                ...
            </div>
            <div className='w-100 text-center fw-bold mb-5'>
                Molti altri prodotti arriveranno a breve!
            </div>
        </Page >
    );
}
