import React from 'react';

export function FormAddress({ addressForm, setAddressForm, showError, formSchema }) {

    return (
        <form className={`p-2`}>
            {formSchema.map((item, index) => {
                if (item.type === 'select') {
                    const options = item.options;
                    if (!options)
                        return null;
                    return (
                        <div className="mb-3" key={index}>
                            <label htmlFor={`floatingSelect${index}`}>{item.label}</label>
                            <select className={`form-select rounded-5 ${!addressForm[item.name] && showError && !item.optional && !addressForm[item.alterativeField] ? 'is-invalid' : ''}`} id={`floatingSelect${index}`} aria-label={"Select for " + item.label}
                                value={addressForm[item.name]}
                                onChange={(e) => {
                                    if (item.name === 'country')
                                        setAddressForm({ ...addressForm, [item.name]: e.target.value, state: '', countryLabel: e.target.options[e.target.selectedIndex].text })
                                    else
                                        setAddressForm({ ...addressForm, [item.name]: e.target.value })
                                }}>
                                {item.name === 'state' && <option value={''} disabled>
                                    Seleziona una Provincia
                                </option>}
                                {options.map((option, index) => {
                                    return (
                                        <option key={index} value={option.value}>{option.label}</option>
                                    );
                                })}
                            </select>
                        </div>
                    )
                }
                if (item.type === 'radio') {
                    return (<div className="mb-2 p-1" key={index}>
                        {item.label}
                        <div className="d-flex mt-2 ms-1">
                            {item.options.map((option, index) => {
                                return (
                                    <div className="form-check me-3" key={index}>
                                        <input className="form-check-input rounded-5" type="radio" value={option.value}
                                            id={`defaultCheck${index}`}
                                            checked={addressForm[item.name] === option.value}
                                            onChange={(e) => setAddressForm({ ...addressForm, [item.name]: e.target.value })} />
                                        <label className="form-check-label">
                                            {option.label}
                                        </label>
                                    </div>
                                )
                            })}
                        </div>
                    </div>);
                }
                if (item.type === 'cf' || item.type === 'vat') {
                    const maxLength = item.type === 'cf' ? 16 : 11;
                    return <div className="d-flex mb-3" key={index}>
                        <select className="form-select w-50 rounded-5" id={`floatingSelect${index}`} aria-label={"Select for " + item.subItem1.name}
                            value={addressForm[item.subItem1.name]}
                            onChange={(e) => setAddressForm({ ...addressForm, [item.subItem1.name]: e.target.value })}>
                            {item.subItem1.options.map((option, index) => {
                                return (
                                    <option key={index} value={option.value}>{option.label}</option>
                                );
                            })}
                        </select>
                        <input type={item.type === 'cf' ? 'text' : 'number'}
                            className={`form-control ms-2 rounded-5 ${!addressForm[item.subItem2.name] && showError && !item.subItem2.optional && !addressForm[item.alterativeField] ? 'is-invalid' : ''}`}
                            placeholder={item.subItem2.placeholder}
                            value={addressForm[item.subItem2.name]}
                            onChange={(e) => {
                                if (item.type === 'cf' && e.target.value.length > 16)
                                    return;
                                if (item.type === 'vat' && e.target.value.length > 11)
                                    return;
                                setAddressForm({ ...addressForm, [item.subItem2.name]: e.target.value })
                            }
                            } maxLength={maxLength} />
                    </div>
                }

                if (item.type === 'label') {
                    return (
                        <div key={index}>
                            <label className="form-label">{item.label}</label>
                        </div>
                    );
                }
                return (
                    <div className='mb-3' key={index}>
                        <label htmlFor={`floatingInput${index}`}>{item.label} {item.placeholder !== ' ' ? '(' + item.placeholder + ')' : ''}</label>
                        <input
                            type={item.type || 'text'}
                            className={`form-control rounded-5 ${!addressForm[item.name] && showError && !item.optional && !addressForm[item.alterativeField] ? 'is-invalid' : ''}`}
                            id={`floatingInput${index}`}
                            placeholder={item.placeholder}
                            value={addressForm[item.name]}
                            onChange={(e) => e.target.value.length <= item.maxLength && setAddressForm({ ...addressForm, [item.name]: e.target.value })}
                            maxLength={item.maxLength}
                        />
                    </div>
                );
            })}
            <p className="fst-italic">I campi contrassegnati con (*) sono obbligatori</p>
        </form>
    );
}