import React from 'react';
import { useDispatch } from 'react-redux';
import { cartActions } from '../../store/actions';
import { Modal } from '../../ui';

export function ListCart({ renderedCartList, removeIndexId, setRemovingProduct }) {
    const dispatch = useDispatch();

    const handleRemove = () => {
        const { index, id } = removeIndexId;
        setRemovingProduct(id);
        setTimeout(() => {
            dispatch(cartActions.remove(index));
            setRemovingProduct(-1);
        }, 200);
    }

    return (
        <React.Fragment>
            <div className='p-3 h-100'>
                <div className="row">
                    {renderedCartList}
                </div>
            </div>
            <Modal
                title="Rimozione Prodotto dal Carrello"
                text="Vuoi rimuovere questo prodotto dal carrello? Una volta eliminato non potrai più recuperarlo."
                primaryText="Elimina"
                secondaryText="Annulla"
                handleConfirm={handleRemove}
                id="cartModal"
            />
        </React.Fragment>
    );
}