/**
 * Function to get all the query parameters from the URL
 * @returns {string} object with all parameters in the url
 */
export const getAllUrlParams = (url='') => {
    const urlParams = new URLSearchParams(url || window.location.search);
    const params = {};
    for (const [key, value] of urlParams) {
        params[key] = value;
    }
    return params;
}