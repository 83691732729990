import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from 'react';
import { useDispatch } from 'react-redux';
import { imageService } from '../../store/services';
import { cartActions } from '../../store/actions';
import { ImgWithPlaceholder, PlaceholdersLoaderGif, Button } from '..';
import { CropperEditor } from '../../components';

export function CardCart({ image, imageCartProp, title, price, count, handleAddCount, handleRemoveCount, handleUpdate, setRemoveIndexId, productNotExist, propertyNotExist, propertiesText, editorImage }) {
    const [realImage, setRealImage] = React.useState(image);
    const dispatch = useDispatch();
    const errorsLoadCount = React.useRef(0);

    const getImage = (id, fingerPrint) => {
        imageService.get(id, fingerPrint).then((res) => {
            // res is blob
            const url = URL.createObjectURL(res);
            setRealImage(url);
            dispatch(cartActions.updateCartImage(imageCartProp.id, url));
        })
    }

    const handleImageError = () => {
        if (errorsLoadCount.current < 3) {
            getImage(imageCartProp.id, imageCartProp.fingerPrint);
        }
        errorsLoadCount.current++;
    }

    const [imageEditorReady, setImageEditorReady] = React.useState(false);
    const setupCropperEditor = () => {
        const { template, image, processingType, properties } = editorImage;
        const background = "rgb(" + template.backgroundColor.replaceAll(";", ",") + ")";
        const aspectRatioContainer = template.width / template.height;
        const designObjects = template.designObjects;
        if (!imageEditorReady) {
            return <ImgWithPlaceholder src={realImage} className="img-fluid pe-none" alt="..." unselectable="on" placeholder={PlaceholdersLoaderGif.loader_1200_1200} onLoad={() => setImageEditorReady(true)} />
        }
        return <CropperEditor
            src={realImage}
            background={background}
            aspectRatio={aspectRatioContainer}
            initProp={image.editorProps}
            initStyle={image.initStyle}
            processingType={processingType}
            designObjects={designObjects}
            overloresList={template.overloresList ? template.overloresList.filter(overlores => properties?.includes(overlores.iD_PropertyValue) || !overlores.iD_PropertyValue) : []}
            enableUpdates={false}
            handleImageError={() => getImage(image.id, image.fingerPrint)}
            hideShadow={true}
        />
    }

    const showIncrementButtons = handleAddCount && handleRemoveCount && count && !productNotExist;

    return (
        <div className={`mb-3 card p-3 h-100 shadow ${productNotExist || propertyNotExist ? 'bg-warning' : 'bg-transparent'}`}>
            <h5 className="card-title">{title}</h5>
            {propertiesText && <p className="text-muted mb-0 fst-italic">Con {propertiesText}</p>}
            <div className="row g-0 align-items-center h-100">
                <div className="col-4 h-100 position-relative overflow-hidden d-flex align-items-center">
                    <div className="w-100">
                    {errorsLoadCount.current < 3 && (
                        editorImage ?
                            setupCropperEditor()
                            :
                            <ImgWithPlaceholder src={realImage} className="img-fluid pe-none" alt="..." onError={handleImageError} unselectable="on" placeholder={PlaceholdersLoaderGif.loader_1200_1200} />
                    )}
                    </div>
                </div>

                <div className="col-8 h-100">
                    <div className="card-body d-flex flex-column justify-content-between h-100">
                        <div>
                            <p className="card-text mb-1">
                                <span className="fw-bold me-1">Quantità:</span>
                                {count}
                            </p>
                            {!productNotExist && <p className="card-text mb-1">
                                <span className="fw-bold me-1">Prezzo:</span>
                                {price}
                            </p>}
                        </div>

                        <div className="d-flex justify-content-between ms-3">
                            <div className="d-flex justify-content-between">
                                {showIncrementButtons && <>
                                    <Button
                                        otherClass={"btn btn-secondary me-1"}
                                        onClick={handleRemoveCount}
                                        children={<FontAwesomeIcon icon="fa-regular fa-minus" />}
                                        disabled={count === 1}
                                    />
                                    <Button
                                        otherClass={"btn btn-secondary"}
                                        onClick={handleAddCount}
                                        children={<FontAwesomeIcon icon="fa-regular fa-plus" />}
                                    />
                                </>}
                            </div>
                            <div className="d-flex justify-content-between ms-3">
                                {handleUpdate && !productNotExist && <Button
                                    otherClass={"btn btn-primary me-1"}
                                    onClick={handleUpdate}
                                    children={<FontAwesomeIcon icon="fa-regular fa-pen" />} />
                                }
                                <Button
                                    otherClass={"btn btn-danger"}
                                    onClick={setRemoveIndexId}
                                    children={<FontAwesomeIcon icon="fa-regular fa-trash-can" />} 
                                    otherProps={{ "data-bs-toggle": "modal", "data-bs-target": "#cartModal" }}
                                    />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {propertyNotExist && <div className="text-danger mt-3 fw-bold">
                Il catalogo è cambiato e la proprietà selezionata per il prodotto non è più disponibile, modifica o elimina il prodotto per procedere con l'acquisto di altri
            </div>}
            {productNotExist && <div className="text-danger mt-3 fw-bold">
                Il catalogo è cambiato e il prodotto non è più disponibile, eliminalo per procedere con l'acquisto di altri
            </div>}
        </div>
    );
}