export const alertConstants = {
  ADD_TOAST_PRODUCT: "ALERT_ADD_TOAST_PRODUCT",
  REMOVE_TOAST_PRODUCT: "ALERT_REMOVE_TOAST_PRODUCT",
};

let timeoutSaved = null;
function addToastProduct(once = true) {
  const text = once ? "Formato immagine non valido!" : "Alcune immagini selezionate non avevano un formato valido e sono state ignorate";
  return (dispatch) => {
    dispatch({ type: alertConstants.ADD_TOAST_PRODUCT, text });
    clearTimeout(timeoutSaved);
    timeoutSaved = setTimeout(() => {
      dispatch({ type: alertConstants.REMOVE_TOAST_PRODUCT });
    }, 3000);
  };
}

function removeToastProduct() {
  return (dispatch) => {
    dispatch({ type: alertConstants.REMOVE_TOAST_PRODUCT });
  };
}

export const alertActions = {
  addToastProduct,
  removeToastProduct,
};
