export function ToastDanger({ children, warningImageFormat = false }) {

    const standardFormat = "toast d-block bg-danger w-100 shadow-none text-white"
    const styleImageFormat = "toast d-flex bg-danger w-75 shadow-none text-white position-fixed bottom-0 mb-5 translate-middle-x start-50";
    return (
        <div className={`${warningImageFormat ? styleImageFormat : standardFormat}`} role="alert" aria-live="assertive" aria-atomic="true">
            <div className="toast-body text-center p-1 w-100">
                {children}
            </div>
        </div>
    );
}
