import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PlaceholdersLoaderGif, ImgWithPlaceholder } from '../../'

export function SelectionCard({
    bottomText,
    checked,
    description,
    image,
    title,
}) {

    return (
        <div className="position-relative h-100">
            {checked && <FontAwesomeIcon icon="fa-regular fa-check-circle" className='position-absolute top-0 end-0 text-white me-n1 mt-n1 z-2 bg-primary rounded-5' size="2x" />}
            <div className={`card h-100 mb-3 rounded-5 overflow-hidden shadow min-h-120`}
            style={{ boxSizing: 'border-box' }}>
                {checked &&
                    <div className='w-100 h-100 position-absolute border border-5 border-primary rounded-5 z-1 top-0 start-0'>

                    </div>
                }

                <div className="row g-0 h-100 min-h-120">
                    <div className="col-5 position-relative overflow-hidden max-w-120">
                        <ImgWithPlaceholder src={image} className="img-fluid rounded-start position-absolute top-50 start-50 translate-middle w-100" alt="..." placeholder={PlaceholdersLoaderGif.loader_1080_1800} />
                    </div>
                    <div className="col-7 text-end lh-sm flex-fill">
                        <div className="card-body">
                            <h5 className="card-title fw-bold">{title}</h5>
                            <p className="card-text fs-6">{description}</p>
                            <p className="card-text"><small className="text-muted">{bottomText}</small></p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}