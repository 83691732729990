import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function PasswordField({
    value,
    setValue,
    placeholder = 'Password',
    className = 'border-light',
}) {
    const [showPassword, setShowPassword] = React.useState(false);

    return (
        <div className={`input-group mb-3 w-100 mt-3 rounded-5 overflow-hidden border border-1 ${className}`}>
            <input type={showPassword ? 'text' : 'password'}
                placeholder={placeholder} className={`form-control border border-0`}
                value={value} onChange={(e) => setValue(e.target.value)} />
            <span className="input-group-text bg-secondary" style={{ width: '45px' }} onClick={() => setShowPassword(!showPassword)}>{showPassword ?
                <FontAwesomeIcon icon="fa-regular fa-eye-slash" /> :
                <FontAwesomeIcon icon="fa-regular fa-eye" />}</span>
        </div>
    );
}