import { productConstants } from "../actions";

const initialState = {
  imagesList: []
};

const productData = (state = initialState, action) => {
  let draft = {
    ...state,
  };
  switch (action.type) {
    case productConstants.ADD:
      draft = {
        ...state,
        imagesList: [...state.imagesList, action.image],
      }
      break;
    case productConstants.ADD_MANY:
      draft = {
        ...state,
        imagesList: [...state.imagesList, ...action.images],
      }
      break;
    case productConstants.CLEAN:
      if (!state.lockClean)
        draft = {
          ...initialState,
        };
      break;
    case productConstants.GET:
      if (action.products)
        draft = action.products;
      break;
    case productConstants.REMOVE:
      draft.imagesList = draft.imagesList.filter((image, index) => index !== parseInt(action.index));
      break;
    case productConstants.REMOVE_PAGE_IMAGE:
      draft = {
        ...state,
        imagesList: state.imagesList.map((image, index) => {
          if (index === parseInt(action.index)) {
            return { ...image, image: null, originalImage: null }
          }
          return image;
        }),
      }
      break;
    case productConstants.UPDATE:
      draft = {
        ...state,
        imagesList: state.imagesList.map((image, index) => {
          if (index === parseInt(action.index)) {
            return { ...image, ...action.imageData }
          }
          return image;
        }),
      }
      break;
    case productConstants.ADD_EDITABLE_PROPERTY:
      draft = {
        ...state,
        editableProperty: action.propValue,
      };
      break;
    case productConstants.CLEAR_EDITABLE_PROPERTY:
      draft = {
        ...state,
        editableProperty: null,
      };
      break;
    case productConstants.RESTORE_FROM_CART:
      draft = {
        ...state,
        imagesList: action.imagesList,
      };
      break;
    case productConstants.UPDATE_IMAGE_URL:
      draft = {
        ...state,
        imagesList: state.imagesList.map((image, index) => {
          if (index === parseInt(action.index)) {
            image.originalImage = action.url;
            image.image = action.url;
          }
          return image;
        }),
      }
      break;
    case productConstants.LOCK_CLEAN:
      draft = {
        ...state,
        lockClean: true
      }
      break;
    case productConstants.UNLOCK_CLEAN:
      draft = {
        ...state,
        lockClean: false
      }
      break;
    // Reducers dedicated to native gallery
    case productConstants.REMOVE_ID:
      draft.imagesList = draft.imagesList.filter((image) => image.id !== action.id);
      break;
    case productConstants.REMOVE_IDS:
      draft.imagesList = draft.imagesList.filter((image) => !action.ids.includes(image.id));
      break;
    case productConstants.VALIDATE_IDS:
      // filter the images that are not in the ids list
      draft.imagesList = draft.imagesList.filter((image) => action.ids.includes(image.id) || image.validate)
        .map((image) => {
          if (action.ids.includes(image.id)) {
            return { ...image, validate: true }
          }
          return image;
        });
      break;
    default:
      break;
  }
  return draft;
};

export default productData;
