import React from "react";
import { useNavigate } from 'react-router-dom';
import { Page } from '../../components/page';
import { Button, PasswordField } from "../../ui";
import { getAllUrlParams } from "../../libs";
import { albumService } from "../../store/services";

export function AlbumGift() {
    const navigate = useNavigate();
    const { id, title } = getAllUrlParams();
    const [formData, setFormData] = React.useState({
        email: '',
        password: ''
    });
    const [errorCode, setErrorCode] = React.useState(null);
    const [showSuccess, setShowSuccess] = React.useState(false);

    const handleSendGift = () => {
        albumService.changeUser(id, formData.email, formData.password).then((res) => {
            console.log(res);
            if (res.status) {
                return res.json();
            }
            setShowSuccess(true);
        }).then((res) => {
            console.log(res);
            setErrorCode(res.errorCode);
        });

    };

    const goToAlbumList = () => {
        navigate('/album-list');
    }

    if(showSuccess) {
        return <Page title={title} visibleBottomNavigation={false}>
            <div className="container mt-1">
                <div className="text-success mt-2 fw-bold text-center">
                    Album inviato con successo!
                </div>
                <Button otherClass={"mt-2"} onClick={goToAlbumList}>
                    Torna ai tuoi album
                </Button>
            </div>
        </Page>
    }

    return (
        <Page title={title} visibleBottomNavigation={false}>
            <div className="container mt-1">
                <p>
                    Puoi regalare questo album ad un amico iscritto a Kangury. Se lo farai l'album verrà spostato nel suo account e non sarà più visibile nel tuo.
                </p>
                <input type="text" className="form-control mt-3 rounded-5" placeholder="Inserisci l'email del destinatario" value={formData.email} onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
                <PasswordField value={formData.password} setValue={(value) => setFormData({ ...formData, password: value })} placeholder="Inserisci la tua password per confermare" className="mt-2" />

                {errorCode && <div className="text-danger mt-2 fw-bold text-center">
                    {translateErrorCode(errorCode)}
                </div>}
                <Button otherClass={"mt-2"} onClick={handleSendGift}>
                    Invia album come regalo
                </Button>
            </div>
        </Page>
    );
}

const translateErrorCode = (errorCode) => {
    switch (errorCode) {
        case 1:
            return 'Password non corretta';
        case 2:
            return 'Email non trovata, controlla che sia corretta o che l\'utente sia registrato a Kangury';
        default:
            return 'Errore server, riprova più tardi o, se si ripete, contatta l\'assistenza';
    }
}