import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CropperEditor } from '..';
import { codeToicons, getAllUrlParams, objectsAreEqual, showResolutionAlertForSingleImage, showCropAlertForSingleImage } from '../../libs';
import { productDataActions, alertActions } from '../../store/actions';
import { LightHorizontalContainer, ButtonLink } from '../../ui';
import { imageService } from '../../store/services';

export function ListImages({ imagesLists, template, processingType, isPossibleAdd, propertiesEditable }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { subCategoryId, productId, properties, propertiesId } = getAllUrlParams();
    const [visibleImages, setVisibleImages] = React.useState(window.visibleImages || 2);
    const isLoadingRef = React.useRef(false);

    React.useEffect(() => {
        const handleScroll = (e) => {
            if (isLoadingRef.current) {
                return;
            };
            const height = e.target.scrollHeight - e.target.clientHeight;
            const scrolled = e.target.scrollTop;
            const percent = scrolled / height;
            if (percent > 0.8 && visibleImages < imagesLists.length) {
                isLoadingRef.current = true;
                setVisibleImages(prev => {
                    window.visibleImages = prev + 1;
                    return prev + 1;
                });
                setTimeout(() => {
                    isLoadingRef.current = false;
                }, 500);
            }
        }
        setTimeout(() => {
            const element = document.getElementById('page');
            element?.addEventListener('scroll', handleScroll);
            return () => {
                element?.removeEventListener('scroll', handleScroll);
            }
        }, 200);
    }, [imagesLists.length, visibleImages]);

    React.useEffect(() => {
        if ((processingType === 3 || processingType === 4) && imagesLists.length === 0) {
            // add one image with null src for any template items
            template.forEach((item, index) => {
                const isUploadedImage = item.designObjects.find(designObject => designObject?.image.type === 0);
                dispatch(productDataActions.add({ originalImage: null, editorProps: null, quantity: 1, index, needImage: isUploadedImage }));
            });
        }
    }, [dispatch, imagesLists.length, processingType, template]);

    const handleIncrementQuantity = (index) => {
        const newData = { quantity: imagesLists[index].quantity + 1 };
        dispatch(productDataActions.update(newData, index));
    }

    const handleDecrementQuantity = (index) => {
        const newData = { quantity: imagesLists[index].quantity - 1 };
        dispatch(productDataActions.update(newData, index));
    }

    const [imageDeleted, setImageDeleted] = React.useState([]);
    const handleDeleteImage = (index) => {
        if (processingType === 1 || processingType === 2) {
            setImageDeleted(prev => [...prev, imagesLists[index].originalImage]);
            setTimeout(() => {
                dispatch(productDataActions.remove(index))
                // remove image from imageDeleted
                setImageDeleted(prev => prev.filter(item => item !== imagesLists[index].originalImage));
            }, 200);
        }
        else
            dispatch(productDataActions.removePageImage(index));
    }

    const handleGoToEditor = (index) => {
        const parameters = window.location.search;
        navigate(`/editor${parameters}&imageIndex=${index}`);
    }

    const handleSaveEditorProps = (index, editorProps) => {
        const oldEditorProps = imagesLists[index].editorProps;
        if (!objectsAreEqual(oldEditorProps, editorProps))
            dispatch(productDataActions.update({ editorProps }, index));
    }

    const handleSaveInitStyle = (index, initStyle) => {
        const oldStyle = imagesLists[index].initStyle;
        if (!objectsAreEqual(oldStyle, initStyle))
            dispatch(productDataActions.update({ initStyle }, index));
    }

    const handleGoToEditableProperty = (index) => {
        navigate(`/property?subCategoryId=${subCategoryId}&productId=${productId}&properties=${properties}&propertiesId=${propertiesId}&idPropEditable=${propertiesEditable[index].id}`);
    }

    const renderCommonButtons = (index, overloresPresent) => {
        return (
            <div className={`d-flex justify-content-center position-relative z-1000 w-100"} ${overloresPresent ? "mt-4" : "mt-3"}`}>
                {(processingType === 1 || processingType === 2) &&
                    (<LightHorizontalContainer className='me-2'>
                        <ButtonLink disabled={imagesLists[index].quantity === 1}
                            onClick={() => handleDecrementQuantity(index)}>
                            <FontAwesomeIcon icon="fa-regular fa-minus" size='2x' />
                        </ButtonLink>
                        <span className='fs-3 ms-2 me-2'>
                            {imagesLists[index].quantity}
                        </span>
                        <ButtonLink disabled={!isPossibleAdd}
                            onClick={() => handleIncrementQuantity(index)}>
                            <FontAwesomeIcon icon="fa-regular fa-plus" size='2x' />
                        </ButtonLink>
                    </LightHorizontalContainer>)}
                <LightHorizontalContainer>
                    <ButtonLink onClick={() => handleGoToEditor(index)}>
                        <FontAwesomeIcon icon="fa-regular fa-pen-to-square" size='2x' />
                    </ButtonLink>
                    {
                        propertiesEditable?.map((property, index) => {
                            return <ButtonLink key={index} onClick={() => handleGoToEditableProperty(index)}>
                                <FontAwesomeIcon icon={`fa-regular ${codeToicons[property.iconCode]}`} size="2x" />
                            </ButtonLink>
                        })
                    }

                    <ButtonLink onClick={() => handleDeleteImage(index, processingType)}>
                        <FontAwesomeIcon icon="fa-regular fa-trash-can" size='2x' />
                    </ButtonLink>
                </LightHorizontalContainer>
            </div>
        );
    }

    const [hideCropper, setHideCropper] = React.useState(-1);
    const changePhoto = (e, index) => {
        const file = e.target.files[0];
        if (file.type.match(/(jpeg|jpg|tiff|tif|png)$/)) {
            const urlImage = URL.createObjectURL(file);
            const image = new Image();
            image.src = urlImage;
            image.onload = () => {
                const prop = {
                    image: urlImage,
                    originalImage: urlImage,
                    imageName: file.name,
                    imageWidth: image.width,
                    imageHeight: image.height,
                };
                dispatch(productDataActions.update(prop, index));
            }
            setHideCropper(index);
            setTimeout(() => {
                setHideCropper(-1);
            }, 200);
        }
        else {
            dispatch(alertActions.addToastProduct())
        }
    }

    const handleImageError = (imagesLists, index) => {
        const { id, fingerPrint } = imagesLists[index];
        if (id && fingerPrint)
            imageService.get(id, fingerPrint).then((res) => {
                const url = URL.createObjectURL(res);
                dispatch(productDataActions.updateImageUrl(index, url));
            })
    }

    const handleOnLoadImage = (imagesLists, index) => {
        const { id, native } = imagesLists[index];
        if (native) {
            const data = {
                type: 'getImage',
                index,
                id,
            }
            window.ReactNativeWebView.postMessage(JSON.stringify(data));
            return true;
        }
        return false;
    }
    const renderImage = (index) => {
        const t = template.length === 1 ? template[0] : template[index];
        const background = "rgb(" + t.backgroundColor.replaceAll(";", ",") + ")";
        const aspectRatioContainer = t.width / t.height;
        const designObjects = t.designObjects;
        return (
            <div className={`w-100 mb-5 transition-base ${processingType === 4 ? 'pb-3' : ''} ${aspectRatioContainer < 1 || processingType === 1? 'max-w-500' : ''}`} 
                        key={`${index}_${imagesLists[index].originalImage}`}
                style={imageDeleted.includes(imagesLists[index].originalImage) ? { marginLeft: '300%' } : {}}>
                <div className={`w-100 object-fit-cover position-relative`} key={index} onClick={() => (imagesLists[index].image || imagesLists[index].originalImage) && handleGoToEditor(index)}>
                    {index !== hideCropper && <CropperEditor
                        src={imagesLists[index].image || imagesLists[index].originalImage}
                        background={background}
                        aspectRatio={aspectRatioContainer}
                        saveProps={(editorProps) => handleSaveEditorProps(index, editorProps)}
                        saveInitStyle={(initStyle) => handleSaveInitStyle(index, initStyle)}
                        initProp={imagesLists[index].editorProps}
                        initStyle={imagesLists[index].initStyle}
                        processingType={processingType}
                        designObjects={designObjects}
                        changePhoto={(e) => changePhoto(e, index)}
                        overloresList={t.overloresList ? t.overloresList.filter(overlores => properties?.includes(overlores.iD_PropertyValue) || !overlores.iD_PropertyValue) : []}
                        enableUpdates={false}
                        handleImageError={() => handleImageError(imagesLists, index)}
                        handleOnLoadImage={() => handleOnLoadImage(imagesLists, index)}
                        index={index}
                        notWasInEditor={!imagesLists[index].wasInEditor}
                        showResolutionAlert={showResolutionAlertForSingleImage(imagesLists[index], t)}
                        showScissors={showCropAlertForSingleImage(imagesLists[index], t, processingType) && !imagesLists[index].wasInEditor}
                    />}
                </div>
                {(imagesLists[index].image || imagesLists[index].originalImage) && renderCommonButtons(index, t.overloresList?.length > 0)}
            </div>
        )
    }

    // show first 10 images
    const imagesListsVisualized = [3, 4].includes(processingType) ? imagesLists : imagesLists.slice(0, visibleImages);

    const [showList, setShowList] = React.useState(true);
    const timerRenderRef = React.useRef(null);
    React.useEffect(() => {
        if (imagesListsVisualized.length < 10 && imagesListsVisualized.length > 0) {
            const { image, originalImage } = imagesListsVisualized[0];
            if (image || originalImage) {
                if (!timerRenderRef.current) {
                    setShowList(false);
                    timerRenderRef.current = setTimeout(() => {
                        setShowList(true);
                    }, 400);
                }
            }
        }
    }, [imagesListsVisualized]);

    if (!showList)
        return null;

    return (
        <React.Fragment>
            {imagesListsVisualized.map((item, index) => renderImage(index))}
        </React.Fragment>
    );
}