import React from 'react';
import { Page } from '../../components';
import { userService } from '../../store/services';
import { cartActions, userActions } from '../../store/actions';
import { Button, ToastDanger, PasswordField } from '../../ui';

export function ProfileRemove() {
    const [askPassword, setAskPassword] = React.useState(true);
    const [password, setPassword] = React.useState('');
    const [showToast, setShowToast] = React.useState(false);

    const [userRemoved, setUserRemoved] = React.useState(false);

    const handleLogout = React.useCallback(() => {
        userService.logout().then((data) => {
            localStorage.removeItem('token');
            localStorage.removeItem('data');
            cartActions.removeAll();
            userActions.removeAllAddresses();
            setUserRemoved(true);

        });
    }, []);

    const handleDeleteUser = () => {
        if (password) {
            userService.remove(password).then((data) => {
                if (data.status === 204) {
                    handleLogout();
                } 
                return data.json();
            }).then((data) => {
                if(data.errorCode) {
                    setShowToast(true);
                }
            });
        }
    }

    if (userRemoved) {
        return (<Page title={'Eliminazione Utente'}>
            <div className='container p-3 h-100 d-flex flex-column justify-content-center align-items-center'>
                <div className='text-center fs-2 fw-bold'>
                    Utente Eliminato con Successo
                </div>
            </div>
        </Page>)
    }

    return (
        <Page title={'Eliminazione Utente'}>
            <div className='p-3'>
                {showToast && <ToastDanger>
                    Password errata
                </ToastDanger>}
                {askPassword && <PasswordField value={password} setValue={(e) => setPassword(e)} />}
                <Button otherClass="btn-danger text-white mt-2"
                    onClick={() => handleDeleteUser()}>
                    Elimina utente
                </Button>
            </div>
        </Page>
    );
}