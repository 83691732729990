import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { CardSimple } from '../../ui';
import "./style.scss";
import logo from '../../ui/assets/logo.png';
import lp_gif from './lp_gif.gif';
import lp_image from './lp_image.jpg';
import { Page } from '../../components';

export function LandingPage() {
    const navigate = useNavigate();
    const howWorkSectionRef = React.useRef(null);

    const arrowClick = (e) => {
        window.history.back();
    }

    const homeClick = (e) => {
        navigate('/');
    }

    return (<Page visibleBottomNavigation={false}>
        <div>
            <div className='landing-page w-100 d-flex justify-content-center align-items-center text-primary border-bottom border-2 bg-tertiary '>
                <button className='btn btn-link' data-bs-toggle="modal" onClick={arrowClick}>
                    <FontAwesomeIcon icon="fa-regular fa-arrow-left" className="fs-4" size='2x' />
                </button>
                <div className='container d-flex justify-content-center align-items-center'>
                    <img src={logo} alt="logo" className={`landing-page__logo pe-none`} unselectable="on" />
                    <span className='fw-bold fs-1'>
                        Kangury
                    </span>
                </div>
                <button className='btn btn-link'>
                    <FontAwesomeIcon icon="fa-regular fa-shop" className="fs-4 text-primary" onClick={homeClick} size='2x' />
                </button>
            </div>
            <Section>
                <div className="row p-2 text-tertiary align-items-center">
                    <div className="col-12 col-sm-6">
                        <h1>Foto che sbloccano mondi di ricordi</h1>
                        <p className='fs-5 mb-4 mt-4'>
                            Ricevi a casa le tue foto stampate potenziate dalla nostra tecnologia invisibile
                            Basterà avvicinare il tuo smartphone per accedere ai tuoi ricordi digitali e rivivere i momenti più preziosi
                        </p>
                        <div className='row fw-bold mb-4 mt-4'>
                            <div className='col-12 col-md-5 align-items-center d-flex mb-2 ms-1'>
                                <FontAwesomeIcon icon="fa-regular fa-camera" className='me-2' size='2x' />
                                <div>
                                    Bellezza della fotografia
                                </div>

                            </div>
                            <div className='col-12 col-md-5 align-items-center d-flex mb-2'>
                                <FontAwesomeIcon icon="fa-regular fa-laptop" className='me-2' size='2x' />
                                <div>
                                    Potenza della tecnologia
                                </div>

                            </div>
                            <button type="button" className='btn rounded-5 w-auto fs-6 btn-tertiary text-primary fw-bold mt-4 ps-4 pe-4'
                                onClick={() => {
                                    // scroll to the next section
                                    howWorkSectionRef.current.scrollIntoView({ behavior: 'smooth' });
                                }}>
                                Come funziona
                            </button>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 d-flex">
                        <img src={lp_gif} alt="gif" className={`landing-page__image m-auto shadow`} unselectable="on" />
                    </div>
                </div>
            </Section>
            <div className='horizontal-line' />
            <Section bgWhite={true}>
                <h2 className='fw-bold w-100 text-center mb-5 text-tertiary' ref={howWorkSectionRef}>
                    Più facile di così non si può!</h2>
                <div className='row'>
                    <div className='col-12 col-md-6 col-lg-4 mb-4 ps-4 pe-4'>
                        <Card
                            title='Scegli'
                            text1='Scegli la foto, il formato ed eventuale cornice, te li consegneremo subito a casa'
                            icon='fa-regular fa-cloud-arrow-up'
                        />

                    </div>
                    <div className='col-12 col-md-6 col-lg-4 mb-4 ps-4 pe-4'>
                        <Card
                            title='Personalizza'
                            text1='Ogni foto offre una memoria cloud dedicata, per sempre, per custodire i tuoi ricordi più belli'
                            icon='fa-regular fa-paint-brush'
                        />
                    </div>
                    <div className='col-12 col-lg-4 mb-4 ps-4 pe-4'>
                        <Card
                            title='Goditela'
                            text1={'Dai vita ai ricordi sfiorando le foto con lo smartphone, grazie alla nostra innovativa tecnologia'}
                            icon='fa-regular fa-face-smile-hearts'
                        />
                    </div>
                </div>
                <div className='mt-4'>
                    <div className="landing-page__video-container">
                        <iframe className="landing-page__video" allowFullScreen="" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" title="PhotoK By Kangury" src="https://www.youtube.com/embed/X8F34Fw-c0E?autoplay=0&amp;loop=0&amp;rel=0&amp;enablejsapi=1&amp;origin=https%3A%2F%2Flaunch.kangury.it&amp;widgetid=1" id="widget2" data-gtm-yt-inspected-7="true" name="fitvid0">
                        </iframe>
                    </div>
                </div>
            </Section>
            <div className='horizontal-line' />
            <Section>
                <h2 className='fw-bold w-100 text-center mb-5 text-tertiary'>
                    Perchè scegliere Kangury
                </h2>
                <div className='row'>
                    <div className='col-12 col-md-6 col-lg-4 mb-4 ps-4 pe-4'>
                        <Card
                            title='Proteggi i tuoi ricordi'
                            text1='Addio dispositivo? Addio ricordi!'
                            text2='Con Kangury i tuoi file sono al sicuro, per sempre.'
                            icon='fa-regular fa-shield'
                        />

                    </div>
                    <div className='col-12 col-md-6 col-lg-4 mb-4 ps-4 pe-4'>
                        <Card
                            title='Ritrova le tue foto'
                            text1='Non lasciare che i tuoi ricordi si perdano nel cloud.'
                            text2='Con Kangury, avrai tutto a portata di mano.'
                            icon='fa-regular fa-magnifying-glass'
                        />
                    </div>
                    <div className='col-12 col-lg-4 mb-4 ps-4 pe-4 ps-4 pe-4'>
                        <Card
                            title='Risparmia'
                            text1='Stufo di avere mille abbonamenti?'
                            text2='Con Kangury paghi una volta, per sempre!'
                            icon='fa-regular fa-piggy-bank'
                        />
                    </div>
                </div>
            </Section>
            <Section bgWhite={true}>
                <div className='row d-flex justify-content-center align-items-center '>
                    <div className='col-12 col-md-6 mb-3'>
                        <img src={lp_image} className={`w-100 shadow`} unselectable="on" alt="set of images" />
                    </div>
                    <div className='col-12 col-md-6'>
                        <h2 className='fw-bold w-100 mb-3 text-tertiary'>
                            Perchè lo facciamo?
                        </h2>
                        <p>
                            Pensiamo che i <strong>momenti speciali</strong> della tua vita debbano essere celebrati, <strong>esposti</strong> e condivisi, <strong>non dimenticati</strong> nei meandri della galleria del tuo telefono.
                        </p>
                        <p>
                            Non dovrai più scegliere fra la bellezza della <strong>fotografia</strong> e la potenza della <strong>tecnologia</strong>: goditi entrambi, con <strong>Kangury</strong>.
                        </p>
                    </div>
                </div>
            </Section>

            <div className={`w-100 p-4 d-flex justify-content-center align-items-center bg-tertiary bg-opacity-50 pt-5 pb-5`}>
                <div className="container">
                    <h2 className='fw-bold w-100 text-center mb-3 text-tertiary'>
                        Seguici sui nostri social per rimanere aggiornato!
                    </h2>
                    <div className='d-flex justify-content-center align-items-center gap-4'>
                        <div className='d-flex justify-content-center align-items-center'>
                            <a href="https://www.instagram.com/kanguryapp/" target="_blank" className='me-3' rel="noreferrer">
                                <FontAwesomeIcon icon="fa-brands fa-instagram" size='3x' className='text-primary' />
                            </a>
                        </div>
                        <div className='d-flex justify-content-center align-items-center'>
                            <a href="https://www.facebook.com/profile.php?id=61563829343302" target="_blank" className='me-3' rel="noreferrer">
                                <FontAwesomeIcon icon="fa-brands fa-facebook" size='3x' className='text-primary' />
                            </a>
                        </div>
                    </div>
                    <h5 className='fw-bold w-100 mt-4 text-tertiary'>
                        Per informazioni contattaci:
                    </h5>
                    <div className='fs-5'>
                        info@kangury.it
                    </div>
                </div>
            </div>

            <div className='landing-page w-100 d-flex justify-content-center align-items-center text-primary border-bottom border-2 bg-tertiary '>
                <img src={logo} alt="logo" className={`landing-page__logo landing-page__logo--footer pe-none`} unselectable="on" />
                Kangury | Copyright © 2024
            </div>

        </div>
    </Page>);
}

const Card = ({ title, text1, text2, otherClass, icon }) => {
    return (
        <CardSimple
            otherClass={otherClass ?? "bg-tertiary bg-opacity-75 position-relative h-100 shadow"}
            children={<div className='text-center'>
                <div className='fs-5 fw-bold text-primary'>{title}</div>
                <div className='fw-bold text-white'>
                    {text1}
                </div>
                <div className='fw-bold text-white'>
                    {text2}
                </div>
                <FontAwesomeIcon icon={icon} size='2x' className='text-primary mt-4 ms-4 position-absolute top-0 start-0 translate-middle' />
            </div>} />
    );
}

const Section = ({ children, bgWhite }) => {
    return (
        <div className={`w-100 p-4 d-flex justify-content-center align-items-center ${bgWhite ? 'bg-white' : 'bg-tertiary bg-opacity-25'} pt-5 pb-5`}>
            <div className="container">
                {children}
            </div>
        </div>
    );
}