import React from 'react';
import { Page } from '../../components';
import { CardOrders, Modal } from '../../ui';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { orderActions } from '../../store/actions';
import { useDispatch, useSelector } from 'react-redux';

// create dictionary of filters options
const FILTERS = {
    all: 'Tutti',
    months6: 'Ultimi 6 mesi',
    year: 'Ultimo anno',
    years2: 'Ultimi 2 anni',
}

export function Orders() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const orders = useSelector(state => state.orderData.orders);
    const [startDate, setStartDate] = React.useState(FILTERS.months6);
    const [removeIndex, setRemoveIndex] = React.useState(null);

    if (!localStorage.getItem('token'))
        navigate('/');

    React.useEffect(() => {
        if (startDate === FILTERS.all) {
            dispatch(orderActions.get())
        } else {
            let _startDate = new Date();
            // add 1 day to endDate to include the last day
            let _endDate = new Date(_startDate.getFullYear(), _startDate.getMonth() + 1, _startDate.getDate());
            if (startDate === FILTERS.year) {
                _startDate = new Date(_startDate.getFullYear(), _startDate.getMonth() - 12, _startDate.getDate());

            } else if (startDate === FILTERS.years2) {
                _startDate = new Date(_startDate.getFullYear(), _startDate.getMonth() - 24, _startDate.getDate());
            } else if (startDate === FILTERS.months6) {
                _startDate = new Date(_startDate.getFullYear(), _startDate.getMonth() - 6, _startDate.getDate());
            } else {
                _startDate = new Date(startDate, 0, 1);
                _endDate = new Date(startDate, 11, 31);
            }
            _startDate = _startDate.toISOString().split('T')[0];
            _endDate = _endDate.toISOString().split('T')[0];
            dispatch(orderActions.get({ startDate: _startDate, endDate: _endDate }));
        }
    }, [dispatch, startDate]);

    // list of last 15 years starting from current year
    const years = React.useMemo(() => {
        const currentYear = new Date().getFullYear();
        const options = Array.from({ length: 13 }, (v, k) => currentYear - k - 2);
        // add to options the filters options
        options.unshift(FILTERS.years2);
        options.unshift(FILTERS.year);
        options.push(FILTERS.all)
        return options;
    }, []);

    const handleRemove = (index) => {
        const order = orders[index];
        dispatch(orderActions.remove(order.id))
    }

    const handlePay = (index) => {
        localStorage.setItem('previousPage', '/orders');
        navigate(`/pay?orderId=${orders[index].id}`);
    }

    const goToDetails = (order) => {
        const trackingUrl = order.shippingTrackingUrl;
        if (window.ReactNativeWebView) {
            const message = {
                type: 'openUrlInBrowser',
                url: trackingUrl
            }
            window.ReactNativeWebView.postMessage(JSON.stringify(message));
        } else {
            window.open(trackingUrl, '_blank');
        }
    }

    return (
        <Page title="Ordini"
            visibleBottomNavigation={false}
            customTopSection={
                <div className="flex w-100 p-2 align-items-center justify-content-between">
                    <div className="form-floating mb-2">
                        <select className="form-select" id={`dateFilter`} aria-label={"Date filter"} onChange={(e) => setStartDate(e.target.value)}>
                            <option value={FILTERS.months6}>
                                {FILTERS.months6}
                            </option>
                            {years.map((year) => (
                                <option key={year} value={year}>{year}</option>
                            ))}
                        </select>
                        <label htmlFor="dateFilter">
                            <FontAwesomeIcon icon="fa-regular fa-filter" />
                            <span className='fw-bold ms-2'>Filtra per:</span>
                        </label>
                    </div>
                </div>
            }>
            {orders?.length > 0 && <div className="container pt-3">
                {orders.map((order, index) => (
                    order.status !== 0 && order.status !== 1 && 
                    <CardOrders 
                        key={order.id}
                        orderNumber={order.idDigitapix}
                        status={order.status}
                        date={order.createdAt}
                        price={order.itemsTotal + order.shippingCost - order.itemsDiscount}
                        setRemoveIndex={() => setRemoveIndex(index)}
                        payNow={() => handlePay(index)}
                        goToDetails={order.shippingTrackingUrl ? () => goToDetails(order) : null}
                    />
                ))}
            </div>}
            <Modal
                title="Conferma cancellazione ordine"
                text="Una volta cancellato l'ordine non sarà più disponibile. Vuoi procedere lo stesso?"
                primaryText="Cancella Ordine"
                secondaryText="Annulla"
                handleConfirm={() => handleRemove(removeIndex)}
                id="orderModal"
            />
        </Page>
    );
}