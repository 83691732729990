import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BottomButton, ListTemplatesImage, Page } from '../../components';
import { priceModifiers, findMinPrice, getAllUrlParams, getModifierByProperties } from '../../libs';
import { productDataActions } from '../../store/actions';

export function Template() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { subCategoryDict } = useSelector(state => state.mainData);
    const { subCategoryId, productId, properties, propertiesId } = getAllUrlParams();
    const product = subCategoryDict && subCategoryDict[subCategoryId].subProductDict[productId];

    const [indexTemplate, setIndexTemplate] = React.useState(0);

    React.useEffect(() => {
        dispatch(productDataActions.clean());
    }, [dispatch]);

    const modifiers = React.useMemo(() => {
        return getModifierByProperties(properties?.split(";") || [], product);
    }, [product, properties]);

    const handleButtonClick = () => {
        let prop = properties ? `&properties=${properties}` : "";
        prop = propertiesId ? `${prop}&propertiesId=${propertiesId}` : prop;
        navigate(`/warn-exit-product?subCategoryId=${subCategoryId}&productId=${productId}&templateId=${product.templates[indexTemplate].id}${prop}`);
    }

    const bottomPrice = React.useMemo(() => {
        if (!product) return null;
        let price = parseFloat(findMinPrice([product], -1));
        if (modifiers) {
            price = price + priceModifiers(price, modifiers);
        }
        return price.toFixed(2).toString().replace(".", ",") + " €";
    }, [modifiers, product]);

    if (!product)
        return <Page loading={true} />

    return (
        <Page
            title="Scegli Modello"
            customBottomSection={
                <BottomButton content="Prosegui" onClick={handleButtonClick} contentUp={`Prezzo: da ${bottomPrice}`} />}>
            <ListTemplatesImage templates={product?.templates} indexTemplate={indexTemplate} setIndexTemplate={setIndexTemplate} />
        </Page>
    );
}