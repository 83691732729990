import React from 'react';
import { Page } from '../../components';
import { nfcService } from '../../store/services/nfc.service';
import { Button } from '../../ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function AdminPanel() {
    const [nfcId, setNFCId] = React.useState(null)
    const [urlWritten, setUrlWritten] = React.useState(null)
    const [toggleUrl, setToggleUrl] = React.useState(false);
    const [customUrl, setCustomUrl] = React.useState(null);

    const handleGetNFCId = () => {
        nfcService.get().then((data) => {
            setNFCId(data.id);
            setUrlWritten()
        })
    }

    const handleWriteNFCId = () => {
        const url = `${process.env.REACT_APP_PUBLIC_URL}${toggleUrl ? customUrl : `smart-connect?id=${nfcId}`}`;
        // write to NFC tag
        if ("NDEFReader" in window) {
            const ndef = new window.NDEFReader();
            ndef.write(url).then(() => {
                setUrlWritten(url);
                setNFCId(null);
                window.alert("Write completed.");
            }).catch(() => {
                window.alert("Write failed.");
            });
        } else {
            window.alert('not supported')
        }
    }

    const copy = () => {
        navigator.clipboard.writeText(nfcId)
    }

    return (
        <Page visibleBottomNavigation={false}
            title={"Pannello Amministratore"}>

            <div className='p-2'>

                <Button onClick={handleGetNFCId} otherClass={"btn-primary mt-3"} disabled={nfcId}>
                    Ottieni ID NFC
                </Button>
                <p className="fw-bold mt-3" onClick={copy}>
                    ID creato: {nfcId}
                </p>

                <div className="d-flex justify-content-between align-items-center form-check form-switch p-1">
                    <div>
                        {toggleUrl
                            ? <span>Scrivere l'url personalizzato</span>
                            : <span>Scrivere l'url ufficiale</span>}
                    </div>
                    <div>
                        <input className="form-check-input cursor-pointer" type="checkbox" id="flexSwitchCheckDefault" onClick={() => setToggleUrl(!toggleUrl)} value={toggleUrl} />
                    </div>
                </div>
                <input value={customUrl} onChange={(e) => setCustomUrl(e.target.value)} className="form-control mt-3" placeholder={toggleUrl ? "Inserisci l'url personalizzato" : process.env.REACT_APP_PUBLIC_URL} disabled={!toggleUrl} />
                <Button onClick={handleWriteNFCId} otherClass={"btn-primary mt-3"} disabled={!nfcId}>
                    Scrivi ID NFC
                </Button>

                <p className="fw-bold mt-3">
                    URL scrtto: {urlWritten}
                </p>
            </div>
        </Page>
    );
}