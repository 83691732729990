import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Page } from '../../components';
import { userService } from '../../store/services';
import { Button, ToastDanger } from '../../ui';

const ERRORS = {
    email: "Email non valida",
    generic: "Reset password fallito, riprova più tardi o contatta l'assistenza",
    11: "Email o Username non esistente",
}

export function ForgottenPassword() {
    const navigate = useNavigate();
    const [showToast, setShowToast] = React.useState(false);
    const [showFinalMessage, setShowFinalMessage] = React.useState(false);
    const [email, setEmail] = React.useState("");

    const handleGoOn = () => {
        if (!email) {
            return;
        }
        userService.forgottenPassword(email)
            .then(data => {
                if (data.status === 202)
                    setShowFinalMessage(true);
                else {
                    return data.json();
                }
            })
            .then(data => {
                if (data.code)
                    setShowToast(ERRORS[data.code]);
                else
                    setShowToast(ERRORS.generic);
            })
            .catch(err => {
                setShowToast(ERRORS.generic);
            })
    }

    const goToAssistancePage = () => {
        navigate(`/assistance`);
    }

    return (
        <Page visibleBottomNavigation={false}
            title={"Recupera Password"}>
            {!showFinalMessage ?
                <div className='container p-3'>
                    {showToast && <ToastDanger>
                            {showToast}
                            </ToastDanger>}
                    <input type="text" placeholder="Email"
                        className="w-100 form-control rounded-5 mt-3"
                        value={email} onChange={(e) => setEmail(e.target.value)} />
                    <Button onClick={handleGoOn} otherClass={"mt-3 p-1"} disabled={email.length === 0}>
                        Prosegui
                    </Button>
                </div>
                :
                <div className='container p-3 h-100 d-flex flex-column justify-content-center align-items-center'>
                    <div className='text-center fs-2 fw-bold'>
                        Operazione quasi completata
                    </div>
                    <div className='text-center fs-4'>
                        Per concludere la procedura segui le indicazioni che ti abbiamo inviato per email
                    </div>
                    <div className='text-center fs-5 mt-4'>
                        Non hai ricevuto nulla? Prova a controllare tra la posta indesiderata,
                        oppure scrivi al nostro <span className='text-decoration-underline' onClick={goToAssistancePage}>servizio clienti</span>.
                    </div>
                </div>}
        </Page>
    );
}