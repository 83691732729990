import { alertConstants } from "../actions";

const initialState = {
  toastText: "",
};

const alertData = (state = initialState, action) => {
  let draft = {
    ...state,
  };
  switch (action.type) {
    case alertConstants.ADD_TOAST_PRODUCT:
      draft.toastText = action.text;
      break;
    case alertConstants.REMOVE_TOAST_PRODUCT:
      draft.toastText = "";
      break;
    default:
      break;
  }
  return draft;
}

export default alertData;
