/**
 * Calculate the dimension of the image in percentage of the container because locally in the
 * app for dimension I have 100 and auto for width and height depending on the image ratio,
 * here I need to find the dimension setting as auto in percentage of the container\
 * xIncr and yIncr are the increment rate to apply to the image position in similar way to width and height
 * @param {*} image image saved in product.imagesList
 * @param {*} template template saved in product.template
 * @returns {width, height, xIncr, yIncr} new dimension and position increment to visualize the image in the server equal to the one in the app
 */
export const calculateDimPercentage = (image, template) => {
    let width = 100, height = 100, incrementRate;
    let indexImage = template.designObjects.findIndex(object => object.image.type === 0);
    if (indexImage !== -1) {
        const containerDim = {
            width: template.designObjects[indexImage].width * template.width / 100,
            height: template.designObjects[indexImage].height * template.height / 100,
        }
        if (image.initStyle?.width === '100%') {
            incrementRate = (containerDim.width / image.imageWidth * image.imageHeight) / containerDim.height;
            height = incrementRate * 100;

        } else {
            incrementRate = (containerDim.height / image.imageHeight * image.imageWidth) / containerDim.width;
            width = incrementRate * 100;
        }
    }
    return {
        width, height,
        xIncr: width > 100 ? incrementRate : 1,
        yIncr: height > 100 ? incrementRate : 1
    };
}

export const checkRatio = (imageAspectRatio, imgContainerAspectRatio, adjustment) => {
    const isHorizontalContainer = imgContainerAspectRatio >= 1;
    const isImageMoreHigh = imageAspectRatio < imgContainerAspectRatio;
    const isHorizontalImage = imageAspectRatio >= 1;
    if (adjustment === 1) {
        /** CROP
         * Adapt image zoom in container, if necessary, to remove the empty spaces around the image
         */
        if (isHorizontalContainer) {
            if (isHorizontalImage) {
                if (isImageMoreHigh) {
                    return {
                        width: "100%",
                        height: "auto",
                    }
                } else {
                    return {
                        width: "auto",
                        height: "100%",
                    }
                }
            } else {
                if (isImageMoreHigh) {
                    return {
                        width: "100%",
                        height: "auto",
                    }
                } else {
                    return {
                        width: "auto",
                        height: "100%",
                    }
                }
            }
        } else {
            if (isHorizontalImage) {
                if (isImageMoreHigh) {
                    return {
                        width: "100%",
                        height: "auto",
                    }
                } else {
                    return {
                        width: "auto",
                        height: "100%",
                    }
                }
            } else {
                if (isImageMoreHigh) {
                    return {
                        width: "100%",
                        height: "auto",
                    }
                } else {
                    return {
                        width: "auto",
                        height: "100%",
                    }
                }
            }
        }
    }
    if (adjustment === 2) {
        /**
         * FIT
         * Adapt image zoom in container, if necessary, to fill the container but 
         * without cutting the image, leaving empty spaces in one side of image if there is
         */
        if (isImageMoreHigh) {
            // image more high than container
            return {
                width: "auto",
                height: "100%",
            }
        }
        else {
            // image more wide than container
            return {
                width: "100%",
                height: "auto",
            }
        }
    }
}

export const initStyle = ({ initStyle = {}, template, processingType, imgHeight, imgWidth }) => {
    const overloresList = template.overloresList;
    const safeAreaWidth = overloresList?.length > 0
        ? 100 / overloresList[0].safeAreaWidth * 100
        : 75
    const designObjects = template.designObjects;
    const aspectRatio = template.width / template.height;
    let phoneAspectRatio = window.innerHeight / window.innerWidth;
    const variableWidth = aspectRatio < phoneAspectRatio ? safeAreaWidth : safeAreaWidth - 25 * (aspectRatio - phoneAspectRatio);
    let _style = {
        ...initStyle,
        aspectRatio: aspectRatio,
        containerWidth: `${variableWidth}%`,
        x: `50%`,
        y: `50%`,
    }
    const designObjectImage = designObjects.find(item => item.image?.type === 0);
    const imageSpaceAspectRatio = designObjectImage ? designObjectImage.width / designObjectImage.height : 1;
    let realAspectRatio = aspectRatio;
    if (processingType === 1) {
        if (imgWidth >= imgHeight || realAspectRatio === 1)
            _style = {
                ..._style,
                aspectRatio: aspectRatio,
                containerWidth: "100%",
            }
        else {
            _style = {
                ..._style,
                aspectRatio: 1 / aspectRatio,
            }
            realAspectRatio = 1 / aspectRatio;
        }
    }
    const imageAspectRatio = imgWidth / imgHeight;
    return {
        ..._style,
        ...checkRatio(imageAspectRatio, realAspectRatio * imageSpaceAspectRatio, designObjectImage?.adjustment),
    }
}