import React, { useState } from 'react';

export function ImgWithPlaceholder (props) {
    const [currentImage, setCurrentImage] = useState(props.placeholder);

    const handleOnLoad = () => {
        setCurrentImage(props.src);
        props.onLoad && props.onLoad();
    }

    React.useEffect(() => {
        setCurrentImage(prev => {
            if (prev !== props.placeholder) {
                return props.src;
            }
            return prev;
        });
    }, [props.placeholder, props.src]);

    return (
        <img {...props} src={currentImage} alt={props.alt} onLoad={handleOnLoad} />
    );
}