import React from 'react';
import { ListSelectionsQuantity } from '../../';
import { findMinPrice } from '../../../libs';
import { SelectionQuantity } from '../../../ui';

export function SelectionFormatPriceItem({ currentSelection, indexSelectionValue, setIndexSelectionValue, products, validSelectionValues = false }) {

    const product = React.useMemo(() => {
        /**
         * Find the product related to the selection values selected by the user in order
         * to display the prices of the product
         */
        const product = Object.values(products).find(p => {
            return p.selectionValues.includes(currentSelection.values[indexSelectionValue].id)
        });
        return product;
    }, [products, currentSelection.values, indexSelectionValue]);

    const renderSelectionsList = () => {
        return currentSelection.values.map((selection, index) => {
            if (validSelectionValues?.has(selection.id.toString()) || validSelectionValues === false)
                return (<div onClick={() => setIndexSelectionValue(index)} key={index}>
                    <SelectionQuantity title={selection.value} checked={indexSelectionValue === index} rows={product.kanguryPrices} minPrice={indexSelectionValue === index ? "" : findMinPrice({products, selectionValueId: selection.id}).replace(".", ",")} />
                </div>)
            return null;
        });
    };

    const urlImage = currentSelection.values[indexSelectionValue]?.images?.[0]?.url || '';

    return (
        <ListSelectionsQuantity list={renderSelectionsList()} image={urlImage} />
    );
}