import { openDB } from 'idb';

const DBTABLE = "data";
const DBNAME = 'kangury-db';
const VERSION = 15;

const indexedDBNotPresent = !window.indexedDB;

const dbPromise = !indexedDBNotPresent ? openDB(DBNAME, VERSION, {
    upgrade(db, oldVersion, newVersion, transaction) {
        if (oldVersion !== newVersion && oldVersion !== 0) {
            console.log("UPGRADE DB VERSION")
            // Clean DB when version change
            db.deleteObjectStore(DBTABLE)
            db.createObjectStore(DBTABLE);
        } else {
            db.createObjectStore(DBTABLE);
        }
    },
    blocked() {
        //console.log("BLOCKED")
    },
    blocking() {
        //console.log("BLOCKING")
    },
    terminated() {
        //console.log("TERMINATED")
    },
}) : null;

/**
 * Function called to generate a fake promise in case of old browser
 * that not support indexedDB
 */
function fakePromise() {
    return new Promise((resolve, reject) => {
        reject('fake');
    });
}

const get = async function (key) {
    if (!indexedDBNotPresent)
        return (await dbPromise).get(DBTABLE, key);
    return fakePromise();
};
const set = async function (key, val) {
    if (!indexedDBNotPresent)
        return (await dbPromise).put(DBTABLE, val, key);
    return fakePromise();
};
const del = async function (key) {
    if (!indexedDBNotPresent)
        return (await dbPromise).delete(DBTABLE, key);
    return fakePromise();
};
const clear = async function () {
    if (!indexedDBNotPresent)
        return (await dbPromise).clear(DBTABLE);
    return fakePromise();
};
const keys = async function () {
    if (!indexedDBNotPresent)
        return (await dbPromise).getAllKeys(DBTABLE);
    return fakePromise();
};

export const localDB = {
    get,
    set,
    del,
    clear,
    keys
}