import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export function CardDiscount({ title, code, shareAndCopy = true, applyCode, dateLimit, minItemTotal }) {
    const [copied, setCopied] = React.useState(false)

    const copy = () => {
        setCopied(true)
        setTimeout(() => {
            setCopied(false)
        }, 1500)
        navigator.clipboard.writeText(code)
    }

    const _applyCode = () => {
        applyCode(code)
    }

    // 2024-10-02T23:59:59 => 02/10/2024
    const dateLimitFormatted = new Date(dateLimit);
    const dateLimitFormattedString = `${dateLimitFormatted.getDate()}/${dateLimitFormatted.getMonth() + 1}/${dateLimitFormatted.getFullYear()}`

    return (
        <div className={`container p-3 ${shareAndCopy ? '' : 'ps-0 pe-0'}`}>
            <div className="card rounded-4 shadow">
                <div className="card-header">
                    {title}
                </div>
                <div className="card-body">
                    <p className="card-text fw-bold m-0 fs-5"> {code} </p>
                    <p className="card-text  m-0"> Valido fino al {dateLimitFormattedString} </p>
                    {minItemTotal && <p className="card-text  m-0"> Spesa minima per usarlo: {minItemTotal} € </p>}
                    <div className="d-flex justify-content-end text-primary">
                        {shareAndCopy
                            ? <button className="btn btn-link p-0 position-relative" onClick={copy}>
                                {copied && <div className="position-absolute bottom-100 start-50 bg-primary text-white rounded-5 p-1 mb-1 translate-middle-x">
                                    Copiato
                                </div>
                                }
                                <FontAwesomeIcon icon="fa-regular fa-copy" className='fs-2' />
                            </button>
                            : <button className="btn btn-primary position-relative rounded-5" onClick={_applyCode}>
                                Applica
                            </button>}
                    </div>

                </div>
            </div>
        </div>
    );
}