import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { productDataActions } from '../../store/actions';
import { FixedTop, Modal } from '../../ui';

export function TopNavigation({ title, simpleTitle }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { imagesList } = useSelector(state => state.productData);
    const atLeastOneImage = imagesList.find(image => image.image || image.originalImage);

    const checkIfGoBack = React.useCallback(() => {
        return (window.location.pathname.includes('/product') && atLeastOneImage) || window.location.pathname.includes('/editor') || (window.location.pathname.includes('/property') && window.location.search.includes('idPropEditable'));
    }, [atLeastOneImage]);

    const showModal = React.useMemo(() => {
        if (checkIfGoBack())
            return true;
        return false;
    }, [checkIfGoBack]);

    const arrowClick = (e) => {
        window.history.back();
    }

    const homeClick = (e) => {
        if (!checkIfGoBack()) {
            navigate('/');
        }
    }

    const confirmGoHome = () => {
        if (window.location.pathname.includes('/product'))
            dispatch(productDataActions.clean());
        navigate('/');
    }

    return (
        <React.Fragment>
            <FixedTop>
                {!simpleTitle && <button className='btn btn-link position-absolute translate-middle-y top-50 start-0 ms-3' data-bs-toggle="modal" onClick={arrowClick}>
                    <FontAwesomeIcon icon="fa-regular fa-arrow-left" className="fs-4" size='2x' />
                </button>}
                <div className="text-break pe-2 ps-2">
                    {title}
                </div>
                {!simpleTitle && <button className='btn btn-link position-absolute translate-middle-y top-50 end-0 me-3'>
                    <FontAwesomeIcon icon="fa-regular fa-shop" className="fs-4 text-primary" data-bs-toggle="modal" data-bs-target={showModal ? "#homeModal" : ""} onClick={homeClick} size='2x'/>
                </button>}
            </FixedTop>
            <Modal
                title={"Sei sicuro di voler tornare alla schermata principale?"}
                text={"Sembra che ci siano delle modifiche non salvate, tornando alla home verranno perse, puoi aggiungere prima il prodotto al carrello per salvarlo. Vuoi uscire comunque?"}
                primaryText="Ritorna alla home"
                secondaryText="Annulla"
                handleConfirm={confirmGoHome}
                id="homeModal"
            />
        </React.Fragment>
    );
}