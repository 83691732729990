import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../button';

export function CardAddress({ icon, title, rows, note, favorite = false, handleEdit, handleSetDefault, handleDelete, link = null, linkText = null, check = false, globalSetDefault = false }) {
    const navigate = useNavigate();

    const handleAddAddress = () => {
        navigate(link);
    }

    return (
        <div className={`card rounded-4 overflow-hidden shadow m-3 ${check ? 'border border-primary border-3' : ''}`} onClick={globalSetDefault ? handleSetDefault : null}>
            <div className="card-body">
                <div className='w-100 text-center mb-3 position-relative'>
                    {icon}
                    {check && <FontAwesomeIcon icon="fa-regular fa-check-circle" className='position-absolute top-0 end-0 text-primary' size="2x" />}
                </div>
                {title && <h5 className="card-title fs-6 fw-bold">{title}</h5>}
                {
                    rows?.map((row, index) => (
                        <p className="card-text fs-6 m-0" key={index}>
                            {row}
                        </p>
                    ))
                }
                {note && <p className="fst-italic mt-1">{note}</p>}
                {linkText && <div className='w-100'>
                    <button className="btn btn-link fw-bold" onClick={handleAddAddress}>
                        {linkText}
                        <FontAwesomeIcon icon="fa-regular fa-chevron-right" className="ms-2 text-primary" />
                    </button>
                </div>}

                {!linkText &&
                    <React.Fragment>
                        <div className='d-flex justify-content-between align-items-center mt-2 gap-2'>
                            <Button onClick={handleEdit}>
                                <FontAwesomeIcon icon="fa-regular fa-pen-to-square" className="me-2" />
                               
                            </Button>
                            {favorite ?
                                <Button>
                                    <FontAwesomeIcon icon="fa-regular fa-star" className={'me-2'} />
                                    
                                </Button>
                                : <Button otherClass="btn-secondary" onClick={handleSetDefault}>
                                    <FontAwesomeIcon icon="fa-regular fa-star" className={'me-2'} />
                                    
                                </Button>
                            }

                            <Button otherClass="btn-danger" onClick={handleDelete}
                            otherProps={{ "data-bs-toggle": "modal", "data-bs-target": "#addressModal" }}>
                                <FontAwesomeIcon icon="fa-regular fa-trash-can" className="me-2" />
                                
                            </Button>
                        </div>
                    </React.Fragment>}
            </div>
        </div>
    );
}