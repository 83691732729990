import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BottomButton, Page } from '../../components';
import { applyDiscounts, calculatePriceInCart, findCourier, formatBillingAddress, formatShippingAddress, getAllUrlParams, estimateCartDeliveryDate } from '../../libs';
import { userActions } from '../../store/actions';
import { CardAddress, CardDiscounts, CardIconTextLink } from '../../ui';

export function CompleteOrder() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { products, discount } = useSelector(state => state.cartData);
    const { couriers, subCategoryDict, productionTimeData, courierKanguryPrice } = useSelector(state => state.mainData);
    const { shippingAddresses, billingAddresses } = useSelector((state) => state.userData);
    const defaultShippingAddress = shippingAddresses?.length > 0 ? shippingAddresses.find(item => item.isDefault) : null;
    const defaultBillingAddress = billingAddresses?.length > 0 ? billingAddresses.find(item => item.isDefault) : null;
    const [totalPrice, setTotalPrice] = React.useState(0);
    const { askBill } = getAllUrlParams();
    const [isAskingBill, setIsAskingBill] = React.useState(askBill === 'true');

    React.useEffect(() => {
        dispatch(userActions.getShippingAddresses());
        dispatch(userActions.getBillingAddresses());
    }, [dispatch]);

    const handleCompleteOrder = React.useCallback(() => {
        navigate(`/upload-order?askBill=${isAskingBill}`);
    }, [isAskingBill, navigate]);

    React.useEffect(() => {
        let totalPrice = 0;
        if (products && products.length > 0 && subCategoryDict) {
            products.forEach((item) => {
                totalPrice += calculatePriceInCart({ product: item, subCategoryDict, products });
            });
            setTotalPrice(totalPrice);
        }
    }, [discount, products, subCategoryDict]);

    const shippingRows = formatShippingAddress(defaultShippingAddress);

    let billingRows = formatBillingAddress(defaultBillingAddress);

    const courier = React.useMemo(() => {
        const country = defaultShippingAddress?.country;
        const state = defaultShippingAddress?.state;
        const itemsPrice = applyDiscounts(discount, totalPrice, 0).itemsPrice;
        return findCourier({ couriers, country, totalPrice: itemsPrice, state })
    }, [couriers, defaultShippingAddress?.country, defaultShippingAddress?.state, discount, totalPrice]);

    // Check if user can go to next step
    const enableNextButton = React.useMemo(() => {
        return defaultShippingAddress && (isAskingBill ? defaultBillingAddress : true) && courier;
    }, [defaultShippingAddress, isAskingBill, defaultBillingAddress, courier]);


    const [showedPrice, discountsNotApplicable] = React.useMemo(() => {
        const resultsDiscounts = applyDiscounts(discount, totalPrice, courier?.price || 0);
        const discountsNotApplicable = resultsDiscounts.discountsNotApplicable;
        const showKanguryPrice = resultsDiscounts.itemsPrice + courierKanguryPrice;
        return [showKanguryPrice, discountsNotApplicable];
    }, [discount, totalPrice, courier?.price, courierKanguryPrice]);

    const discountRows = React.useMemo(() => {
        let rows = [];
        let _totalPrice = totalPrice;
        let _courierPrice = parseFloat(courier?.price || 0);
        if (discount) {
            discount.forEach((item) => {
                const notApplicable = discountsNotApplicable.find((d) => d === item.pin);
                if (notApplicable) {
                    rows.push({
                        title: item.title,
                        text: `Sconto non applicabile`,
                        notApplicable: true,
                    });
                }
                else {
                    let priceDiscounted = 0;
                    let courierDiscount = 0;
                    let extendOnShippingCost = false;
                    if (item.type === 1) {
                        // discount in percentage
                        priceDiscounted = _totalPrice * (item.percentage.value / 100);
                        extendOnShippingCost = item.percentage.extendOnShippingCost;
                        if (item.percentage.creditOnShippingCost) {
                            courierDiscount += item.percentage.creditOnShippingCost
                        }
                    }
                    else {
                        // discount in credit
                        priceDiscounted = item.credit.value;
                        extendOnShippingCost = item.credit.extendOnShippingCost;
                        if (item.credit.creditOnShippingCost) {
                            courierDiscount += item.credit.creditOnShippingCost;
                        }
                    }
                    let totalDiscount = 0;
                    if (priceDiscounted < _totalPrice) {
                        // discount is less than total price
                        totalDiscount = priceDiscounted;
                    } else {
                        // discount is more than total price
                        if (extendOnShippingCost) {
                            const remainDiscount = priceDiscounted - _totalPrice;
                            courierDiscount += remainDiscount;
                        }
                        totalDiscount = _totalPrice;
                        priceDiscounted = _totalPrice;
                    }
                    if (courierDiscount < _courierPrice) {
                        // courier discount is less than courier price
                        totalDiscount += courierDiscount;
                    }
                    else {
                        // courier discount is more than courier price
                        totalDiscount += _courierPrice;
                        courierDiscount = _courierPrice;
                    }
                    rows.push({
                        title: item.title,
                        text: `Sconto: ${totalDiscount.toFixed(2).replace(".", ",")} €`,
                    });
                    _totalPrice -= priceDiscounted;
                    _courierPrice -= courierDiscount;
                }
            });
        }
        return rows
    }, [courier?.price, discount, discountsNotApplicable, totalPrice]);

    const dateFormatted = React.useMemo(() => {
        if (products && subCategoryDict && productionTimeData && courier && products.length > 0) {
            const date = estimateCartDeliveryDate(products, subCategoryDict, productionTimeData, courier)
            // format date is Tue Dec 05 2023 15:31:47 GMT+0100 (Central European Standard Time)
            // cast to format 'Martedì 5 Dicembre'
            return date.toLocaleDateString('it-IT', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
        }
        return null;
    }, [products, subCategoryDict, productionTimeData, courier]);

    if (products.length === 0) {
        navigate('/cart');
        return;
    }

    return (
        <Page title="Concludi Ordine"
            customBottomSection={
                <BottomButton otherClass={"p-1"}
                    onClick={handleCompleteOrder}
                    disabled={!enableNextButton}
                    content={
                        <React.Fragment>
                            Prosegui
                            <FontAwesomeIcon icon="fa-regular fa-chevron-right" className='ms-2' />
                        </React.Fragment>
                    }
                    contentUp={
                        <div className='d-flex justify-content-between align-items-center fw-bold w-100 ps-4 pe-4 pt-1'>
                            <div>Totale (IVA incl.):</div>
                            <div className='text-primary'>{showedPrice.toFixed(2).replace(".", ",")} €</div>
                        </div>
                    }>
                </BottomButton>
            }>
            <div className='p-2'>
                <p className='fw-bold'>Indirizzo di Consegna</p>

                {defaultShippingAddress ?
                    <CardAddress
                        title={defaultShippingAddress.recipient}
                        rows={shippingRows}
                        note={defaultShippingAddress.courierNotes}
                        icon={<FontAwesomeIcon icon="fa-regular fa-house-heart" size='2x' />}
                        link={'/shipping-addresses?check=true'}
                        linkText={'Cambia Indirizzo'}
                    />
                    :
                    <CardIconTextLink
                        icon={<FontAwesomeIcon icon="fa-regular fa-house-heart" size='2x' />}
                        link={'/shipping-addresses?index=-1'}
                        linkText={'Aggiungi un nuovo indirizzo'}
                        title={"Nessun indirizzo selezionato"}
                    />}


                <p className='fw-bold'>Indirizzo di Fatturazione</p>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="askBill"
                        checked={isAskingBill}
                        onChange={(e) => {
                            setIsAskingBill(e.target.checked)
                            // add params to url using navigate without incrementing history
                            navigate({ search: `?${e.target.checked ? 'askBill=true' : ''}` }, { replace: true });
                        }} />
                    <label className="form-check-label" htmlFor="askBill">
                        Richiedi Fattura
                    </label>
                </div>
                {isAskingBill && (
                    defaultBillingAddress ?
                        <CardAddress
                            title={defaultShippingAddress.recipient}
                            rows={billingRows}
                            note={defaultShippingAddress.courierNotes}
                            icon={<FontAwesomeIcon icon="fa-regular fa-house-heart" size='2x' />}
                            link={'/billing-addresses?check=true'}
                            linkText={'Modifica Dati'}
                        />
                        : <CardIconTextLink
                            icon={<FontAwesomeIcon icon="fa-regular fa-money-check-dollar-pen" size='2x' />}
                            link={'/billing-addresses?index=-1'}
                            linkText={'Aggiungi un nuovo indirizzo'}
                            title={"Nessun indirizzo di fatturazione selezionato"}
                        />)}

                <p className='fw-bold'>Metodo di Spedizione</p>
                {courier ?
                    <CardIconTextLink
                        centerTitle={false}
                        darkText={true}
                        icon={<FontAwesomeIcon icon="fa-regular fa-truck-fast" size='2x' />}
                        title={courier.name}
                        text={"Prezzo: " + courierKanguryPrice + " €"}
                        footer={
                            <div className='d-flex align-items-center'>
                                <div className=''>Consegna stimata: {dateFormatted}</div>
                            </div>}
                    />
                    : <CardIconTextLink
                        icon={<FontAwesomeIcon icon="fa-regular fa-truck-fast" size='2x' />}
                        title={defaultShippingAddress ? "Indirizzo non raggiungiungibile al momento, inserirne un altro"
                            : "Specificare un indirizzo per informazioni sui metodi di spedizione"}
                        danger={true}
                    />}

                {/* <p className='fw-bold mt-3'>Codici Sconto</p>
                {discountRows.length > 0 ?
                    <CardDiscounts
                        link={`/discount-code?totalPrice=${totalPrice}&courierPrice=${courier?.price || 0}`}
                        rows={discountRows}
                    />
                    :
                    <CardIconTextLink
                        icon={<FontAwesomeIcon icon="fa-regular fa-tags" size='2x' />}
                        link={`/discount-code?totalPrice=${totalPrice}&courierPrice=${courier?.price || 0}`}
                        linkText={'Aggiungi Codice'}
                        title={"Nessun sconto applicato"}
                    />} */}
            </div>
        </Page>
    );
}