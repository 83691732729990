export const goToPhotobook = ({ category, categoryId, subCategoryId, navigate, fingerPrint = null, id = null }) => {
  // special case for externalEditUrl
  const url_application = window.location.origin;
  const token = localStorage.getItem('token');
  let url = `${category.externalEditUrl}?userSessionToken=${token}&endUrl=${url_application}/photobook-return`;
  if (fingerPrint) url = `${url}&fingerPrint=${fingerPrint}`;
  if (window.ReactNativeWebView) {
    let externalEditUrl = category.externalEditUrl;
    if (id) {
      // "externalEditUrl": "https://www.digitalpix.com/Mobile/Pages/BookEditor/product/300436"
      // substitute /product/300436 with /item/id
      externalEditUrl = externalEditUrl.replace(/\/product\/\d+/, `/item/${id}`);
    }
    window.ReactNativeWebView.postMessage(JSON.stringify({
      type: 'externalEditUrl',
      token,
      categoryId,
      subCategoryId,
      externalEditUrl,
      fingerPrint
    }));
  }
  else
    navigate(`/external-links?url=${url}`);
}

export const goToProjects = ({ navigate }) => {
  const url = "https://www.digitalpix.com/Mobile/Pages/BookEditor/Projects/";
  const url_application = window.location.origin;
  const token = localStorage.getItem('token');
  const url_with_token = `${url}?userSessionToken=${token}&endUrl=${url_application}/photobook-return`;
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(JSON.stringify({
      type: 'externalEditUrl',
      token,
      externalEditUrl: url
    }));
  }
  else
    navigate(`/external-links?url=${url_with_token}`);
}