import React from 'react';
import './App.scss';
import {
  BrowserRouter as Router, Route, Routes
} from "react-router-dom";
import { Album, AlbumGift, AlbumPrivacy, BillingAddresses, Cart, CompleteOrder, Details, ConsumerProtection, Assistance, ShippingCosts, PrivacyPolicy, DiscountCode, DiscountCodeList, Editor, ExternalLinks, ForgottenPassword, Home, IframeReturn, Login, ManagePlan, Orders, PageNotFound, Pay, Product, Profile, ProfileRemove, Property, Registration, Selection, ShippingAddresses, SmartConnect, AlbumList, Template, UploadOrder, WaitExitProduct, LandingPage, EmailConfirmed, AdminPanel, ResetPassword, InstallGuide } from './pages';
import { common } from './store/services';
import { askReview } from './libs';
import './bootstrap/bootstrap.scss';

function App() {

  React.useEffect(() => {
    // Refresh "refreshToken" when enter in the app

     const data = JSON.parse(localStorage.getItem('data'));
    if (localStorage.getItem('token') && localStorage.getItem('token') !== 'undefined') {
      if (new Date(data.refreshExpirationDate) < new Date()) {
        // refresh token expired, user must login again
        localStorage.removeItem('token');
        localStorage.removeItem('data');
      } else {
        // check if location is /
        if (window.location.pathname === '/') {
          common.refreshToken();
        }
      }
    }
    /*const gaAutorized = localStorage.getItem('gaAutorized');
    if (gaAutorized === 'true') {
      initializeGoogleAnalytics();
    } */
  }, []);

  React.useEffect(() => {
    // check if we want ask a review to the user
    askReview();
  }, []);

  window.logMessage = (text) => {
    const message = {
      type: 'logMessage',
      message: JSON.stringify(text),
    }
    if (window.ReactNativeWebView)
      window.ReactNativeWebView.postMessage(JSON.stringify(message));
    else console.log(text);
  }

  const version = require('../package.json').version;
  React.useEffect(() => {
    if (window.ReactNativeWebView) {
      const message = {
        type: 'checkVersion',
        version,
      }
      window.ReactNativeWebView.postMessage(JSON.stringify(message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Router>
      <Routes>
        {<Route path="/" element={<Home />} />}
        <Route path="/admin-panel" element={<AdminPanel />} />
        <Route path="/album" element={<Album />} />
        <Route path="/album-gift" element={<AlbumGift />} />
        <Route path="/album-privacy" element={<AlbumPrivacy />} />
        <Route path="/assistance" element={<Assistance />} />
        <Route path="/billing-addresses" element={<BillingAddresses />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/complete-order" element={<CompleteOrder />} />
        <Route path="/consumer-protection" element={<ConsumerProtection />} />
        <Route path="/details" element={<Details />} />
        <Route path="/discount-code" element={<DiscountCode />} />
        <Route path="/discount-code-list" element={<DiscountCodeList />} />
        <Route path="/editor" element={<Editor />} />
        <Route path="/email-confirmed" element={<EmailConfirmed />} />
        <Route path="/external-links" element={<ExternalLinks />} />
        <Route path="/forgotten-password" element={<ForgottenPassword />} />
        <Route path="/iframe-return" element={<IframeReturn />} />
        <Route path="/install-guide" element={<InstallGuide />} />
        <Route path="/landing-page" element={<LandingPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/manage-plan" element={<ManagePlan />} />
        <Route path="/orders" element={<Orders />} />
        <Route path="/pay" element={<Pay />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/product" element={<Product />} />
        <Route path="/profile" element={<Profile version={version} />} />
        <Route path="/profile-remove" element={<ProfileRemove />} />
        <Route path="/property" element={<Property />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/selection" element={<Selection />} />
        <Route path="/shipping-addresses" element={<ShippingAddresses />} />
        <Route path="/shipping-costs" element={<ShippingCosts />} />
        <Route path="/smart-connect" element={<SmartConnect />} />
        <Route path="/album-list" element={<AlbumList />} />
        <Route path="/template" element={<Template />} />
        <Route path="/upload-order" element={<UploadOrder />} />
        <Route path="/warn-exit-product" element={<WaitExitProduct />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
