import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useDispatch } from 'react-redux';
import { ProgressbarCircle, AlertFixed } from '../../ui';
import { albumService } from '../../store/services/album.service';

const ALERT_MESSAGES = {
    TOO_MANY_MEMORY: 'File troppo grandi selezionati, il limite è di ',
    ERROR_DURING_UPLOAD: 'Errore durante il caricamento dei file, potrebbe essere causato dal caricamento di file non salvati in locale. Salvali in locale e riprova.'
}

const CONVERT_VALUE = 1000000;

export function UploadButtonAlbum({ active, memoryAvailable, handleNewMedia, albumId }) {
    const [showAlert, setShowAlert] = React.useState(false);
    const [internalImageUploading, setInternalImageUploading] = React.useState(false);
    const [errorDuringUpload, setErrorDuringUpload] = React.useState(false);
    const [imagesUploaded, setImagesUploaded] = React.useState(0);
    const [allImages, setAllImages] = React.useState(10);
    const [alertMessage, setAlertMessage] = React.useState('');

    React.useEffect(() => {
        if (errorDuringUpload) {
            setAlertMessage(ALERT_MESSAGES.ERROR_DURING_UPLOAD);
            setShowAlert(true);
        }
    }, [errorDuringUpload])

    const loadImages = async (file) => {
        const formattedData = new FormData();
        formattedData.append('file', file);
        formattedData.append('album_id', albumId);
        window.formattedData = formattedData;
        const res = await albumService.uploadFile(formattedData);
        if (res) {
            return res;
        }
    }

    const handleButton = async (e) => {
        setInternalImageUploading(true);
        const files = e.target.files;
        const copyFiles = [...files];
        const allFileSize = copyFiles.filter((file) => file.type.includes('image') || file.type.includes('video')).reduce((acc, file) => acc + file.size, 0)
        setImagesUploaded(0);
        setAllImages(copyFiles.length);
        if (allFileSize > memoryAvailable) {
            setAlertMessage('Memoria insufficiente per caricare tutti i file selezionati');
            setShowAlert(true);
            setInternalImageUploading(false);
            return;
        }
        // URL.createObjectURL
        const newMedia = [];
        // clean input
        e.target.value = "";
        
        for (let i = 0; i < copyFiles.length; i++) {
            const file = copyFiles[i];
            const fileUploaded = await loadImages(file);
            if (fileUploaded) {
                newMedia.push(fileUploaded);
            }
            setImagesUploaded((prev) => prev + 1);
        }

        handleNewMedia(newMedia, allFileSize / CONVERT_VALUE);
        setInternalImageUploading(false);
    }

    const handleCloseAlert = () => {
        setShowAlert(false);
    }

    const percentage = Math.round((imagesUploaded / allImages) * 100);
    return (
        <React.Fragment>
            <label className="w-100 mt-3">
                <p className={`btn fw-bold btn-outline-primary border border-3 border-primary w-100 text-dark rounded-5 no-hover ${active ? '' : 'disabled'}`}>
                    <FontAwesomeIcon icon="fa-regular fa-square-plus" className="me-2" />
                    Carica Immagini o Video
                </p>
                <input type="file" multiple={true} className="opacity-0" onChange={handleButton} accept="image/*, video/*"
                    lang='it' disabled={!active} />
            </label>
            <AlertFixed message={alertMessage} showAlert={showAlert} handleCloseAlert={handleCloseAlert} />
            {internalImageUploading && <div className="position-fixed top-0 start-0 w-100 h-100 bg-white d-flex justify-content-center align-items-center flex-column" style={{ zIndex: 9999 }}>
                <ProgressbarCircle percentage={percentage} delay={false} />
                <h2 className='mt-4'>{imagesUploaded} / {allImages}</h2>
                <h1 className='mt-4'>Caricamento file...</h1>
            </div>}
        </React.Fragment>
    );
}