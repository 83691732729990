/**
 * Function to find the minimum price in a list of products that include the current id selection passed
 * @param {*} products List of products we want to check the prices
 * @param {*} selectionValueId Id of the current selection value selected by user to verify if a product is selectable based on the current option
 * @param {*} usePackageCount If true, the price returned is divided by the package count of the product
 * @returns
 */
export const findMinPrice = ({
  products,
  selectionValueId = -1,
  useOriginalPrice = false,
  useKanguryPrice=true
}) => {
  let minPrice;
  Object.values(products).forEach((p) => {
    if (
      selectionValueId === -1 ||
      p.selectionValues.includes(selectionValueId)
    ) {
      const prices = useKanguryPrice ? p.kanguryPrices : p.prices;
      prices.forEach((price) => {
        if (useOriginalPrice) {
          if (!minPrice || price.originalPrice < minPrice) {
            minPrice = price.originalPrice;
          }
        }
        else {
          if (!minPrice || price.price < minPrice) {
            minPrice = price.price;
          }
        }
      });
    }
  });
  return minPrice?.toFixed(2) + " €";
};

/**
 * Function to calculate the total price based on the number of images uploaded, the price per image 
 * and the quantity of the products incremented in the cart
 * @param {*} prices List of prices of a product based on the quantity 
 * @param {*} imagesList List of images uploaded by the user for a specific product
 * @param {*} quantity Quantity of the product incremented in the cart
 * @param {*} imagesInCart Number of images uploaded by the user for the same product in the cart
 * @param {*} processingType Type of processing of the product
 * @param {*} countInCart Quantity of the product incremented in the cart
 * @param {*} useOriginalPrice If true, the price returned is the original price of the product instead of the current price (modified by temporary discounts)
 * @returns {price, originalPrice}
 */
export const calculatePrice = ({ prices, imagesList, imagesInCart = 0, processingType, countInCart = 1, kanguryPrices }) => {
  // Count total images uploaded, in case of processing type 1 (like classic) we need to count the total images
  let imagesInList = processingType === 1 ? imagesList.reduce(
    (acc, imagesList) => acc + imagesList.quantity,
    0
  ) : 1;
  imagesInList = imagesInList * countInCart;
  // If product has package count, we calculate the price based on the quantity of the product in cart because the price is per package, not per quantity
  const packageCount = prices[0].packageCount;
  if (packageCount) {
    return {
      price: prices[0].price * imagesInList,
      originalPrice: prices[0].originalPrice * imagesInList,
      kanguryPrice: kanguryPrices[0].price * imagesInList,
      kanguryOriginalPrice: kanguryPrices[0].originalPrice * imagesInList,
    };
  }
  const totalImages = imagesInCart;
  let price = 0, originalPrice = 0, kanguryPrice = 0, kanguryOriginalPrice = 0;
  prices.every((p) => {
    if (p.minQuantity > totalImages) {
      return false;
    }
    price = p.price;
    originalPrice = p.originalPrice;
    return true;
  });
  kanguryPrices?.every((p) => {
    if (p.minQuantity > totalImages) {
      return false;
    }
    kanguryPrice = p.price;
    kanguryOriginalPrice = p.originalPrice;
    return true;
  })
  return {
    price: price * imagesInList,
    originalPrice: originalPrice * imagesInList,
    kanguryPrice: kanguryPrice * imagesInList,
    kanguryOriginalPrice: kanguryOriginalPrice * imagesInList,
  };
};

/**
 * Return the increment of the price based on modifiers passed
 * @param {*} price Price we want to modify
 * @param {*} modifiers List of modifiers to apply to the price put in object in the format {'group_id': 'modifier_of_group'}
 * @returns
 */
export const priceModifiers = (price, modifiers) => {
  let newPrice = 0;
  Object.values(modifiers).forEach((m) => {
    if (m !== 0) newPrice = newPrice + (price * m) / 100;
  });
  return newPrice;
};

/**
 * @param {*} properties list of properties of a product
 * @returns List of ids of properties that are editable in editor
 */
export const getPropertyValueIdsEditableInEditor = (properties) => {
  if (!properties) return "";
  let ids = "";
  Object.values(properties).forEach((p) => {
    if (p.editInEditor) ids += p.propertyValues[0].id + ";";
  });
  if (ids.length !== "") return ids.slice(0, -1);
  return ids;
};


/**
 * @param {*} properties list of properties of a product
 * @returns List of ids of all properties
 */
export const getPropertyValueIds = (properties) => {
  if (!properties) return { ids: "", values: "" };
  let values = "", ids = "";
  Object.values(properties).forEach((p) => {
    values += p.propertyValues[0].id + ";";
    ids += p.id + ";";
  });
  if (values.length !== "") {
    values = values.slice(0, -1);
    ids = ids.slice(0, -1);
  }
  return { ids, values };
};

/**
 * @param {*} properties array of properties value (ex: ["1", "2"])
 * @param {*} templates array of templates, each template is an object
 * @returns list of valid templates based on properties value passed
 */
export const getTemplatesByProperties = (properties, templates) => {
  if (!properties || !templates) return [];
  if (templates.length === 1) return templates;
  return Object.values(templates).filter((t) => {
    let foundPropInTemplate = false;
    Object.values(properties).forEach((p) => {
      if (t.propertyValueFilter.includes(parseInt(p)))
        foundPropInTemplate = true;
    });
    return !foundPropInTemplate;
  });
};

/**
 * @param {*} propertyValuesId list of property values id (ex: ["1", "2"])
 * @param {*} product single product
 * @returns an object with the modifiers to apply to the product price based on the property values passed. Each object for a key has the priceModifierGroup id
 */
export const getModifierByProperties = (propertyValuesId, product) => {
  if (propertyValuesId.length === 0 || !product || propertyValuesId.length === 0) return null;
  const modifiers = {};
  propertyValuesId.forEach((_proprietyValueId) => {
    const proprietyValueId = parseInt(_proprietyValueId);
    product.properties.forEach((property) => {
      property.propertyValues.every((propertyValue) => {
        if (
          parseInt(propertyValue.id) === proprietyValueId &&
          propertyValue.priceModifier
        ) {
          if (!modifiers[propertyValue.priceModifierGroup])
            modifiers[propertyValue.priceModifierGroup] =
              propertyValue.priceModifier;
          else
            modifiers[propertyValue.priceModifierGroup] +=
              propertyValue.priceModifier;
          return false;
        }
        return true;
      });
    });
  });
  return modifiers;
};

/**
 * Given a list of product in cart and a product id check how many of that product are 
 * already in cart
 * Processing type 1 means that the product is a photo book, so we need to check the total images inside, in other cases we check the quantity of the product in cart
 * excludedId is the unique id created when product is added to cart, it is used to exclude the product we are checking from the check, it is different from productId,
 * because productId is the id of the product in the database, excludedId is the id of the product in the cart created randomly
 * @param {*} cart List of products in cart
 * @param {*} productId Id of the product we want to check
 * @param {*} processingType Type of processing of the product
 * @param {*} excludedId Id of the product we want to exclude from the check
 */
export const getQuantityOfProductInCart = ({ cart, productId, processingType }) => {
  if (!cart || !productId || cart.length === 0) return 0;
  let quantity = 0;
  cart.forEach((c) => {
    if (parseInt(c.productId) === parseInt(productId)) {
      if (processingType === 1)
        quantity += c.totalImages;
      else
        quantity += c.countInCart;
    };
  });
  return quantity;
}

/**
 * Given a list of products found the oldest expiration date
 */
export const getOldestExpirationDate = (items) => {
  // items contain expirationDate in format YYYY-MM-DDTHH:mm:ss.sssZ
  if (!items || items.length === 0) return null
  let oldest = items[0].expirationDate
  items.forEach((item) => {
    if (item.expirationDate < oldest) oldest = item.expirationDate
  })
  return oldest
}


/**
 * Return true if image is cropped for the template passed
  * @param {*} image
  * @param {*} template
  * @returns
  * */
export const showCropAlertForSingleImage = (image, template, processingType) => {
  if (!image || !template || !(image.image || image.originalImage)) return false;
  const imageAspectRatio = image.imageWidth / image.imageHeight;
  const templateAspectRatio = template.width / template.height;
  const designObjectImage = template.designObjects.find(d => d.image.type === 0);
  const designObjectImageAspectRatio = designObjectImage.width / designObjectImage.height;

  if (Math.abs(imageAspectRatio - templateAspectRatio * designObjectImageAspectRatio) > MAX_CUT_DIFFERENCE) {
    if(processingType === 1) {
      // check also in rotate image becase in processing type 1 we rotate the image (ex classic photo)
      const imageAspectRatioRotated = image.imageHeight / image.imageWidth;
      if (Math.abs(imageAspectRatioRotated - templateAspectRatio * designObjectImageAspectRatio) > MAX_CUT_DIFFERENCE) {
        return true;
      }
      return false;
    }
    return true;
  }
  return false;
}

export const MAX_CUT_DIFFERENCE = 0.02;
/**
 * Return true if at least one item is cropped and user not go inside the editor
 * of this photo
 * @param {*} imagesList 
 * @param {*} template 
 * @param {*} processingType
 */
export const showCropAlert = (imagesList, templateList, processingType) => {
  if (!imagesList || !templateList) return false;
  let show = false;
  imagesList.every((image, index) => {
    if (image.wasInEditor || !image.originalImage) return true
    const template = templateList.length === 1 ? templateList[0] : templateList[index];

    if (showCropAlertForSingleImage(image, template, processingType)) {
      show = true;
      return false;
    }
    return true;
  });
  return show;
}

/**
 * Return true if the image resolution is too low for the template passed
 * @param {*} image 
 * @param {*} template 
 * @returns 
 */
export const showResolutionAlertForSingleImage = (image, template) => {
  if (!image || !template || !(image.image || image.originalImage)) return false;
  const zoom = image.editorProps?.zoom || 1;
  const minResolution = template.minResolution;
  // calculate dpi of image from width and height
  const designObjectImage = template.designObjects.find(d => d.image.type === 0);
  const imgWidthCm = designObjectImage.width * template.widthCm / 100;
  const imgHeightCm = designObjectImage.height * template.heightCm / 100;
  const imageWidthPx = (image.width || image.imageWidth) / zoom;
  const imageHeightPx = (image.height || image.imageHeight) / zoom;
  const imageDpiWidth = imageWidthPx / (imgWidthCm / 2.54);
  const imageDpiHeight = imageHeightPx / (imgHeightCm / 2.54);
  if (imageDpiWidth < minResolution || imageDpiHeight < minResolution) {
    return true;
  }
  return false;
}

/**
 * Return true if at least one item has a resolution too low and user not go inside the editor
 * @param {*} imagesList 
 * @param {*} templateList 
 * @returns 
 */
export const showResolutionAlert = (imagesList, templateList) => {
  if (!imagesList || !templateList) return false;
  let show = false;
  imagesList.every((image, index) => {
    if (!image.originalImage) return true
    const template = templateList.length === 1 ? templateList[0] : templateList[index];
    if (showResolutionAlertForSingleImage(image, template)) {
      show = true;
      return false;
    }
    return true;
  });
  return show;
}

export const lookForProductById = ({ id, subCategoryDict, categoryDict }) => {
  const subCategoryKeys = Object.keys(subCategoryDict);
  const categoryKeys = Object.keys(categoryDict);
  if (subCategoryKeys.includes(id.toString())) {
    for (let k = 0; k < categoryKeys.length; k++) {
      const category = categoryDict[categoryKeys[k]];
      if (category.subCategoryIDs.includes(id)) {
        return { subCategoryId: id, categoryId: categoryKeys[k] };
      }
    }
    return { subCategoryId: id, categoryId: subCategoryDict[id].categoryId };
  }
  for (let i = 0; i < subCategoryKeys.length; i++) {
    const subCategory = subCategoryDict[subCategoryKeys[i]];
    const productKeys = Object.keys(subCategory.subProductDict);
    for (let j = 0; j < productKeys.length; j++) {
      const product = subCategory.subProductDict[productKeys[j]];
      if (parseInt(product.id) === parseInt(id)) {
        // find Category id before return
        for (let k = 0; k < categoryKeys.length; k++) {
          const category = categoryDict[categoryKeys[k]];
          if (category.subCategoryIDs.includes(parseInt(subCategoryKeys[i]))) {
            return { product, subCategoryId: subCategoryKeys[i], categoryId: categoryKeys[k] };
          }
        }
      }
    }
  }
}