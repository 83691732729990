import { common } from "./common.service"

export const orderService = {
    getList,
    post,
    remove,
    get,
    getPayUrl
}

function getList({startDate, endDate, status}) {
    let url = `order?`;
    if(startDate) url += `startDate=${startDate}&`;
    if(endDate) url += `endDate=${endDate}&`;
    if(status) url += `status=${status}&`;
    return common.fetchAPI({ url, method: "GET", token: true });
}

function post(data) {
    let url = `order?`;
    return common.fetchAPI({ url, method: "POST", data, token: true });
}

function remove(id) {
    let url = `order/${id}`;
    return common.fetchAPI({ url, method: "DELETE", token: true });
}

function get(id) {
    let url = id ? `order/${id}` : `order`;
    return common.fetchAPI({ url, method: "GET", token: true });
}

function getPayUrl(orderId) {
    let url = `order/${orderId}/payment`;
    return common.fetchAPI({ url, method: "GET", token: true });
}