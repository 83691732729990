import { Page } from '../../components';

export function PageNotFound() {
    return (
        <Page>
            <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
            <h1>Pagina non trovata</h1>
            </div>
        </Page>
    );
}