import React, { useEffect } from 'react';
import { Page } from '../../components';
import { CardDiscount } from '../../components/card-discount';
import { useSelector, useDispatch } from 'react-redux';
import { discountService } from '../../store/services';

export function DiscountCodeList() {
    const [discountCodesList, setDiscountCodesList] = React.useState(null);
    const { general } = useSelector(state => state.mainData);

    return (
        <Page title={'Codici Sconto'}
            visibleBottomNavigation={false}>
            <div className='container d-flex align-items-start justify-content-center'>
                <div className='card p-4 shadow-lg mb-4 mt-4'>
                    <h1 className='text-center mb-4'>Codici Sconto</h1>
                    <p>
                        Stiamo lavorando per offrirvi a breve dei <strong>codici sconto</strong> per i nostri prodotti.
                    </p>
                </div>
            </div>
        </Page>
    )

    /* React.useEffect(() => {
        if (!general) return;
        discountService.getAll(general.application)
            .then(data => {
                setDiscountCodesList(data);
            })
            .catch(err => {
                console.error(err);
            });
    }, [general])

    return (
        <Page visibleBottomNavigation={false}
            title={"Codici Sconto Disponibili"}>
            {discountCodesList
                && discountCodesList.map((discount, index) => {
                    return (
                        <CardDiscount
                            key={index}
                            title={discount.title}
                            code={discount.pin}
                            description={discount.description}
                            shareAndCopy={true}
                            dateLimit={discount.dateLimit}
                            minItemTotal={discount.minItemTotal}
                        />
                    )
                })}
        </Page>
    ); */
}