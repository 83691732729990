import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ImgWithPlaceholder } from '../../img-with-placeholder';
import { PlaceholdersLoaderGif } from '../../assets';

export function TemplateImage({ active = false, title, image }) {

    return (
        <div className={`position-relative card mb-2 rounded-4 shadow border border-5 p-1 ${active ? 'border-primary' : 'border-white'}`}>
            {active && <FontAwesomeIcon icon="fa-regular fa-check-circle" className='position-absolute top-0 end-0 text-white me-n3 mt-n3 z-1 bg-primary rounded-5' size="2x" />}
            <ImgWithPlaceholder src={image} className="img-fluid pe-none" alt="..." unselectable='on' placeholder={PlaceholdersLoaderGif.loader_1080_1800} />
            <div className='text-center' loading="lazy">{title}</div>
        </div>
    );
}