import { localDB } from "../cache";


export const productConstants = {
  CLEAN: "PRODUCT_CLEAN_STATE",
  GET: "PRODUCT_GET_IMAGES",
  ADD: "PRODUCT_ADD_IMAGE",
  ADD_MANY: "PRODUCT_ADD_MANY_IMAGES",
  UPDATE: "PRODUCT_UPDATE_IMAGE",
  REMOVE: "PRODUCT_REMOVE_IMAGE",
  REMOVE_PAGE_IMAGE: "PRODUCT_REMOVE_PAGE_IMAGE",
  ADD_EDITABLE_PROPERTY: "PRODUCT_ADD_EDITABLE_PROPERTY",
  CLEAR_EDITABLE_PROPERTY: "PRODUCT_CLEAR_EDITABLE_PROPERTY",
  RESTORE_FROM_CART: "PRODUCT_RESTORE_FROM_CART",
  UPDATE_IMAGE_URL: "PRODUCT_UPDATE_IMAGE_URL",
  LOCK_CLEAN: "PRODUCT_BLOCK_CLEAN",
  UNLOCK_CLEAN: "PRODUCT_UNLOCK_CLEAN",
  REMOVE_ID: "PRODUCT_REMOVE_IMAGE_ID",
  REMOVE_IDS: "PRODUCT_REMOVE_IMAGE_IDS",
  VALIDATE_IDS: "PRODUCT_VALIDATE_IDS",
};

function clean() {
  return (dispatch) => {
    dispatch({ type: productConstants.CLEAN });
  };
}

function add(image) {
  return (dispatch) => {
    dispatch({ type: productConstants.ADD, image });
  };
}

function addMany(images) {
  return (dispatch) => {
    dispatch({ type: productConstants.ADD_MANY, images });
  };
}

function get() {
  return (dispatch) => {
    localDB.get("products").then((products) => {
      dispatch({ type: productConstants.GET, products });
    });
  };
}

function update(imageData, index) {
  return (dispatch) => {
    dispatch({ type: productConstants.UPDATE, index, imageData });
  };
}

function remove(index) {
  return (dispatch) => {
    dispatch({ type: productConstants.REMOVE, index });
  };
}

function removePageImage(index) {
  return (dispatch) => {
    dispatch({ type: productConstants.REMOVE_PAGE_IMAGE, index });
  };
}

function addEditableProperty(propValue) {
  return (dispatch) => {
    dispatch({ type: productConstants.ADD_EDITABLE_PROPERTY, propValue });
  };
}

function clearEditableProperty() {
  return (dispatch) => {
    dispatch({ type: productConstants.CLEAR_EDITABLE_PROPERTY });
  };
}

function restoreFromCart(imagesList) {
  return (dispatch) => {
    dispatch({ type: productConstants.RESTORE_FROM_CART, imagesList });
  };
}

function updateImageUrl(index, url) {
  return (dispatch) => {
    dispatch({ type: productConstants.UPDATE_IMAGE_URL, index, url });
  };
}

/**
 * When the user want add a product to the cart but need to do login first, 
 * we need to block the clean action
 * @returns 
 */
function lockClean(){
  return (dispatch) => {
    dispatch({ type: productConstants.LOCK_CLEAN });
  };
}

function unlockClean(){
  return (dispatch) => {
    dispatch({ type: productConstants.UNLOCK_CLEAN });
  };
}

/**
 * Actions for native gallery
 */
function removeId(id) {
  return (dispatch) => {
    dispatch({ type: productConstants.REMOVE_ID, id });
  };
}

function removeIds(ids) {
  return (dispatch) => {
    dispatch({ type: productConstants.REMOVE_IDS, ids });
  };
}

function validateIds(ids) {
  return (dispatch) => {
    dispatch({ type: productConstants.VALIDATE_IDS, ids });
  };
}

export const productDataActions = {
  add,
  addMany,
  clean,
  get,
  remove,
  removePageImage,
  update,
  addEditableProperty,
  clearEditableProperty,
  restoreFromCart,
  updateImageUrl,
  lockClean,
  unlockClean,
  removeId,
  removeIds,
  validateIds,
};
