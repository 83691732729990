import { orderService } from "../services";

export const orderConstants = {
  GET_REQUEST: "ORDER_GET_REQUEST",
  GET_SUCCESS: "ORDER_GET_SUCCESS",
  GET_ERROR: "ORDER_GET_ERROR",
  REMOVE_REQUEST: "ORDER_REMOVE_REQUEST",
  REMOVE_SUCCESS: "ORDER_REMOVE_SUCCESS",
  REMOVE_ERROR: "ORDER_REMOVE_ERROR",
};

function get(filters = undefined) {
  return (dispatch) => {
    dispatch(request());
    if (filters) {
      orderService.getList(filters).then(
        (data) => {
          dispatch(success(data));
        },
        (error) => {
          dispatch(failure(error));
        }
      );
    } else {
      orderService.get().then(
        (data) => {
          dispatch(success(data));
        },
        (error) => {
          dispatch(failure(error));
        }
      );
    }
  };

  function request() {
    return { type: orderConstants.GET_REQUEST };
  }
  function success(data) {
    return { type: orderConstants.GET_SUCCESS, data };
  }
  function failure(error) {
    return { type: orderConstants.GET_ERROR, error };
  }
}

function remove(id) {
  return (dispatch) => {
    dispatch(request());
    orderService.remove(id).then(
      () => {
        dispatch(success(id));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: orderConstants.REMOVE_REQUEST };
  }
  function success(id) {
    return { type: orderConstants.REMOVE_SUCCESS, id };
  }
  function failure(error) {
    return { type: orderConstants.REMOVE_ERROR, error };
  }
}


export const orderActions = {
  get,
  remove
};
