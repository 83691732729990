import { orderConstants } from "../actions";

const initialState = {
  orders: [],
};

const orderData = (state = initialState, action) => {
  let draft = {
    ...state,
  };
  switch (action.type) {
    case orderConstants.GET_SUCCESS:
      draft.orders = action.data;
      break;
    case orderConstants.REMOVE_SUCCESS:
      draft.orders = draft.orders.filter((order) => order.id !== action.id);
      break;
    default:
      break;
  }
  return draft;
}

export default orderData;
