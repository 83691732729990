import React from 'react';
import { Page } from '../../components';
import Installed from "./installed.jpeg";
import Android1 from "./android-1.jpg";
import Android2 from "./android-2.jpg";
import Ios1 from "./ios-1.jpg";
import Ios2 from "./ios-2.jpg";

export function InstallGuide() {


    return (
        <Page title={"Installazione Kangury"}>
            <div className='container'>
                <h1 style={{ textAlign: 'center', marginTop: '10px' }}>Perchè installarlo?</h1>
                <p>Per trovarlo rapidamente sul tuo smartphone</p>
                <div className='d-flex justify-content-center'>
                    <img src={Installed} alt="Kangury installato" className='w-25 mb-3' />
                </div>
                <p>
                    Qui sotto puoi trovare le immagini che ti guideranno ad installare la web app su Android o Ios. Se nel tuo smartphone non dovessi trovare le opzioni fai una veloce ricerca su Google.
                </p>
                <h2 style={{ textAlign: 'center' , marginBottom: '10px'}}>Android</h2>
                <div className='d-flex justify-content-center flex-column align-items-center'>
                    <img src={Android1} alt="Android 1" className='w-75 mb-3' />
                    <img src={Android2} alt="Android 2" className='w-75 mb-3' />
                </div>
                <h2 style={{ textAlign: 'center' }}>Ios</h2>
                <div className='d-flex justify-content-center flex-column align-items-center'>
                    <img src={Ios1} alt="Ios 1" className='w-75 mb-3' />
                    <img src={Ios2} alt="Ios 2" className='w-75 mb-3' />
                </div>
            </div>
        </Page>
    );
}