import React from 'react';
import { Page } from '../../components';
import { getAllUrlParams } from '../../libs';

export function IframeReturn() {
    const { operation, action } = getAllUrlParams();
    React.useEffect(() => {
        // send operation to parent from iframe
        window.parent.postMessage(operation, '*');
        if(action) {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [operation]);

    return (
        <Page visibleBottomNavigation={false}>
            {/** Print window.location to string */}
            <pre>{JSON.stringify(window.location, null, 2)}</pre>
        </Page>
    );
}