export function Modal({
  title,
  text,
  primaryText,
  secondaryText,
  handleConfirm,
  primaryColor = "",
  id = "mainModal",
  notDismiss = false,
}) {
  return (
    <div
      className="modal fade z-1100"
      id={id}
      tabIndex="-1"
      aria-labelledby="mainModalLabel"
    >
      <div className="modal-dialog top-25">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title fw-bold" id="mainModalLabel">
              {title}
            </h5>
          </div>
          <div className="modal-body text-pre-line">{text}</div>
          <div className="modal-footer flex-column">
            {primaryText && <button
              type="button"
              className={`btn w-100 rounded-5 ${primaryColor ? primaryColor : "btn-danger "}`}
              onClick={handleConfirm}
              data-bs-dismiss={notDismiss ? "" : "modal"}
            >
              {primaryText}
            </button>}
            {secondaryText && <button
              type="button"
              className="btn w-100 rounded-5 btn-secondary"
              data-bs-dismiss="modal"
            >
              {secondaryText}
            </button>}
          </div>
        </div>
      </div>
    </div>
  );
}
