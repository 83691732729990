import React from 'react';
import "swiper/css";
import { Button } from '../../ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export function MediaSettings({ visible, handleClose, handleDelete, handleSetCover }) {

    return (
        <div className={`position-fixed bottom-0 start-0 w-100 h-100 bg-dark d-flex align-items-end z-1000 bg-opacity-50 ${visible ? 'visibility-visible' : 'visibility-invisible pe-none'}`}
            onClick={handleClose}>
            <div className={`bg-secondary bg-opacity-25 w-100 p-2 transition-base gap-1 rounded-top d-flex justify-content-start align-items-center flex-column pb-4 ${visible ? 'h-15' : ' h-0'}`} onClick={(e) => e.stopPropagation()}>
                <FontAwesomeIcon icon="fa-regular fa-dash" className="text-white" />
                <div className="d-flex gap-1 flex-column w-100 h-100 justify-content-space-around">
                    <Button onClick={handleSetCover}>
                        <FontAwesomeIcon icon="fa-regular fa-star" className={'me-2'} />
                        Imposta come foto copertina
                    </Button>
                    {/* <Button otherClass={"btn-secondary text-white"} onClick={handleButton}>
                    <FontAwesomeIcon icon="fa-regular fa-circle-info" className=" me-2 fs-3" />
                    Dettagli foto
                </Button> 
                <Button otherClass={"btn-secondary text-white"} onClick={handleButton}>
                    <FontAwesomeIcon icon="fa-regular fa-download" className="fs-3 me-2" />
                    Scarica originale
                </Button>
                <Button otherClass={"btn-secondary text-white"} onClick={handleButton}>
                    <FontAwesomeIcon icon="fa-regular fa-share-nodes" className=" me-2 fs-3" />
                    Condividi
                </Button> */}
                    <Button otherClass={"btn-danger text-white"} onClick={handleDelete}>
                        <FontAwesomeIcon icon="fa-regular fa-trash-can" className=" me-2 fs-3" />
                        Elimina
                    </Button>
                </div>
            </div>
        </div>
    );
}