import { Page, IFrame } from '../../components';
import { getAllUrlParams } from '../../libs';

export function ExternalLinks() {
    const { title, url } = getAllUrlParams();

    return (
        <Page visibleBottomNavigation={false}
            title={title}>
                <IFrame url={url} />
        </Page>
    );
}