import React from 'react';
import { LoadingSpinner } from '../../ui';
import Logo from '../../ui/assets/logo-text.png';

export const LoadingPage = ({ show }) => {
    const isHomePage = window.location.pathname === '/';
    const fade = 'pe-none ' + (isHomePage ? 'fade-slow' : 'fade');

    React.useEffect(() => {
        if (show) {
            const t = setTimeout(() => {
                // go back if loading page is still visible after 5 seconds
                window.history.back();
            }, 30000);
            return () => clearTimeout(t);
        }
    }, [show]);



    return (<div className={`vw-100 vh-100 d-flex flex-column justify-content-center align-items-center position-fixed z-1000 start-0 top-0 bg-tertiary ${show ? '' : fade}`}>
        <div className="w-75 text-center ps-4 pe-4" style={{maxWidth: '800px'}} >
            <img src={Logo} className="img-fluid pe-none" alt="Logo" unselectable="on" />
            
        </div>
        <div className={`${show ? '' : 'opacity-0'}`}>
            <LoadingSpinner />
        </div>
    </div>)
}