// Estimation of delivery date based on production days and courier
function estimateDeliveryDate(productionDays, courier, productionTimeData) {
    // Look for the standard delivery time in the couriers
    const deliveryDays = courier.standardDeliveryDays;
    // Go ahead in time subtracting the production hours until they run out, but only on working days
    let hourAmount = (productionDays * 24) + productionTimeData.elaborationHours + (deliveryDays * 24);
    let hourAmountOffset = Math.max(hourAmount / 100, 1);
    let endDate = new Date();
    while (hourAmount > 0) {
        endDate.setTime(endDate.getTime() + (hourAmountOffset * 60 * 60 * 1000));
        // eslint-disable-next-line eqeqeq
        if (!productionTimeData.nonWorkingDays.some(nwd => (nwd.dayOfWeek == null || nwd.dayOfWeek == endDate.getDay()) && (nwd.day == null || nwd.day == endDate.getDate()) && (nwd.month == null || nwd.month === (endDate.getMonth() + 1)) && (nwd.year == null || nwd.year === endDate.getFullYear())))
            hourAmount = Math.round((hourAmount - hourAmountOffset) * 100) / 100;
    }
    return endDate;
}

function getCourierLessDeliveryDays(couriers) {
    let minDeliveryDaysCourier = null;
    for (const courier of couriers) {
        if (minDeliveryDaysCourier == null ||
            courier.standardDeliveryDays < minDeliveryDaysCourier.standardDeliveryDays) {
            minDeliveryDaysCourier = courier;
        }
    }
    return minDeliveryDaysCourier;
}

function getProductionTime(groupedProductionTimes, productionTimeData, type = 'min') {
    let productionDays = null;
    for (const groupedProductionTime of groupedProductionTimes) {
        for (const productionTime of productionTimeData.productionTimes) {
            if (productionTime.id === groupedProductionTime.productionTime) {
                for (const range of productionTime.ranges) {
                    if ((range.minQuantity <= groupedProductionTime.quantity || !range.minQuantity) &&
                        (range.maxQuantity >= groupedProductionTime.quantity || !range.maxQuantity)) {
                        if (productionDays === null || (type === 'min' && productionDays > range.days) || (type === 'max' && productionDays < range.days))
                            productionDays = range.days;
                    }
                }
                break;
            }
        }
    }
    return productionDays;
}

export function estimateCartDeliveryDate(cartProducts, subCategoryDict, productionTimeData, couriers) {
    // collect all productionTime id for each product in the cart
    const groupedProductionTimes = [];
    for (let i = 0; i < cartProducts.length; i++) {
        const p = cartProducts[i];
        const product = subCategoryDict[p.subCategoryId]?.subProductDict[p.productId];
        if(!product) continue;
        const productionTime = product.productionTime;
        let quantity = p.countInCart;
        if (p.processingType === 1) {
            quantity = 0;
            p.imagesList.forEach(image => {
                quantity += image.quantity;
            });
        }
        if(p.processingType === 2) {
            quantity = quantity * p.countInCart;
        }
        if (!groupedProductionTimes.find(gpt => gpt.productionTime === productionTime)) {
            groupedProductionTimes.push({ productionTime, quantity }) 
        }
        else {
            // if present sum the quantity
            const index = groupedProductionTimes.findIndex(gpt => gpt.productionTime === productionTime);
            groupedProductionTimes[index].quantity += quantity;
        }
    }
    // Check the max production time
    const maxProductionDays = getProductionTime(groupedProductionTimes, productionTimeData, 'max');
    // Check the less delivery days
    let minDeliveryDaysCourier;
    if (couriers.id) {
        // single courier passed
        minDeliveryDaysCourier = couriers;
    } else {
        // array of couriers passed
        minDeliveryDaysCourier = getCourierLessDeliveryDays(couriers);
    }

    // Return the estimated day
    if (maxProductionDays == null) return null;
    else return estimateDeliveryDate(maxProductionDays, minDeliveryDaysCourier, productionTimeData);
}

export function estimateCategoryDeliveryDate(category, productionTimeData, couriers) {
    // Collect all productionTime id for each product in the category
    const products = category.subProductDict;
    const groupedProductionTimes = [];
    for (const p in products) {
        const product = products[p];
        const productionTime = product.productionTime;
        if (!groupedProductionTimes.find(gpt => gpt.productionTime === productionTime)) {
            groupedProductionTimes.push({ productionTime, quantity: 1 });
        }
    }
    // Check the less production time
    const minProductionDays = getProductionTime(groupedProductionTimes, productionTimeData);
    // Check the less delivery days
    const minDeliveryDaysCourier = getCourierLessDeliveryDays(couriers);

    // Return the estimated day
    if (minProductionDays == null) return null;
    else return estimateDeliveryDate(minProductionDays, minDeliveryDaysCourier, productionTimeData);
}