import React from 'react';
import { Button, FixedBottom } from '../../ui';

export function BottomButton({ onClick, content, contentUp, disabled=false, otherClass="" }) {

    return (
        <FixedBottom>
            {contentUp}
            <div className='pt-2 pb-2 ps-3 pe-3 w-100'>
                <Button onClick={onClick} otherClass={otherClass + (disabled ? " disabled": "")}>
                    {content}
                </Button>
            </div>
        </FixedBottom>
    );
}