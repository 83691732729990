import React from "react";
import { CardAlbum } from "..";

export function AlbumList({ albums, handleOpen, associate = false }) {

    const _handleOpen = (id) => {
        handleOpen(id);
    }

    const formatDate = (date) => {
        const dateObj = new Date(date);
        return dateObj.getDate() + '/' + (dateObj.getMonth() + 1) + '/' + dateObj.getFullYear();
    }

    return (
        <div className="row">
                    {albums && albums.map((album) => (
                        <div className="col-12 col-md-6 col-lg-4 mb-4" key={album.id}>
                            <CardAlbum
                                handleOpen={() => _handleOpen(album.id)}
                                title={album.name}
                                files={album.countFiles}
                                memoryUsed={album.memoryUsed / 1000000}
                                memoryTotal={album.memoryTotal / 1000000}
                                cover={album.latestFileUrl}
                                coverType={album.latestFileType}
                                associate={associate}
                                creationDate={formatDate(album.date_creation)}
                                nfc={album.nfc}
                            />
                        </div>
                    ))}
                </div>
    );
}