import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BottomButton, FormAddress, Page } from '../../components';
import { formatBillingAddress, getAllUrlParams } from '../../libs';
import { userActions } from '../../store/actions';
import { CardAddress, Modal, Button } from '../../ui';
import { privateFormData, vatFormData } from './formData';

const checkFields = (addressForm) => {
  if(addressForm.owner === 'private') {
    const { name, address, city, zipCode, fiscalCode, state } = addressForm;
    return name && address && city && zipCode && fiscalCode && (state || !['IT', 'ES'].includes(addressForm.country));
  } else {
    const { name, address, city, zipCode, fiscalCode, vatNumber, state, recipientCode, pec } = addressForm;
    return name && address && city && zipCode && fiscalCode && vatNumber && (state || !['IT', 'ES'].includes(addressForm.country)) && (recipientCode || pec);
  }
}

const initAddressForm = {
  owner: 'vat',
  country: 'IT',
  countryLabel: 'Italia',
  name: '',
  address: '',
  city: '',
  state: '',
  zipCode: '',
  vatNumber: '',
  fiscalCode: '',
  vatCountry: 'IT',
}

export function BillingAddresses() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { index, check } = getAllUrlParams();
  const {billingAddresses} = useSelector((state) => state.userData);
  const [addressForm, setAddressForm] = React.useState(billingAddresses && index !== undefined && index !== '-1' ? billingAddresses[index] : initAddressForm);
  const [showError, setShowError] = React.useState(false);
  const [deleteIndex, setDeleteIndex] = React.useState(null);

  React.useEffect(() => {
    dispatch(userActions.getBillingAddresses());
  }, [dispatch]);

  React.useEffect(() => {
    if (billingAddresses && index !== undefined && index !== '-1')
      setAddressForm(billingAddresses[index]);
  }, [billingAddresses, index]);

  const handleManageAddress = (_index) => {
    if (index === undefined) {
      // List page
      if (_index || _index === 0) {
        navigate(`/billing-addresses?index=${_index}`);
        setAddressForm(billingAddresses[_index]);
      }
      else {
        navigate('/billing-addresses?index=-1');
        setAddressForm(initAddressForm)
      }
    } else {
      // Form page
      if (checkFields(addressForm)) {
        setShowError(false);
        if (_index === '-1')
          dispatch(userActions.addBillingAddress(addressForm));
        else
          dispatch(userActions.updateBillingAddress(addressForm, _index));
        window.history.back()
      }
      else
        setShowError(true);
    }
  };

  const handleSetDefault = (index) => {
    dispatch(userActions.setDefaultBillingAddress(index));
  };

  const handleDelete = () => {
    if (deleteIndex !== null)
      dispatch(userActions.removeBillingAddress(deleteIndex));
  };

  const formSchema = React.useMemo(() => {
    if(!addressForm) return [];
    if (addressForm.owner === 'private') {
      setAddressForm((prev) => {
        return {
          ...prev,
          vatNumber: '',
          pec: '',
          recipientCode: '',
        }
      })
      return privateFormData;
    }
    return vatFormData;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressForm?.owner]);

  const title = React.useMemo(() => {
    if (index === undefined) {
      return 'Indirizzi di Fatturazione';
    }
    if (index === '-1') {
      return 'Nuovo indirizzo';
    }
    return 'Modifica indirizzo';
  }, [index]);

  if((billingAddresses === null || billingAddresses.length === 0) && index === undefined) {
    return (
      <Page
        title={title}
        visibleBottomNavigation={false}
        >
          <div className='text-center w-100 h-100 d-flex flex-column justify-content-center align-items-center p-4'>
            <div className='mb-1 fs-4 fw-bold'>Non hai ancora aggiunto alcun indirizzo</div>
            <div className='mb-3 fs-6'>
              A che indirizzo vuoi intestare la fattura?
            </div>
            <Button otherClass="p-1" onClick={() => handleManageAddress(index)}>
                <span className="text-dark fs-5">
                    <FontAwesomeIcon icon="fa-regular fa-plus" className='me-2' />
                    Aggiungi un indirizzo
                </span>
            </Button>
        </div>
        </Page>
    )
  }

  return (
    <Page
      title={title}
      visibleBottomNavigation={index || check ? true : false}
      customBottomSection={
        index ? <BottomButton onClick={() => handleManageAddress(index)} content={
            <React.Fragment>
                <FontAwesomeIcon
                    icon="fa-regular fa-floppy-disk" className="me-2" />
                Salva Indirizzo
            </React.Fragment>
        } />
            : (
                check && <BottomButton onClick={() => window.history.back()}
                    content={
                        <React.Fragment>
                            Conferma
                        </React.Fragment>
                    } />
            )
    }>
      {index === undefined ? billingAddresses?.map((address, index) => {
        let rows = formatBillingAddress(address);
        if(address.vatNumber) {
          rows.splice(1, 0, `P.IVA: ${address.vatNumber}`);
        }
        return <CardAddress
          key={index}
          title={address.name}
          rows={rows}
          note={address.courierNotes}
          favorite={address.isDefault}
          icon={<FontAwesomeIcon icon="fa-regular fa-money-check-dollar-pen" size='2x' className={address.isDefault ? 'text-primary' : ''} />}
          handleEdit={() => handleManageAddress(index)}
          handleSetDefault={() => handleSetDefault(index)}
          handleDelete={() => setDeleteIndex(index)}
          check={check && address.isDefault}
          globalSetDefault={check}
        />
      })
        : addressForm && formSchema && <FormAddress
          addressForm={addressForm}
          showError={showError}
          setAddressForm={setAddressForm}
          formSchema={formSchema}
        />
      }
      {index === undefined && <div className="w-100 ps-3 pe-3">
                <Button onClick={() => handleManageAddress()} otherClass={"btn-secondary"}>
                    <FontAwesomeIcon
                        icon="fa-regular fa-circle-plus" className="me-2" />
                    Nuovo Indirizzo
                </Button>
            </div>}
      <Modal
        title={"Eliminazione Indirizzo di Spedizione"}
        text={"Vuoi eliminare l'indirizzo di spedizione? Questa operazione non può essere annullata."}
        primaryText="Conferma"
        secondaryText="Annulla"
        handleConfirm={handleDelete}
        id="addressModal"
      />
    </Page>
  );
}