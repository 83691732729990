import React from 'react';
import { LoadingSpinner } from '..';

export function ProgressbarCircle({ percentage, delay = true }) {
    const [localPercentage, setLocalPercentage] = React.useState(0);

    React.useEffect(() => {
        if (percentage === 0) setLocalPercentage(0);
        if (localPercentage < percentage) {
            if (delay)
                setTimeout(() => {
                    setLocalPercentage(localPercentage + 1);
                }, 10);
            else setLocalPercentage(localPercentage + 1);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localPercentage, percentage]);

    const color = localPercentage <= 50 ? '#adb5bd' : '#FFBC0D';
    let deg = localPercentage <= 50 ? -90 + (localPercentage * 180 / 50) : -89 + ((localPercentage - 50) * 180 / 50);
    if (localPercentage === 100) deg = 90;

    return (
        <div className="rounded-circle d-flex bg-white shadow" style={{
            background: `linear-gradient(${deg}deg, ${color} 50%, transparent 50%), linear-gradient(90deg, #adb5bd 50%, #FFBC0D 50%)`, width: '200px', height: '200px'
        }}>
            <h1 className="d-flex justify-content-center align-items-center m-auto bg-white rounded-circle" style={{ width: '150px', height: '150px' }}>
            {localPercentage < 100 && <LoadingSpinner />}
            </h1>
        </div>
    );
}
