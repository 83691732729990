export function ToastInfo({ children, warningImageFormat = false }) {

    const standardFormat = "toast  d-block w-100 shadow-none"
    const styleImageFormat = "toast bg-primary d-flex w-75 shadow-none position-fixed top-0 mt-5 translate-middle-x start-50 opacity-75 fw-bold";
    return (
        <div className={`z-1000 ${warningImageFormat ? styleImageFormat : standardFormat}`} role="alert" aria-live="assertive" aria-atomic="true">
            <div className="toast-body text-center p-1 w-100">
                {children}
            </div>
        </div>
    );
}
