import React from "react";
import { Modal } from "../../ui";

export const Modals = ({ name, onChangeNameAlbum, confirmChangeName, confirmRemoveAlbum, password, onChangePassword, passwordError }) => {
    return (
        <>
            <Modal
                title={"Cambia nome all'album"}
                text={<input type="text" className="form-control" placeholder="Nome album" value={name} onChange={onChangeNameAlbum} />}
                primaryText="Salva"
                secondaryText="Annulla"
                handleConfirm={confirmChangeName}
                primaryColor="btn-primary"
                id="changeName"
            />

            <Modal
                title={"Sei sicuro di voler eliminare l'album?"}
                text={
                    <>
                        <div>
                            <p>Questa azione è irreversibile e non potrai più usare la tua smartphoto con Kangury.</p>
                            <p>Inserisci la tua password per confermare l'eliminazione.</p>
                        </div>
                        <input type="password" className="form-control" placeholder="password" value={password}
                            onChange={onChangePassword} />
                        {passwordError && <div className="text-danger">Password errata</div>}
                    </>
                }
                primaryText="Conferma eliminazione"
                secondaryText="Annulla"
                handleConfirm={confirmRemoveAlbum}
                id="removeAlbum"
                notDismiss={true}
            />
        </>
    )
}