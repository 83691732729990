import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/core';
import "swiper/css";
import { PlaceholdersLoaderGif, ImgWithPlaceholder, VideoWithPlaceholder, Button } from '../../ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export function MediaCarousel({ media, initialSlide, handleClose, handleSettings, onlyReadAlbum }) {
    const [actualIndex, setActualIndex] = React.useState(initialSlide);
    const swiperRef = React.useRef(null);

    const _handleSettings = () => {
        handleSettings(actualIndex);
    }

    return (
        <div className="position-fixed top-0 start-0 w-100 h-100 bg-tertiary p-2 pt-5 pb-5 d-flex align-items-center justify-content-center z-1000">
            <Swiper
                centeredSlides={true}
                pagination={{
                    clickable: true,
                    dynamicBullets: true,
                }}
                navigation={{nextEl: '.arrow-right', prevEl: '.arrow-left'}}
                modules={[Pagination, Navigation]}
                className="mySwiper h-100"
                initialSlide={initialSlide}
                onSlideChangeTransitionEnd={(swiper) => setActualIndex(swiper.activeIndex)}
                ref={swiperRef}
            >

                {media.map((item, index) => (
                    <SwiperSlide key={index}>
                        <div className='d-flex justify-content-center align-items-center h-100'>
                            {
                                item.type.includes('image') &&
                                <ImgWithPlaceholder
                                    src={item.url}
                                    className="d-block img-fluid pe-none mw-100 mh-100" alt={'image'}
                                    unselectable="on"
                                    placeholder={PlaceholdersLoaderGif.loader_1200_1200}
                                />}
                            {
                                item.type.includes('video') &&
                                <VideoWithPlaceholder
                                    src={item.url}
                                    className="h-100 w-auto d-block img-fluid"
                                    alt={'video'}
                                    autoPlay={true} 
                                    controls={true}
                                    isCurrent={index === actualIndex}
                                    isCarousel={true}
                                />}
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
            <button type="button" className="btn rounded-5 text-dark fs-6 btn-primary position-absolute bottom-0 start-0 mb-4 ms-2 z-1 arrow-left">
                <FontAwesomeIcon icon="fa-regular fa-chevron-left" />
            </button>
            <button type="button" className="btn rounded-5 text-dark fs-6 btn-primary position-absolute bottom-0 end-0 mb-4 me-2 z-1 arrow-right">
                <FontAwesomeIcon icon="fa-regular fa-chevron-right" />
            </button>
            <FontAwesomeIcon icon="fa-regular fa-x" onClick={handleClose} className="position-absolute top-0 start-0 m-2 text-primary p-2 fs-4 cursor-pointer z-1" />
            {!onlyReadAlbum && <FontAwesomeIcon icon="fa-regular fa-gear" onClick={_handleSettings} className="position-absolute top-0 end-0 m-2 text-primary p-2 fs-4 cursor-pointer z-1" />}
        </div>
    );
}