export function ListSelectionsCard({ list }) {
    const id = Date.now();
    
    return (
        <div className="mt-3 p-3 row">
            {list.map((item, index) => (
                <div className={`rounded-0 border-0 col-12 col-md-6 col-lg-4 mb-3`} 
                key={`${id}-${index}`}>
                    {item}
                </div>
            ))}
        </div>
    );
}