/**
 * Services to manage images in the DigitalPix API when user did an order
 */
import { common } from "./common.service"

export const imageService = {
    get,
    post,
    put
}

async function get(id, fingerprint) {
    let url = `image/${id}/preview?fingerprint=${fingerprint}`;
    return common.fetchAPI({ url, method: "GET", jsonResponse: false, imgResponse: true, useDigitalPixAPI: true });
}

async function post(data) {
    let url = `image`;
    return common.fetchAPI({ url, method: "POST", data, useDigitalPixAPI: true });
}

async function put(idImage, data) {
    let url = `image/${idImage}/data`;
    return common.fetchAPI({ url, method: "PUT", data, useDigitalPixAPI: true  });
}