import React from 'react';
import { Modal } from '../../ui';
import { useCallback } from 'react';

export const ProductModals = ({ imagesList }) => {
    const speedActive = localStorage.getItem('speedActive') === 'false' ? false : true;

    const changeSpeedActiveMode = useCallback(() => {
        localStorage.setItem('speedActive', !speedActive);
        window.location.reload();
    }, [speedActive]);

    return (<React.Fragment>
        <Modal
            title={"Ci sono delle foto ritagliate"}
            text={`Alcune foto non si adattano al formato scelto e sono state contrassegnate da un icona con delle forbici. \nAprile e controlla che non siano state tagliate parti importanti`}
            id="scissorsModal"
            secondaryText={"Chiudi"}
        />
        <Modal
            title={"Risoluzione insufficiente"}
            text={`Alcune foto non hanno una risoluzione ottimale per essere stampate in questo modello scelto.\nTi consigliamo di usare una foto con risoluzione più alta o non potremo garantirti un risultato soddisfacente.\nIl problema potrebbe essere causato anche da uno zoom eccessivo per la qualità dell'immagine`}
            id="resolutionModal"
            secondaryText={"Ignora e prosegui"}
        />
        <Modal
            title={"Attiva caricamento rapido"}
            text={<div className='text-center'>
                {imagesList.length > 0 && <div className='text-danger fw-bold fs-4'>Attenzione: attivando questa modalità ricaricherai la pagina rimuovendo le foto selezionate</div>}
                <div className='fw-bold'>Ideale per il caricamento di molte foto o per smartphone datati</div>
                <div>
                    <em>Questa modalità ti fara selezionare le foto dalla tua galleria nativa, potrebbe essere meno intuitiva ma renderà il processo di caricamento più rapido.</em>
                </div>
            </div>}
            id="activeSpeedModal"
            primaryText={"Attiva e ricarica la pagina"}
            handleConfirm={changeSpeedActiveMode}
            primaryColor='btn-primary'
            secondaryText={"Annulla"}
        />
        <Modal
            title={"Disattiva caricamento rapido"}
            text={<div className='text-center'>
                {imagesList.length > 0 && <div className='text-danger fw-bold fs-4'>Attenzione: disattivando questa modalità ricaricherai la pagina rimuovendo le foto selezionate</div>}
                <div className='fw-bold'>Ideale per il caricamento di poche foto o per accedere a Google Photo.</div>
                <div><em>Questa modalità organizzerà le tue foto in cartelle, il procedimento potrebbe essere più lento ma più intuitivo.</em></div>
            </div>}
            id="disactiveSpeedModal"
            primaryText={"Disattiva e ricarica la pagina"}
            handleConfirm={changeSpeedActiveMode}
            primaryColor='btn-primary'
            secondaryText={"Annulla"}
        />
    </React.Fragment>)
}