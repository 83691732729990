import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Page } from '../../components';
import { userService } from '../../store/services';
import { Button, PasswordField, ToastDanger } from '../../ui';
import { getAllUrlParams } from '../../libs';

export function ResetPassword() {
    const { uid, token } = getAllUrlParams();
    const navigate = useNavigate();
    const [showToastError, setShowToastError] = React.useState(false);
    const [showFinalMessage, setShowFinalMessage] = React.useState(false);
    const [password, setPassword] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');

    const handleConfirm = () => {
        if(password.length < 6) {
            setShowToastError(1);
            return;
        }
        if(password !== confirmPassword) {
            setShowToastError(2);
            return;
        }
        if (uid && token) {
            userService.resetPassword(password, uid, token).then(data => {
                if (data.status) {
                    setShowToastError(3);
                    return;
                }
                setShowFinalMessage(true);
            })
        }
    }

    const goToLogin = () => {
        navigate(`/login`);
    }

    return (
        <Page visibleBottomNavigation={false}
            title={"Recupera Password"}>
            {!showFinalMessage ?
                <div className='container p-3'>
                    {showToastError && <ToastDanger>
                        {errorCode[showToastError]}
                    </ToastDanger>}
                    <PasswordField placeholder={"Nuova Password"} value={password} setValue={setPassword} />
                    <PasswordField placeholder={"Conferma Password"} value={confirmPassword} setValue={setConfirmPassword} />
                    <Button onClick={handleConfirm} otherClass={"mt-3 p-1"}>
                        Conferma
                    </Button>
                </div>
                :
                <div className='container p-3 h-100 d-flex flex-column justify-content-center align-items-center'>
                    <div className='text-center fs-2 fw-bold'>
                        Password cambiata
                    </div>
                    <div className='text-center fs-5 mt-4'>
                        Vai al <span className='text-decoration-underline cursor-pointer text-primary fw-bold' onClick={goToLogin}>Login</span>.
                    </div>
                </div>}
        </Page>
    );
}

const errorCode = {
    1: 'Password troppo corta',
    2: 'Le password non corrispondono',
    3: 'Errore durante il cambio password, token non valido'
}