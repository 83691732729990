import { localDB } from "../cache";

export const cartConstants = {
  ADD: "CART_ADD_PRODUCT",
  REMOVE: "CART_REMOVE_PRODUCT",
  REMOVE_ALL: "CART_REMOVE_ALL_PRODUCTS",
  ADD_COUNT: "CART_ADD_COUNT_PRODUCT",
  REMOVE_COUNT: "CART_REMOVE_COUNT_PRODUCT",
  GET: "CART_GET_PRODUCTS",
  APPLY_DISCOUNT: "CART_APPLY_DISCOUNT",
  CLEAN_DISCOUNT: "CART_CLEAN_DISCOUNT",
  UPDATE_CART_IMAGE: "CART_UPDATE_CART_IMAGE",
  UPDATE_PRODUCT_PRICE: "CART_UPDATE_PRODUCT_PRICE",
};

function add(product, oldId) {
  return (dispatch) => {
    dispatch({ type: cartConstants.ADD, product, oldId });
  };
}

function remove(indexProduct) {
  return (dispatch) => {
    dispatch({ type: cartConstants.REMOVE, indexProduct });
  };
}

function removeAll() {
  return (dispatch) => {
    dispatch({ type: cartConstants.REMOVE_ALL });
  };
}

function addCount(indexProduct) {
  return (dispatch) => {
    dispatch({ type: cartConstants.ADD_COUNT, indexProduct });
  };
}

function removeCount(indexProduct) {
  return (dispatch) => {
    dispatch({ type: cartConstants.REMOVE_COUNT, indexProduct });
  };
}

function get() {
  return (dispatch) => {
    localDB.get("cart").then((products) => {
      dispatch({ type: cartConstants.GET, products });
    });
  };
}

function applyDiscount(discount) {
  return (dispatch) => {
    dispatch({ type: cartConstants.APPLY_DISCOUNT, discount });
  };
}

function cleanDiscount() {
  return (dispatch) => {
    dispatch({ type: cartConstants.APPLY_DISCOUNT });
  };
}

function updateCartImage(idImage, newUrl) {
  return (dispatch) => {
    dispatch({ type: cartConstants.UPDATE_CART_IMAGE, idImage, newUrl });
  };
}

function updateProductPrice(productId, prices) {
  return (dispatch) => {
    dispatch({ type: cartConstants.UPDATE_PRODUCT_PRICE, productId, prices });
  };
}

export const cartActions = {
  add,
  remove,
  removeAll,
  addCount,
  removeCount,
  get,
  applyDiscount,
  cleanDiscount,
  updateCartImage,
  updateProductPrice,
};
