import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Page } from '../../components';
import { useSelector, useDispatch } from 'react-redux';
import { productDataActions } from '../../store/actions';

export function WaitExitProduct() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { imagesList } = useSelector(state => state.productData);
    const search = window.location.search;
    const [show, setShow] = React.useState(false);


    React.useEffect(() => {
        const previousPage = localStorage.getItem('previousPage');
        if(previousPage !== '/product') {
            navigate(`/product${search}`);
        }
        const atLeastOneImage = imagesList.find(image => image.image || image.originalImage);
        if(previousPage === '/product' && !atLeastOneImage) {
            window.history.back();
        }   
        setTimeout(() => {
            setShow(true);
        }, 100);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleConfirm = () => {
        dispatch(productDataActions.clean());
        window.history.back();
    }

    const handleCancel = () => {
        navigate(`/product${search}`);
    }

    return (
        <Page customBottomSection={<div></div>}>
            {show && <div className="d-flex flex-column align-items-center justify-content-center h-100 w-100 p-4 text-center">
                <h1>Sei sicuro di voler uscire dalla creazione? </h1>
                <h3>Sembra che ci siano delle modifiche non salvate, tornando indietro verranno perse. Vuoi uscire comunque?</h3>
                <div className="d-flex flex-row align-items-center justify-content-center">
                    <button
                        type="button"
                        className={`btn w-100 rounded-5 fw-bold btn-danger me-4`}
                        onClick={handleConfirm}
                        data-bs-dismiss="modal"
                    >
                        Conferma
                    </button>
                    <button
                        type="button"
                        className="btn w-100 rounded-5 btn-secondary fw-bold"
                        onClick={handleCancel}
                        data-bs-dismiss="modal"
                    >
                        Annulla
                    </button>
                </div>
            </div>}
        </Page>
    );
}