import { userConstants } from "../actions";
import { localDB } from "../cache";

const initialState = {
  shippingAddresses: [],
  billingAddresses: [],
};

const userData = (state = initialState, action) => {
  let draft = {
    ...state,
  };
  switch (action.type) {
    case userConstants.ADD_SHIPPING_ADDRESS:
      const newAddressS = draft.shippingAddresses.length === 0 ? { ...action.address, isDefault: true } : { ...action.address, isDefault: false};
      draft.shippingAddresses.push(newAddressS);
      localDB.set("shippingAddresses", draft.shippingAddresses);
      break;
    case userConstants.GET_SHIPPING_ADDRESS:
      if (action.addresses) {
        draft.shippingAddresses = action.addresses;
      }
      break;
    case userConstants.REMOVE_SHIPPING_ADDRESS:
      draft.shippingAddresses.splice(action.index, 1);
      // if there at least one address, set the first one as default
      if (draft.shippingAddresses.length > 0) {
        draft.shippingAddresses[0].isDefault = true;
      }
      localDB.set("shippingAddresses", draft.shippingAddresses);
      break;
    case userConstants.UPDATE_SHIPPING_ADDRESS:
      draft.shippingAddresses[action.index] = action.address;
      localDB.set("shippingAddresses", draft.shippingAddresses);
      break;
    case userConstants.SET_DEFAULT_SHIPPING_ADDRESS:
      draft.shippingAddresses.forEach((address) => {
        address.isDefault = false;
      });
      draft.shippingAddresses[action.index].isDefault = true;
      localDB.set("shippingAddresses", draft.shippingAddresses);
      break;

    case userConstants.ADD_BILLING_ADDRESS:
      const newAddressB = draft.billingAddresses.length === 0 ? { ...action.address, isDefault: true } : { ...action.address, isDefault: false};
      draft.billingAddresses.push(newAddressB);
      localDB.set("billingAddresses", draft.billingAddresses);
      break;
    case userConstants.GET_BILLING_ADDRESS:
      if (action.addresses) {
        draft.billingAddresses = action.addresses;
      }
      break;
    case userConstants.REMOVE_BILLING_ADDRESS:
      draft.billingAddresses.splice(action.index, 1);
      // if there at least one address, set the first one as default
      if (draft.billingAddresses.length > 0) {
        draft.billingAddresses[0].isDefault = true;
      }
      localDB.set("billingAddresses", draft.billingAddresses);
      break;
    case userConstants.UPDATE_BILLING_ADDRESS:
      draft.billingAddresses[action.index] = action.address;
      localDB.set("billingAddresses", draft.billingAddresses);
      break;
    case userConstants.SET_DEFAULT_BILLING_ADDRESS:
      draft.billingAddresses.forEach((address) => {
        address.isDefault = false;
      });
      draft.billingAddresses[action.index].isDefault = true;
      localDB.set("billingAddresses", draft.billingAddresses);
      break;
    case userConstants.REMOVE_ALL_ADDRESS:
      draft.shippingAddresses = [];
      draft.billingAddresses = [];
      localDB.set("shippingAddresses", draft.shippingAddresses);
      localDB.set("billingAddresses", draft.billingAddresses);
      break;
    case userConstants.GET_INFO:
      draft.userInfo = action.data;
      break;
    default:
      break;
  }
  return draft;
}

export default userData;
