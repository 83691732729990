import { mainDataService } from "../services";
import { localDB } from "../cache";
import { cartActions } from "./cart.action";

export const mainDataConstants = {
  GET_REQUEST: "MAIN_DATA_GET_REQUEST",
  GET_SUCCESS: "MAIN_DATA_GET_SUCCESS",
  GET_ERROR: "MAIN_DATA_GET_ERROR",
  ADD_CATALOG_DIFFERENCES: "ADD_CATALOG_DIFFERENCES",
  UPDATE_CATALOG_DIFFERENCES: "UPDATE_CATALOG_DIFFERENCES",
};

function _printError(dispatch, failure, replyError) {
  console.error("ERROR:" + replyError);
}

/**
 * @param {*} subCategory
 * @returns Smallest price from the subCategory passed
 */
const smallestPriceFromSubCategory = (subCategory) => {
  const price = {
    price: -1,
    originalPrice: -1,
  };
  subCategory.subProducts?.forEach((subProd) => {
    subProd.kanguryPrices.forEach((p) => {
      if (price.price === -1 || p.price < price.price) {
        price.price = p.price.toFixed(2);
        price.originalPrice =
          p.originalPrice !== p.price ? p.originalPrice.toFixed(2) : null;
      }
    });
  });
  return price;
};

const extractSubProducts = (subCategory) => {
  // id subCategory: [subProducts]
  const subProductDict = {};
  subCategory.subProducts?.forEach((subProd) => {
    subProductDict[subProd.id] = subProd;
    subProductDict[subProd.id].selectionValues = subProd.iD_SelectionValues;
    subProductDict[subProd.id].productionTime = subProd.iD_productionTime;
    if (subProductDict[subProd.id].selectionValues)
      subProductDict[subProd.id].selectionValues = subProductDict[
        subProd.id
      ].selectionValues
        .sort()
        .join(";");
    delete subProductDict[subProd.id].iD_SelectionValues;
    delete subProductDict[subProd.id].iD_productionTime;
  });
  return {
    _subProductDict: subProductDict,
  };
};

/**
 * Given a subCategory return a dict of all selections in the subCategory
 * and the IDs of selections ordered.
 * @param {*} subCategory
 * @returns
 */
const extractSelections = (subCategory) => {
  const selectionDict = {};
  const selectionIDs = [];

  const insertSelectionInOrder = (_selectionDict, selection) => {
    let index = -1;
    selectionIDs.forEach((id, i) => {
      if (_selectionDict[id].displayOrder > selection.displayOrder) {
        index = i;
        return;
      }
    });
    if (index === -1) {
      selectionIDs.push(selection.id);
    } else {
      selectionIDs.splice(index, 0, selection.id);
    }
  };

  subCategory.selections?.forEach((sel) => {
    const selection = {};
    selection.id = sel.id;
    selection.name = sel.name;
    selection.values = sel.selectionValues;
    selection.displayOrder = sel.displayOrder;
    selection.type = sel.type;
    selectionDict[sel.id] = selection;
    insertSelectionInOrder(selectionDict, sel);
  });
  return { _selectionDict: selectionDict, _selectionIDs: selectionIDs };
};


const CATEGORIES_ALLOWED = [300345, 300348]
const SUB_CATEGORY_ALLOWED = [300347, 300349, 300422]

/**
 * @param {*} category
 * @returns Dictionary in the form of {categoryID: [list of subCategories]}
 */
const extractSubCategories = (category) => {
  // id category: [subCategories]
  const subCategoryDict = {};
  const subCategoryIDs = [];
  const trendingSubCategoryIDs = [];
  const suggestedSubCategoryIDs = [];
  category.subCategories.forEach((subCat) => {
    if (SUB_CATEGORY_ALLOWED.includes(subCat.id)) {
      const { _selectionDict, _selectionIDs } = extractSelections(subCat);
      const { _subProductDict } = extractSubProducts(subCat);
      subCategoryDict[subCat.id] = {
        name: subCat.name,
        image: subCat.images ? subCat.images[0].url : null,
        images: subCat.images ? subCat.images
          .filter((img) => img.type === 2)
          .map((img) => img.url) : null,
        price: smallestPriceFromSubCategory(subCat),
        description: subCat.description,
        selectionIDs: _selectionIDs,
        subProductDict: _subProductDict,
        selectionDict: _selectionDict,
        tags: subCat.tags,
        layoutType: subCat.layoutType,
        faqs: subCat.faqs,
        externalEditUrl: subCat.externalEditUrl,
      };
      subCategoryIDs.push(subCat.id);
      if (subCat.isTrending) trendingSubCategoryIDs.push(subCat.id);
      if (subCat.isSuggested) suggestedSubCategoryIDs.push(subCat.id);
    }
  });
  return {
    _subCategoryDict: subCategoryDict,
    subCategoryIDs,
    _trendingSubCategoryIDs: trendingSubCategoryIDs,
    _suggestedSubCategoryIDs: suggestedSubCategoryIDs,
  };
};

/**
 * @param {*} data
 * @returns List of all categories
 */
const extractCategories = (data) => {
  const categoryDict = {};
  const categoryIDs = [];
  const trendingSubCategoryIDs = [];
  const suggestedSubCategoryIDs = [];
  let subCategoryDict = {};
  let selectionDict = {};
  data.categories.forEach((cat) => {
    if (CATEGORIES_ALLOWED.includes(cat.id)) {
      const {
        _subCategoryDict,
        subCategoryIDs,
        _trendingSubCategoryIDs,
        _suggestedSubCategoryIDs,
      } = extractSubCategories(cat);
      // console.log("subCategories", _subCategoryDict)
      // console.log("subCategoryIDs", subCategoryIDs)
      // console.log("_trendingSubCategoryIDs", _trendingSubCategoryIDs)
      // console.log("_suggestedSubCategoryIDs", _suggestedSubCategoryIDs)
      // console.log("_selectionDict", _selectionDict)
      const category = {};
      category.id = cat.id;
      category.name = cat.name;
      category.image = cat.images[0].url;
      category.subCategoryIDs = subCategoryIDs;
      subCategoryDict = { ...subCategoryDict, ..._subCategoryDict };
      categoryDict[cat.id] = category;
      categoryIDs.push(cat.id);
      trendingSubCategoryIDs.push(..._trendingSubCategoryIDs);
      suggestedSubCategoryIDs.push(..._suggestedSubCategoryIDs);
    }
  });
  const general = {
    application: data.application,
    locale: data.locale,
    version: data.version,
    generateDate: data.generateDate,
  };
  const productionTimeData = data.productionTimeData;
  const homeSections = data.homeSections;
  return {
    categoryDict,
    categoryIDs,
    subCategoryDict,
    trendingSubCategoryIDs,
    suggestedSubCategoryIDs,
    selectionDict,
    couriers: data.couriers,
    courierKanguryPrice: data.courierKanguryPrice,
    productionTimeData,
    homeSections,
    general,
  };
};

function _checkDifferences(cart, newCatalog, dispatch) {
  const productsNotPresentAnymore = [];
  const propertyNotPresentAnymore = [];
  cart.forEach(c => {
    // update price of product in cart
    const productNew = newCatalog.subCategoryDict[c.subCategoryId]?.subProductDict[c.productId];
    if (productNew)
      dispatch(cartActions.updateProductPrice(c.productId, productNew.prices))
    // check properties if necessary
    if (!c.propertiesId) return; // go next cart item
    if (!productNew) {
      productsNotPresentAnymore.push(c.productId);
    } else {
      // Check if some properties are not present anymore
      if (c.properties) {
        const propertiesChoosen = c.properties.split(";").map(p => parseInt(p));
        const propertiesIdChoosen = c.propertiesId.split(";").map(p => parseInt(p));
        const propertiesPresent = propertiesChoosen.map(p => false);
        productNew.properties.forEach(p => {
          p.propertyValues.forEach((pv) => {
            if (propertiesChoosen.includes(pv.id)) {
              propertiesPresent[propertiesChoosen.indexOf(pv.id)] = true;
            }
          });
        });
        // put in propertyNotPresentAnymore the properties that are not present anymore in format {id, value}
        propertiesPresent.forEach((p, i) => {
          if (!p) {
            const propertyValue = propertiesChoosen[i]
            const propertyId = propertiesIdChoosen[i]
            const property = productNew.properties.find(p => p.id === propertyId)
            if (property.editInEditor) {
              propertyNotPresentAnymore.push({ id: propertyId, value: propertyValue });
            }
            else {
              // if properties are not editable, remove from the cart
              productsNotPresentAnymore.push(c.productId);
            }
          }
        });
      }
    }
  });
  if (productsNotPresentAnymore.length > 0 || propertyNotPresentAnymore.length > 0) {
    dispatch({ type: mainDataConstants.ADD_CATALOG_DIFFERENCES, productsNotPresentAnymore, propertyNotPresentAnymore });
  }
}

function get() {
  const callAPI = ({ dispatch, version = -1 }) => {
    mainDataService.get(version).then(
      async (data) => {
        const subCategoryDict = await localDB.get("subCategoryDict");
        if (data?.version) {
          const cart = await localDB.get("cart");
          const categories = extractCategories(data)
          if (cart?.length > 0) {
            _checkDifferences(cart, categories, dispatch)
          }
          dispatch(success({ ...categories, save: true }));
        } else {
          const categoryIDs = await localDB.get("categoryIDs");
          const categoryDict = await localDB.get("categoryDict");
          const trendingSubCategoryIDs = await localDB.get("trendingSubCategoryIDs");
          const suggestedSubCategoryIDs = await localDB.get("suggestedSubCategoryIDs");
          const selectionDict = await localDB.get("selectionDict");
          const couriers = await localDB.get("couriers");
          const productionTimeData = await localDB.get("productionTimeData");
          const homeSections = await localDB.get("homeSections");
          const general = await localDB.get("general");
          const productsNotPresentAnymore = await localDB.get("productsNotPresentAnymore");
          const propertyNotPresentAnymore = await localDB.get("propertyNotPresentAnymore");
          dispatch(
            success({
              categoryDict,
              categoryIDs,
              subCategoryDict,
              trendingSubCategoryIDs,
              suggestedSubCategoryIDs,
              selectionDict,
              couriers,
              productionTimeData,
              homeSections,
              general,
              productsNotPresentAnymore,
              propertyNotPresentAnymore,
              save: false,
            })
          );
        }
      },
      (error) => {
        dispatch(failure(error));
        _printError(dispatch, failure, error);
      }
    );
  };
  return (dispatch) => {
    dispatch(request());
    localDB
      .get("general")
      .then(async (general) => {
        if (general) {
          callAPI({ dispatch, version: general.version });
        } else callAPI({ dispatch });
      })
      .catch((error) => {
        callAPI({ dispatch });
      });
  };

  function request() {
    return { type: mainDataConstants.GET_REQUEST };
  }
  function success(data) {
    return { type: mainDataConstants.GET_SUCCESS, data };
  }
  function failure(error) {
    return { type: mainDataConstants.GET_ERROR, error };
  }
}

export const mainDataActions = {
  get,
};