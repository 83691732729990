import { mainDataConstants } from "../actions";
import { localDB } from "../cache";

const initialState = {
  categories: [],
};

const mainData = (state = initialState, action) => {
  let draft = {
    ...state,
  };
  switch (action.type) {
    case mainDataConstants.GET_SUCCESS:
      for (let key in action.data) {
        if (key !== "save") {
          draft[key] = action.data[key];
          if (action.data.save)
            localDB.set(key, action.data[key]);
        }
      }
      draft.isReady = true;
      break;
    case mainDataConstants.ADD_CATALOG_DIFFERENCES:
      draft.productsNotPresentAnymore = action.productsNotPresentAnymore;
      draft.propertyNotPresentAnymore = action.propertyNotPresentAnymore;
      localDB.set("productsNotPresentAnymore", action.productsNotPresentAnymore);
      localDB.set("propertyNotPresentAnymore", action.propertyNotPresentAnymore);
      break;
    case mainDataConstants.UPDATE_CATALOG_DIFFERENCES:
      draft.productsNotPresentAnymore = action.productsNotPresentAnymore;
      draft.propertyNotPresentAnymore = action.propertyNotPresentAnymore;
      localDB.set("productsNotPresentAnymore", action.productsNotPresentAnymore);
      localDB.set("propertyNotPresentAnymore", action.propertyNotPresentAnymore);
      break;
    default:
      break;
  }
  return draft;
}

export default mainData;
