import React from 'react';
import { Page, BottomButton, SelectionFormatPriceItem, SelectionRadioPriceItem } from '../../components';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllUrlParams, findMinPrice, priceModifiers, getTemplatesByProperties, getModifierByProperties } from '../../libs';
import { productDataActions } from '../../store/actions';

export function Property() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { subCategoryDict } = useSelector(state => state.mainData);
    const { categoryId, subCategoryId, productId, properties, idPropEditable } = getAllUrlParams();
    const oldPropertyValueIndexSelected = React.useRef(0);
    const [pricePropSelected, setPricePropSelected] = React.useState(null);
    const product = subCategoryDict && subCategoryDict[subCategoryId].subProductDict[productId];

    React.useEffect(() => {
        if (!idPropEditable)
            dispatch(productDataActions.clean());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const prevPropertiesSelected = React.useMemo(() => {
        if(!product) return [];
        if (idPropEditable) {
            const currentProperty = { ...product?.properties.find(prop => prop.id === parseInt(idPropEditable)) };
            let propertyNeedChange = "";
            currentProperty.propertyValues.every((propertyValue) => {
                if (properties.includes(propertyValue.id)) {
                    propertyNeedChange = propertyValue.id;
                    return false;
                }
                oldPropertyValueIndexSelected.current++;
                return true;
            });
            let validProperties = properties.replace(`${propertyNeedChange}`, '').replace(';;', ';');
            // Remove first and last semicolon
            validProperties = validProperties.replace(/^;|;$/g, '');
            return validProperties?.split(';') || [];
        }
        return properties?.split(';') || []
    }, [idPropEditable, product, properties]);

    const modifiers = React.useMemo(() => {
        return getModifierByProperties(prevPropertiesSelected, product);
    }, [prevPropertiesSelected, product]);

    const [indexPropertyValues, setIndexPropertyValues] = React.useState(0);
    React.useMemo(() => {
        // When user go back in the navigation, here we reset the index of the selection selected to 0
        setIndexPropertyValues(oldPropertyValueIndexSelected.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prevPropertiesSelected])

    const currentProperty = React.useMemo(() => {
        /**
         * Current property user see with values filtered based on the previous values selected
         */
        let currentProperty = product?.properties ? { ...product?.properties[prevPropertiesSelected.length] } : [];
        if (idPropEditable) {
            currentProperty = { ...product?.properties.find(prop => prop.id === parseInt(idPropEditable)) }
        }
        currentProperty.values = currentProperty.propertyValues;
        delete currentProperty.propertyValues;
        return currentProperty;
    }, [product?.properties, prevPropertiesSelected.length, idPropEditable]);

    const handleButtonClick = () => {
        /**
         * When all the properties are selected, we go to the next page where we can have 4 cases:
         * 1. If user reach this pages from product pages he just need to select a property editable in the editor, so when he click on the button we return to the editor page
         * 2. If the product has more property, add the property selected to the url and remain on the same page
         * 3. If user select the last property not editable in the editor, go to the template if we have more than one for the properties selected
         * 4. If user select the last property not editable in the editor, go to the product page if we have only one template for the properties selected
         */
        const commonUrlParams = `categoryId=${categoryId}&subCategoryId=${subCategoryId}&`;
        const propertyValuesChoosen = currentProperty.values[indexPropertyValues].id;
        let propertiesInUrl = prevPropertiesSelected.length === 0
            ? propertyValuesChoosen.toString()
            : prevPropertiesSelected.join(';') + ';' + propertyValuesChoosen;
        const propertyIdChoosen = currentProperty.id;
        let propertiesIdInUrl = prevPropertiesSelected.length === 0
            ? propertyIdChoosen.toString()
            : prevPropertiesSelected.join(';') + ';' + propertyIdChoosen;
        setIndexPropertyValues(0);

        if (idPropEditable) {
            // go back 
            dispatch(productDataActions.addEditableProperty({ newValue: propertyValuesChoosen, id: parseInt(idPropEditable) }));
            window.history.back();
            return;
        }

        if (prevPropertiesSelected.length === product.properties.length - 1) {
            const templates = getTemplatesByProperties([...propertiesInUrl.split(";")], product.templates);
            const hasMoreTemplates = templates.length > 1;
            if (hasMoreTemplates) {
                navigate(`/template?${commonUrlParams}productId=${productId}&properties=${propertiesInUrl}&propertiesId=${propertiesIdInUrl}`);
                return;
            }
            navigate(`/warn-exit-product?${commonUrlParams}productId=${productId}&properties=${propertiesInUrl}&propertiesId=${propertiesIdInUrl}`);
            return;
        }
        navigate(`/property?${commonUrlParams}productId=${productId}&properties=${propertiesInUrl}&propertiesId=${propertiesIdInUrl}`);

    }

    const renderCorrectSelection = React.useCallback(() => {
        // Render the correct property based on the type of the property
        // For the moment we have only type 2 for all properties
        if (subCategoryDict && subCategoryDict[subCategoryId] && currentProperty?.values) {
            switch (currentProperty?.type) {
                case 1: 
                    return <SelectionFormatPriceItem
                        currentSelection={currentProperty}
                        indexSelectionValue={indexPropertyValues}
                        setIndexSelectionValue={setIndexPropertyValues}
                        products={[product]} />
                default:
                    return <SelectionRadioPriceItem
                        currentSelection={currentProperty}
                        indexSelectionValue={indexPropertyValues}
                        setIndexSelectionValue={setIndexPropertyValues}
                        products={[product]}
                        type={currentProperty?.type}
                        modifiers={modifiers}
                        pricePropSelected={pricePropSelected}
                    />
            }
        }
        return <></>;
    }, [currentProperty, indexPropertyValues, modifiers, product, subCategoryDict, subCategoryId, pricePropSelected]);

    const bottomPrice = React.useMemo(() => {
        /**
         * Calculate the price of the product based on the properties selected
         * appling the modifiers indicated on the properties
         */
        if (!product) return null;
        let price = parseFloat(findMinPrice({products: [product]}));
        let digitalPixPrice = parseFloat(findMinPrice({products: [product], useKanguryPrice: false}));
        let originalPriceDigitalPix = parseFloat(findMinPrice({products: [product], selectionValueId: -1, useOriginalPrice: true, useKanguryPrice: false}));
        if (idPropEditable) {
            const modifierSelectedProp = getModifierByProperties([currentProperty?.values[oldPropertyValueIndexSelected.current]?.id], product);
            const basePrice = priceModifiers(digitalPixPrice, modifierSelectedProp);
            setPricePropSelected(basePrice);
        }
        const modifierCurrentProp = getModifierByProperties([currentProperty?.values[indexPropertyValues]?.id], product);
        let allModifiers = { ...modifierCurrentProp };
        if (modifiers) {
            // Merge the modifiers of the current property with the modifiers of the previous properties
            for(const key in modifiers) {
                if (allModifiers[key]) {
                    allModifiers[key] = allModifiers[key] + modifiers[key];
                } else {
                    allModifiers[key] = modifiers[key];
                }
            }
        }
        if (allModifiers) {
            price = price + priceModifiers(originalPriceDigitalPix, allModifiers);
        }
        return price.toFixed(2).replace(".", ",") + " €";
    }, [currentProperty?.values, idPropEditable, indexPropertyValues, modifiers, product]);

    return (
        <Page
            title={currentProperty?.name}
            customBottomSection={
                <BottomButton content="Prosegui" onClick={handleButtonClick} contentUp={`Prezzo: da ${bottomPrice}`} />}>
            {renderCorrectSelection()}
        </Page>
    );
}