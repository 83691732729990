import { Page } from '../../components';
import { Button } from '../../ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export function EmailConfirmed() {
    const navigate = useNavigate();

    const goToHome = useCallback(() => {
        navigate('/');
    }, [navigate]);
    
    return (
        <Page>
            <div className='w-100 h-100 d-flex justify-content-center align-items-center flex-column gap-3'>
            <h1>Email confermata</h1>
            <h3>Inizia a fare acquisti!</h3>
            <Button otherClass="btn-primary p-1" onClick={goToHome}>
                <span className="text-tertiary  fs-5">
                    <FontAwesomeIcon icon="fa-regular fa-cart-plus" className='me-2' />
                    Aggiungi un prodotto
                </span>
            </Button>
            </div>
        </Page>
    );
}