import React from 'react';
import { Page } from '../../components';
import { CropperEditor, BottomButton } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { productDataActions, alertActions } from '../../store/actions';
import { getAllUrlParams, showCropAlertForSingleImage, showResolutionAlertForSingleImage } from '../../libs';
import { Modal } from '../../ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function Editor() {
    const dispatch = useDispatch();
    const { subCategoryDict } = useSelector(state => state.mainData);
    const { template } = useSelector(state => state.templateData);
    const { imagesList } = useSelector(state => state.productData);
    const { imageIndex, subCategoryId, productId, properties } = getAllUrlParams();
    const [editorData, setEditorData] = React.useState(imagesList[imageIndex] ? {
        image: imagesList[imageIndex].image || imagesList[imageIndex].originalImage,
        initProp: imagesList[imageIndex].editorProps,
        initStyle: imagesList[imageIndex].initStyle,
    } : {});
    const product = subCategoryDict && subCategoryDict[subCategoryId].subProductDict[productId];

    const [showScissorInfo, showResolutionInfo] = React.useMemo(() => {
        if (!imagesList || imagesList.length === 0 || imageIndex === undefined) return [false, false];
        const _template = template.length === 1 ? template[0] : template[imageIndex];
        if(imagesList[imageIndex].wasInEditor) return [false, showResolutionAlertForSingleImage(imagesList[imageIndex], _template)];
        return [showCropAlertForSingleImage(imagesList[imageIndex], _template, product?.processingType), showResolutionAlertForSingleImage(imagesList[imageIndex], _template)];
    }, [imageIndex, imagesList, product?.processingType, template]);

    React.useEffect(() => {
        // Show alert when user want to reload page
        window.onbeforeunload = function () {
            dispatch(productDataActions.clean());
            window.history.back();
        }
        return () => {
            // Save wasInEditor flag when user leave the editor
            dispatch(productDataActions.update({ wasInEditor: true }, imageIndex));
            window.onbeforeunload = null;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);// Not add any dependency

    const templateData = React.useMemo(() => {
        if (!template) return {};
        const t = template.length === 1 ? template[0] : template[imageIndex];
        const data = { ...t };
        data.aspectRatioContainer = t.width / t.height;
        data.backgroundColor = "rgb(" + t.backgroundColor.replaceAll(";", ",") + ")";
        return data;
    }, [imageIndex, template]);

    const handleSaveChanges = () => {
        const newEditorData = { ...editorData }
        dispatch(productDataActions.update({ ...newEditorData }, imageIndex));
        window.history.back();
    }

    const [showCropper, setShowCropper] = React.useState(true);
    const refreshCropper = () => {
        setShowCropper(prev => !prev);
        setTimeout(() => {
            setShowCropper(prev => !prev);
        }, 100);
    }

    const changePhoto = (e) => {
        const file = e.target.files[0];
        if (file.type.match(/(jpeg|jpg|tiff|tif|png)$/)) {
            const urlImage = URL.createObjectURL(file);
            const image = new Image();
            image.src = urlImage;
            image.onload = () => {
                setEditorData(prev => ({
                    ...prev,
                    image: urlImage,
                    originalImage: urlImage,
                    imageName: file.name,
                    imageWidth: image.width,
                    imageHeight: image.height,
                    initProp: {
                        x: 0,
                        y: 0,
                        zoom: 1,
                        rotate: 0,
                    },
                    initStyle: undefined,
                    uploaded: false
                }));
            }
        } else {
            dispatch(alertActions.addToastProduct());
        }
        refreshCropper();
    }

    const changePhotoNative = (file) => {
        setEditorData(prev => ({
            ...prev,
            image: file.url,
            originalImage: file.url,
            imageName: file.name,
            imageWidth: file.imageWidth,
            imageHeight: file.imageHeight,
            initProp: {
                x: 0,
                y: 0,
                zoom: 1,
                rotate: 0,
            },
            uploaded: false,
            id: file.id
        }));
        refreshCropper();
    }

    const handleSaveEditorProps = (editorProps) => {
        setEditorData(prev => ({
            ...prev,
            editorProps
        }));
    }

    const handleSaveInitStyle = (initStyle) => {
        setEditorData(prev => ({
            ...prev,
            initStyle
        }));
    }

    const nativePermissionGranted = localStorage.getItem('permissionGranted') === 'true';

    if (!imagesList || imagesList.length === 0) {
        window.history.back();
        return null;
    }

    return (
        <Page title={"Modifica Anteprima"}
            customBottomSection={
                <BottomButton content="Salva Modifiche" onClick={handleSaveChanges}
                    contentUp={<div className='text-center mb-3'>
                        {showScissorInfo && <div className='text-primary fw-bold' data-bs-toggle="modal" data-bs-target={"#scissorsModal"}>
                            <span className='me-2'>
                                <FontAwesomeIcon icon="fa-regular fa-circle-info" className='text-white bg-primary rounded-circle' />
                            </span>
                            <span>Questa foto è stata ritagliata</span>
                        </div>}
                        {showResolutionInfo && <div className='text-danger fw-bold p-1' data-bs-toggle="modal" data-bs-target={"#resolutionModal"}>
                            <span className='me-2'>
                            <FontAwesomeIcon icon="fa-solid fa-triangle-exclamation" className='text-danger bg-white' />
                            </span>
                            <span>Questa foto non ha una risoluzione ottimale</span>
                        </div>}
                    </div>} />}>
            <div className={`w-100 object-fit-cover position-relative h-100 ${templateData.aspectRatioContainer < 1 || product?.processingType === 1 ? 'max-w-500 m-auto' : ''}`}>
                {showCropper && <CropperEditor
                    src={editorData.image}
                    aspectRatio={templateData.aspectRatioContainer}
                    background={templateData.backgroundColor}
                    changePhoto={window.ReactNativeWebView && nativePermissionGranted ? changePhotoNative : changePhoto}
                    designObjects={templateData.designObjects}
                    editorPage={true}
                    initProp={editorData.initProp}
                    initStyle={editorData.initStyle}
                    processingType={product?.processingType}
                    saveProps={handleSaveEditorProps}
                    saveInitStyle={handleSaveInitStyle}
                    overloresList={templateData.overloresList ? templateData.overloresList.filter(overlores => properties?.includes(overlores.iD_PropertyValue) || !overlores.iD_PropertyValue) : []}
                    index={imageIndex}
                />}
            </div>
            <Modal
                title={"Questa foto è ritagliata"}
                text={`La foto non si adatta al formato scelto.\n Controlla che non siano state tagliate parti importanti della foto: puoi zoomare, ruotare o spostare la foto trascinando il dito sull'anteprima`}
                id="scissorsModal"
                secondaryText={"Chiudi"}
            />
            <Modal 
                title={"Risoluzione insufficiente"}
                text={`Alcune foto non hanno una risoluzione ottimale per essere stampate in questo modello scelto.\nTi consigliamo di usare una foto con risoluzione più alta o non potremo garantirti un risultato soddisfacente.\nIl problema potrebbe essere causato anche da uno zoom eccessivo per la qualità dell'immagine`}
                id="resolutionModal"
                secondaryText={"Ignora e prosegui"}
            />
        </Page>
    );
}