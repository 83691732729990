import React from 'react';
import { Page } from '../../components';

export function ConsumerProtection() {
    return (
        <Page title={'Tutela del Consumatore'}
        visibleBottomNavigation={false}>
            <div className='container d-flex align-items-start justify-content-center'>
                <div className='card p-4 shadow-lg mb-4 mt-4'>
                    <h1 className='text-center mb-4'>Tutela del Consumatore</h1>
                    <p>
                        In Kangury, ci impegniamo a garantire la massima soddisfazione dei nostri clienti, rispettando pienamente i diritti previsti dalla normativa italiana e comunitaria. Di seguito, troverai tutte le informazioni relative alla tutela dei tuoi diritti come consumatore.
                    </p>
                    <h2>Garanzia legale</h2>
                    <p>
                        Tutti i nostri prodotti e servizi sono coperti dalla garanzia legale di conformità, come previsto dal Codice del Consumo (D.Lgs. 206/2005). Questa garanzia ha una durata di 24 mesi e si applica ai difetti di conformità riscontrati nei prodotti acquistati.
                    </p>
                    <p>
                        In caso di problemi, puoi richiedere:
                    </p>
                    <ul>
                        <li>La riparazione o sostituzione gratuita del prodotto.</li>
                        <li>Un rimborso totale o parziale, in base alla natura del difetto e alle condizioni del prodotto.</li>
                    </ul>
                    <p>
                        Per esercitare i tuoi diritti, contattaci all'indirizzo: <a href="mailto:supporto@kangury.it">supporto@kangury.it</a>.
                    </p>
                    <h2>Politica di reso e rimborso</h2>
                    <p>
                        Offriamo una politica di "Soddisfatti o rimborsati", valida per 30 giorni dalla data di acquisto. Se non sei soddisfatto, potrai richiedere un rimborso completo.
                    </p>
                    <p>
                        Non sarà necessario restituire il prodotto: disattiveremo il dispositivo da remoto, e potrai smaltirlo. Il rimborso verrà elaborato entro 14 giorni dalla ricezione della tua richiesta.
                    </p>
                    <h2>Diritto di recesso</h2>
                    <p>
                        Per richiedere il recesso puoi in autonomia cancellare la tua utenza e con essa i dati relativi, in caso di ulteriore supporto, puoi scriverci a: <a href="mailto:supporto@kangury.it">supporto@kangury.it</a>.
                    </p>
                    <h2>Servizio clienti</h2>
                    <p>
                        Per qualsiasi problema o richiesta, il nostro team di assistenza è a tua disposizione. Alla seguente Email: <a href="mailto:supporto@kangury.it">supporto@kangury.it</a>. Ci impegniamo a rispondere entro 48 ore lavorative.
                    </p>
                    <h2>Risarcimenti e responsabilità</h2>
                    <p>
                        In caso di danni o problemi legati ai nostri prodotti o servizi, ti invitiamo a contattarci immediatamente tramite email: <a href="mailto:supporto@kangury.it">supporto@kangury.it</a>. Valuteremo ogni caso con attenzione per garantire una soluzione rapida e soddisfacente.
                    </p>
                </div>
            </div>
        </Page>
    )
}