import { templateDataService } from "../services";

export const templateDataConstants = {
  GET_REQUEST: "TEMPLATE_DATA_GET_REQUEST",
  GET_SUCCESS: "TEMPLATE_DATA_GET_SUCCESS",
  GET_ERROR: "TEMPLATE_DATA_GET_ERROR",
};

function _printError(dispatch, failure, replyError) {
  console.error("ERROR:" + replyError);
}

// Save template data in localDB and, when fetch, change it only when timestamp is different
function _getTemplateData(product, templateId, properties) {
  if (product.templates.length === 1) return product.templates[0];
  if (templateId && product.templates.length > 1)
    return product.templates.find((t) => {
      return t.id === templateId
    });
  return product.templates.find((t) => {
    return properties.every((p) => {
      return !t.propertyValueFilter.includes(parseInt(p));
    });
  });
}

function get(product, templateId, properties) {
  const callAPI = ({ dispatch, id }) => {
    templateDataService.get({ id }).then(
      (data) => {
        dispatch(success({ template: data }));
      },
      (error) => {
        dispatch(failure(error));
        _printError(dispatch, failure, error);
      }
    );
  };

  return (dispatch) => {
    dispatch(request());
    const template = _getTemplateData(product, parseInt(templateId), properties);
    if (template?.templateInfo?.pages) {
      dispatch(success({ template: template }));
    }
    else {
      callAPI({ dispatch, id: template.id });
    }
  };

  function request() {
    return { type: templateDataConstants.GET_REQUEST };
  }
  function success(data) {
    return { type: templateDataConstants.GET_SUCCESS, data };
  }
  function failure(error) {
    return { type: templateDataConstants.GET_ERROR, error };
  }
}

export const templateDataActions = {
  get,
};
