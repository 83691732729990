import React from 'react';
import { Page } from '../../components';

export function PrivacyPolicy() {
    return (
        <Page title={'Informativa sulla Privacy'}
        visibleBottomNavigation={false}>
            <div className='container d-flex align-items-start justify-content-center'>
            <div className='card p-4 shadow-lg mb-4 mt-4'>
                    <h1 className='text-center mb-4'>Informativa sulla Privacy</h1>
                    <p>
                        La tua privacy è importante per noi. È nostra politica rispettare la tua privacy riguardo a qualsiasi informazione che potremmo raccogliere attraverso il nostro sito web, <a href="http://www.kangury.com">http://www.kangury.com</a>, e altri siti che possediamo e gestiamo.
                    </p>
                    <p>
                        Chiediamo informazioni personali solo quando ne abbiamo veramente bisogno per fornirti un servizio. Le raccogliamo con mezzi equi e legali, con la tua conoscenza e il tuo consenso. Ti facciamo anche sapere perché le stiamo raccogliendo e come verranno utilizzate.
                    </p>
                    <p>
                        Conserviamo le informazioni raccolte solo per il tempo necessario a fornirti il servizio richiesto. I dati che memorizziamo, li proteggeremo con mezzi commercialmente accettabili per prevenire perdite e furti, nonché accessi non autorizzati, divulgazione, copia, uso o modifica.
                    </p>
                    <p>
                        Non condividiamo informazioni di identificazione personale pubblicamente o con terze parti, eccetto quando richiesto dalla legge.
                    </p>
                    <p>
                        Il nostro sito web può collegarsi a siti esterni che non sono gestiti da noi. Si prega di essere consapevoli del fatto che non abbiamo alcun controllo sul contenuto e sulle pratiche di questi siti, e non possiamo accettare responsabilità per le loro rispettive politiche sulla privacy.
                    </p>
                    <p>
                        Sei libero di rifiutare la nostra richiesta di informazioni personali, con la consapevolezza che potremmo non essere in grado di fornirti alcuni dei servizi desiderati.
                    </p>
                </div>
            </div>
        </Page>
    )
}