import React from 'react'
import { PlaceholdersLoaderGif } from '../'
import { ImgWithPlaceholder } from '..';

export function CardSmall({ image, title, otherClass }) {

    return (
        <div className={`card rounded-4 overflow-hidden shadow  ${otherClass}`}>
            <div className=' ratio ratio-16x9'>
                <ImgWithPlaceholder src={image} className="card-img-top pe-none" alt={title} unselectable="on" placeholder={PlaceholdersLoaderGif.loader_1200_1200} />
            </div>
            <p className="text-center m-1">{title}</p>
        </div>
    );
}