import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/core';
import "swiper/css";

export function CarouselSmall({ elements, otherProps}) {
    const [reverseDirection, setReverseDirection] = React.useState(false);

    return (
        <Swiper
            autoplay={{
                delay: 2500,
                disableOnInteraction: true,
                reverseDirection
            }}
            pagination={{
                clickable: true,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
            slidesPerView={1.3}
            spaceBetween={25}
            // add listener to update the carousel when reach the end
            onReachEnd={() => {
                setReverseDirection(!reverseDirection);
            }}
            onReachBeginning={() => {
                setReverseDirection(!reverseDirection);
            }}
            {...otherProps}
        >

            {elements.map((element, index) => (
                <SwiperSlide key={index}>
                    {element}
                </SwiperSlide>
            ))}
        </Swiper>
    );
}