import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Page } from '../../components';
import { Button } from '../../ui';
import logo from '../../ui/assets/logo.png';

export function ManagePlan({ version }) {

    return (
        <Page title={"Gestisci Abbonamento"}
            visibleBottomNavigation={false}>
            <div className='p-3 text-center'>
                <h2>Presto in arrivo per sbloccare nuove funzionalità avanzate!</h2>
                {/* <div className="accordion" id="accordionPanelsStayOpenExample">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                            <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="false" aria-controls="panelsStayOpen-collapseOne">
                                Base
                            </button>
                        </h2>
                        <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne">
                            <div className="accordion-body">
                                <li>
                                    500 Mb associati ad ogni smartphoto acquistata per salvare le tue foto e video
                                </li>
                                <div className='mt-1 text-secondary text-end'>
                                    Gratis
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                            <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                                Premium 10 Gb - <span className='ms-1 text-success'>
                                    active</span>
                            </button>
                        </h2>
                        <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                            <div className="accordion-body">
                                <ul>
                                    <li> Cose offerte dal piano Base </li>
                                    <li> 10 Gb di spazio cloud da dividere con le varie smartphoto acquistate</li>
                                    <li> Possibilità di salvare diversi tipi di file oltre a foto</li>
                                    <li> Gestione privacy delle smartphoto avanzata (possibilità di rendere le proprie smartphoto pubbliche per un gruppo di amici o per chiunque) </li>
                                    <li> Creazione album associati alle smartphoto automatica </li>
                                </ul>
                                <div className='mt-1 mb-1 text-secondary text-end'>
                                    4.99 euro/mese
                                </div>
                                <Button otherClass={"btn-secondary mt-3"}>
                                    Disattiva
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                            <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                                Premium 100 Gb
                            </button>
                        </h2>
                        <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                            <div className="accordion-body">
                            <ul>
                                    <li> Cose offerte dal piano 10 Gb </li>
                                    <li> 100 Gb di spazio cloud da dividere con le varie smartphoto acquistate</li>
                                </ul>
                                <div className='mt-1 text-secondary text-end'>
                                    9.99 euro/mese
                                </div>
                                <Button otherClass={"mt-3"}>
                                    Attiva
                                </Button>
                            </div>
                        </div>
                    </div>
                </div> */}

            </div>
        </Page>
    );
}