import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function InstallToast() {
    const [show, setShow] = React.useState(true);
    const navigate = useNavigate();

    const goToInstallGuide = () => {
        navigate('/install-guide');
    }

    const handleNotShowAgain = () => {
        localStorage.setItem('notShowInstallToastAgain', true);
        setShow(false);
    }

    const handleAfter = () => {
        localStorage.setItem('lastAccessInWebApp', new Date());
        setShow(false);
    }

    if(!showInstallToast() || !show) {
        return null;
    }

    return (
        <div className={'z-1000 d-flex w-100 shadow-none position-fixed bottom-0 mb-5 translate-middle-x start-50 fw-bol ps-2 pe-2'} 
        role="alert" aria-live="assertive" aria-atomic="true">
        <div className="toast-body p-1 ps-2 pe-2 w-100 bg-primary rounded rounded-3">
        <div className='row'>
            <div className='col-11 text-center'>
                <div>Installa Kangury per accedere rapidamente!</div>
                <div className='d-flex justify-content-around text-decoration-underline'>
                    <div className='cursor-pointer' onClick={handleNotShowAgain}>
                        Non mostrare più
                    </div>
                    <div className='cursor-pointer' onClick={handleAfter}>
                        Dopo
                    </div>
                </div>
            </div>
            <div className='col-1 d-flex justify-content-end align-items-center cursor-pointer' onClick={goToInstallGuide}>
                <FontAwesomeIcon icon="fa-regular fa-download" />
            </div>
        </div>
        </div>
    </div>
    );
}

const showInstallToast = () => {
    const notShowAgain = localStorage.getItem('notShowInstallToastAgain');
    if(notShowAgain) {
        return false;
    }
    let lastAccessInWebApp = localStorage.getItem('lastAccessInWebApp');
    if(!lastAccessInWebApp) {
        localStorage.setItem('lastAccessInWebApp', new Date());
        return false;
    }
    lastAccessInWebApp = new Date(lastAccessInWebApp);
    const now = new Date();
    const diff = now - lastAccessInWebApp;
    if(diff > 1000 * 60 * 60 * 24 * 7) {
        return true;
    } 
    return false;
}