import { localDB } from "../cache";
import { userService } from "../services";

export const userConstants = {

  LOGOUNT_REQUEST: "USER_LOGOUT_REQUEST",
  LOGOUT_SUCCESS: "USER_LOGOUT_SUCCESS",
  LOGOUT_FAILURE: "USER_LOGOUT_FAILURE",

  ADD_SHIPPING_ADDRESS: "USER_ADD_SHIPPING_ADDRESS",
  GET_SHIPPING_ADDRESS: "USER_GET_SHIPPING_ADDRESS",
  REMOVE_SHIPPING_ADDRESS: "USER_REMOVE_SHIPPING_ADDRESS",
  UPDATE_SHIPPING_ADDRESS: "USER_UPDATE_SHIPPING_ADDRESS",
  SET_DEFAULT_SHIPPING_ADDRESS: "USER_SET_DEFAULT_SHIPPING_ADDRESS",

  ADD_BILLING_ADDRESS: "USER_ADD_BILLING_ADDRESS",
  GET_BILLING_ADDRESS: "USER_GET_BILLING_ADDRESS",
  REMOVE_BILLING_ADDRESS: "USER_REMOVE_BILLING_ADDRESS",
  UPDATE_BILLING_ADDRESS: "USER_UPDATE_BILLING_ADDRESS",
  SET_DEFAULT_BILLING_ADDRESS: "USER_SET_DEFAULT_BILLING_ADDRESS",

  REMOVE_ALL_ADDRESS: "USER_REMOVE_ALL_ADDRESS",

  GET_INFO: "USER_GET_INFO",
};

function addShippingAddress(address) {
  return (dispatch) => {
    dispatch({ type: userConstants.ADD_SHIPPING_ADDRESS, address });
  };
}

function getShippingAddresses() {
  return (dispatch) => {
    localDB.get("shippingAddresses").then((addresses) => {
      dispatch({ type: userConstants.GET_SHIPPING_ADDRESS, addresses });
    });
  };
}

function removeShippingAddress(index) {
  return (dispatch) => {
    dispatch({ type: userConstants.REMOVE_SHIPPING_ADDRESS, index });
  };
}

function updateShippingAddress(address, index) {
  return (dispatch) => {
    dispatch({ type: userConstants.UPDATE_SHIPPING_ADDRESS, address, index });
  };
}

function setDefaultShippingAddress(index) {
  return (dispatch) => {
    dispatch({ type: userConstants.SET_DEFAULT_SHIPPING_ADDRESS, index });
  };
}

function addBillingAddress(address) {
  return (dispatch) => {
    dispatch({ type: userConstants.ADD_BILLING_ADDRESS, address });
  };
}

function getBillingAddresses() {
  return (dispatch) => {
    localDB.get("billingAddresses").then((addresses) => {
      dispatch({ type: userConstants.GET_BILLING_ADDRESS, addresses });
    });
  };
}

function removeBillingAddress(index) {
  return (dispatch) => {
    dispatch({ type: userConstants.REMOVE_BILLING_ADDRESS, index });
  };
}

function updateBillingAddress(address, index) {
  return (dispatch) => {
    dispatch({ type: userConstants.UPDATE_BILLING_ADDRESS, address, index });
  };
}

function setDefaultBillingAddress(index) {
  return (dispatch) => {
    dispatch({ type: userConstants.SET_DEFAULT_BILLING_ADDRESS, index });
  };
}

function removeAllAddresses() {
  return (dispatch) => {
    dispatch({ type: userConstants.REMOVE_ALL_ADDRESS });
  };
}

function getInfo() {
  return (dispatch) => {
    userService.getInfo().then((data) => {
      dispatch({ type: userConstants.GET_INFO, data });
    });
  };
}


export const userActions = {
  addShippingAddress,
  getShippingAddresses,
  removeShippingAddress,
  updateShippingAddress,
  setDefaultShippingAddress,
  addBillingAddress,
  getBillingAddresses,
  removeBillingAddress,
  updateBillingAddress,
  setDefaultBillingAddress,
  removeAllAddresses,
  getInfo,
};
