import { templateDataConstants, productConstants } from "../actions";

const initialState = {};

const templateData = (state = initialState, action) => {
  let draft = {
    ...state,
  };
  switch (action.type) {
    case templateDataConstants.GET_SUCCESS:
      if (action.data.template?.templateInfo?.pages)
        draft.template = action.data.template.templateInfo.pages;
      else
        draft.template = action.data.template.pages;
      break;
    case productConstants.CLEAN:
      draft = {
        ...initialState,
      };
      break;
    default:
      break;
  }
  return draft;
}

export default templateData;
