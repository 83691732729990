import React from 'react'
import { PlaceholdersLoaderGif, ImgWithPlaceholder, Button, ProgressBarMemory, VideoWithPlaceholder } from '..'
import LogoBlack from '../assets/logo-black.png'

export function CardAlbum({ handleOpen, title, files, memoryUsed, cover, coverType, memoryTotal = 500, associate = false, creationDate, nfc }) {

    return (
        <div className="card p-3 shadow">
            <div className="row g-0">
                <h5 className="col-9 card-title fw-bold">{title}</h5>
                <div className="col-3">
                    <Button otherClass={"p-0"} otherProps={{ "data-bs-toggle": "modal", "data-bs-target": "#confirmAssociate" }}
                        onClick={handleOpen}>
                        {associate ? 'Associa' : 'Apri'}
                    </Button>
                </div>
            </div>
            <div className="row g-0">
                <div className="col-4 d-flex justify-content-center align-items-center cursor-pointer position-relative" onClick={handleOpen}>
                    {coverType && coverType.includes('image') && <ImgWithPlaceholder src={cover}
                        className="card-img-top pe-none"
                        alt={'image'}
                        unselectable="on"
                        placeholder={PlaceholdersLoaderGif.loader_1200_1200} />
                    }
                    {coverType && coverType.includes('video') && <VideoWithPlaceholder src={cover}
                        className={"card-img-top pe-none"}
                    />
                    }
                </div>
                <div className="col-8 position-relative">
                    <div className="card-body">
                        <p className="card-text mb-0">
                            <span className="fw-bold me-1">Elementi:</span>
                            {files}
                        </p>
                        <p className="card-text mb-0">
                            <span className="fw-bold me-1">Creazione:</span>
                            {creationDate}
                        </p>
                        <p className="card-text mb-0">
                            <span className='fw-bold'> Associato:</span> {nfc ? 'Si' : 'No'}
                        </p>
                        <p className="card-text mb-0">
                            <span className='fw-bold'> Memoria:</span> {memoryUsed.toFixed(2)}/{memoryTotal}Mb
                        </p>
                        <ProgressBarMemory percentage={memoryUsed / memoryTotal * 100} />
                        
                    </div>
                </div>
                
            </div>
        </div>
    );
}