import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';

export function CardIconTextLink({ icon, title, linkText, link, text, centerTitle = true, darkText = false, danger = false, footer }) {
    const navigate = useNavigate();

    const handleAddAddress = () => {
        navigate(link);
    }

    const textColor = React.useMemo(() => {
        if (danger) {
            return 'text-danger';
        }
        if (darkText) {
            return 'text-dark';
        }
        return 'text-secondary';
    }, [danger, darkText]);

    return (
        <div className={`card rounded-4 overflow-hidden shadow m-3 ${danger ? 'border border-danger border-3 text-danger' : ''}`}>
            <div className="card-body">
                <div className='w-100 text-center mb-3'>
                    {icon}
                </div>
                {title && <p className={`fw-bold m-0 ${centerTitle ? 'text-center' : ''} ${textColor}`}>
                    {title}
                </p>}
                {linkText && <div className='text-center w-100'>
                    <button className="btn btn-link fw-bold pb-0" onClick={handleAddAddress}>
                        {linkText}
                        <FontAwesomeIcon icon="fa-regular fa-chevron-right" className="ms-2 text-primary" />
                    </button>
                </div>}
                {text && <p className={` m-0 ${textColor}`}>
                    {text}
                </p>}
                {footer}
            </div>
        </div>
    );
}