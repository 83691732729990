import loader_800_440_rounded from './800_440_rounded.gif';
import loader_800_440 from './800_440.gif';
import loader_1080_1800 from './1080_1800.gif';
import loader_1200_1200_rounded from './1200_1200_rounded.gif';
import loader_1200_1200 from './1200_1200.gif';

export const PlaceholdersLoaderGif = {
    loader_800_440_rounded,
    loader_800_440,
    loader_1080_1800,
    loader_1200_1200_rounded,
    loader_1200_1200,
}