import { RadioBasic } from '../../radio';

export function SelectionQuantity({ title, checked, rows, minPrice }) {

    return (
        <div className="card p-2 ps-3 pe-3 border-0 bg-transparent">
            <div className="d-flex justify-content-between">
                <div className="d-flex">
                    <RadioBasic name="radios" checked={checked} />
                    <h5 className="card-title fw-bold ms-2 mb-0"><small>{title}</small></h5>
                </div>
                {minPrice && <p className="card-title text-dark m-0"><small>{minPrice}</small></p>}
            </div>
            {checked && rows &&
                <div className='mt-2'>
                    {rows.map((row, index) => (
                        <div className="d-flex align-items-center justify-content-between" key={index}>
                            <p className="text-muted mb-0"><small>da {row.minQuantity} {row.maxQuantity === 9999 ? "e oltre" : "a " + row.maxQuantity}</small></p>
                            <p className="card-text mb-0">
                                {row.originalPrice !== row.price &&
                                    <small className="text-decoration-line-through fw-light fst-italic">
                                        {row.originalPrice.toFixed(2).replace(".", ",")} €
                                    </small>}
                            </p>
                            <p className="card-text">
                                <small className="text-dark fw-bold">
                                    {row.price.toFixed(2).replace(".", ",")} €
                                </small></p>
                        </div>
                    ))}
                </div>}

        </div>

    );
}