import React from 'react';
import { ListSelectionsCard } from '../../';
import { findMinPrice } from '../../../libs';
import { SelectionCard } from '../../../ui';

export function SelectionRadioPriceItem({ currentSelection, indexSelectionValue, setIndexSelectionValue, products, type, validSelectionValues = false, pricePropSelected = null }) {

    const selectionValueWithLowestPrice = React.useMemo(() => {
        return parseFloat(findMinPrice({products, selectionValueId: -1}));
    }, [products]);
    const selectionValueWithLowestPriceDigitalPix = React.useMemo(() => {
        return parseFloat(findMinPrice({products, selectionValueId: -1, useOriginalPrice: true, useKanguryPrice: false}));
    }, [products]);

    const renderSelectionsList = () => {
        return currentSelection?.values.map((selection, index) => {
            if (validSelectionValues === false || validSelectionValues?.has(selection.id.toString())) {
                let price = "";
                if (type === 2)
                    price = products.length > 1 ? findMinPrice({products, selectionValueId: selection.id}) : selectionValueWithLowestPrice;
                if (type === 3) {
                    price = products.length > 1 ? findMinPrice({products, selectionValueId: selection.id}) : selectionValueWithLowestPrice;
                    if (selection.priceModifier) {
                        const digitalPixPrice = products.length > 1 ? findMinPrice({products, selectionValueId: selection.id}, false, false) : selectionValueWithLowestPriceDigitalPix;
                        price = price + (digitalPixPrice * selection.priceModifier / 100)
                    }
                    if (pricePropSelected)
                        price = (parseFloat(price) - parseFloat(pricePropSelected)).toFixed(2);
                    else
                        price = (parseFloat(price) - selectionValueWithLowestPrice).toFixed(2)
                    if (price > 0)
                        price = "(+" + price + " €)";
                    else if (price < 0)
                        price = "(" + price + " €)";
                    else
                        price = "";
                }
                return (<div onClick={() => setIndexSelectionValue(index)} className='h-100' key={index}>
                    <SelectionCard title={selection.value} checked={indexSelectionValue === index} description={selection.description} image={selection.images && selection.images[0].url} bottomText={price.replace(".", ",")} />
                </div>)
            }
            return null;
        });
    };

    return (
        <ListSelectionsCard list={renderSelectionsList(indexSelectionValue) || []} />
    );
}