export const ProgressBarMemory = ({ percentage=60 }) => {
    return (<div className="d-flex border rounded-5 overflow-hidden border-tertiary border-2" 
    style={{ height: '20px' }}>
        <div className="bg-tertiary" style={{ width: `${percentage}%` }}>

        </div>
        <div className="bg-white" style={{ width: `${100 - percentage}%` }}>

        </div>
    </div>)
}